import React, { useContext, useState, useEffect } from "react";

import { ListeEtapes } from "./Etapes/ListeEtapes";
import { ProjetByProjetId } from "./Projets/ProjetByProjetId";
import { ProjetNomByProjetId } from "./Projets/ProjetNomByProjetId";

import { GetModeleById } from "./Modeles/GetModeleById";
import { AfficheTiersByTiersId } from "./Tiers/AfficheTiersByTiersId";

import { UtilisateurById } from "./Utilisateurs/UtilisateurById";
import { ProjetNote } from "./Projets/ProjetNote";
import "./css/Global.css";
import "./css/Zone3.css";

import {Siret} from "./Siret/Siret"
import { AuthContext } from "../contexts/AuthProvider";
import { SyntheseGlobale } from "./Synthèses/SyntheseGlobale";
import { ListeDestinataires } from "./Destinataires/ListeDestinataires";
import { ListeFichiers } from "./Fichiers/ListeFichiers";
import { FiChevronRight, FiChevronDown } from "react-icons/fi";


// export function Zone3() {}

export function Zone3() {
  const {
    userId,
    utilisateurId,
    tiersId,
    selectedMenu,
    projetId,
    modeleId,
    debugAffichage,
    isAgence,
    isPrestataire,
    isDestinatairesOpen,
    selectedArchive,
    projetCible,  // CLIENT, PARTENAIRE, AGENCE, PRESTATAIRE
    handleToggleDestinataires
  } = useContext(AuthContext);
  const [showBorderAndShadow, setShowBorderAndShadow] = useState(false);
  const [utilisateur] = useState([]);

  useEffect(() => {
    // Évaluez toutes les conditions dans lesquelles vous souhaitez afficher la bordure et l'ombre
    const shouldShowBorderAndShadow = userId && (
      ((isAgence || isPrestataire) && selectedMenu === "ARCHIVES" &&
        (selectedArchive === "ARCHIVES PROJETS" || selectedArchive === "ARCHIVES CLIENTS" || selectedArchive === "ARCHIVES PARTENAIRES")) ||
      (projetId && (isAgence || isPrestataire) && (selectedMenu === "PROJETS CLIENTS" ||selectedMenu === "PROJETS PARTENAIRES" 
        ||  selectedMenu === "ALL PROJETS" ||     selectedMenu ===  "ALL CLIENTS" || selectedMenu === "ALL ARCHIVES")) ||
      (selectedMenu === "REQUÊTES" && isDestinatairesOpen) ||
      selectedMenu === "SYNTHÈSES" ||
      selectedMenu === "ALL DASHBOARD" ||
      (modeleId && selectedMenu === "ADMIN MODÈLES") ||
      (tiersId && (selectedMenu === "PARTENAIRES" || selectedMenu === "CLIENTS" || selectedMenu === "ALL PRESTATAIRES" ||
        selectedMenu === "ALL PRESTATAIRES" || selectedMenu === "ADMIN GÉNÉRAL" || selectedMenu === "ADMIN AGENCES" || selectedMenu === "ALL AGENCES")) || 
      (utilisateurId && selectedMenu === "UTILISATEURS")
    );

    setShowBorderAndShadow(shouldShowBorderAndShadow);
    console.log("Zone3 setShowBorderAndShadow", shouldShowBorderAndShadow);
  }, [userId, isAgence, isPrestataire, selectedMenu, selectedArchive, projetId, modeleId, tiersId, isDestinatairesOpen, utilisateurId]); // Ajoutez d'autres dépendances si nécessaire

  const toggleDestinataires = () => {
    // console.log("zone4 toggleDestinataire isDestinatairesOpen", isDestinatairesOpen);
    handleToggleDestinataires(!isDestinatairesOpen);
    console.log(
      "zone4 toggleDestinataire isDestinatairesOpen",
      isDestinatairesOpen
    );
  };

  console.log("Zone3", selectedMenu, selectedArchive, " projetId ", projetId);

  return (
    // <div className="zone-3 marge-zones ">
      <div className={`zone-3  marge-zones ${showBorderAndShadow ? "show-border-shadow" : ""}`}>
    
      {/* Juste pour savoir où sont les zones */}
      {debugAffichage && <h4 className="zone-title">Zone 3</h4>}

      {userId && projetId &&
        (isAgence || isPrestataire) &&  (selectedMenu === "PROJETS CLIENTS" || selectedMenu === "ALL PROJETS" ||  selectedMenu === "PROJETS PARTENAIRES" )  && 
        (
          <div>
            {/* On récupère toutes les informations du projets */}
            <ProjetByProjetId />
            <ProjetNomByProjetId />  
            <ProjetNote />
            <hr />

            {/* Affiche toutes les étapes du projet */}
            <ListeEtapes />
            <hr />
            {/* On liste tous les fichiers du projetId en cours */}
            <ListeFichiers AllAgences={false} />

          </div>
        )}



      {userId &&
              (isAgence || isPrestataire) &&
              selectedMenu === "NEW AGENCE"  && (
                <>
                {/* Test Siret */}
                  <Siret />
                </>
              )}

 


        {userId && selectedMenu === "REQUÊTES" && 
        // isDestinatairesOpen 
        // && 
        (
          <>
            <div
              // className="cadre-zone3 flex-space-around-center cursor-pointer"
              className="cadre-zone3 d-flex justify-content-center align-items-center cursor-pointer"
              // onClick={toggleDestinataires}
            >
              
              <div className="rules-text" onClick={toggleDestinataires}>
                    DESTINATAIRES
                    </div>
              <div
                onClick={toggleDestinataires}
                className="icon-size"
                data-tooltip-id="TooltipGDestinataires"
                data-tooltip-content="Cliquez pour voir les Destinataires"
              >
                {isDestinatairesOpen ? (
                  <FiChevronDown />
                ) : (
                  <FiChevronRight />
                )}
              </div>
            </div>
            <ListeDestinataires typeTiers={["PARTENAIRE", "AGENCE","PRESTATAIRE"]} />
            {/* {isDestinatairesOpen && (
              // On consièdère qu'une AGENCE et les PRESTATAIRES font partie des destinataires
              <ListeDestinataires
                typeTiers={["PARTENAIRE", "AGENCE", "PRESTATAIRE"]}
              />
            )} */}
          </>
        )}



      {userId && selectedMenu === "SYNTHÈSES" && (
        <>
          {/* Synthèse juste sur les projets de userId*/}
          <SyntheseGlobale AgenceAuComplet={false} />
        </>
      )}
      {userId && selectedMenu === "ALL DASHBOARD" && (
        <>
          {/* Synthèse sur tous les projets de l'agence */}
          <SyntheseGlobale AgenceAuComplet={true} />
        </>
      )}

      {userId && modeleId && selectedMenu === "ADMIN MODÈLES" && (
        <div className="custom-heading">
          <h5>
            <GetModeleById />
          </h5>
        </div>
      )}

      {userId &&
        tiersId &&
        (selectedMenu === "PARTENAIRES" ||
          selectedMenu === "CLIENTS" ||
          selectedMenu === "ALL CLIENTS" ||
          selectedMenu === "ALL PARTENAIRES" ||
          selectedMenu === "ALL PRESTATAIRES" ||
          selectedMenu === "ADMIN AGENCES" ||
          selectedMenu === "ADMIN GÉNÉRAL" ||
          selectedMenu === "ALL AGENCES") && (
          <>
            <AfficheTiersByTiersId />
          </>
        )}


      {userId &&
        utilisateurId &&
        utilisateur &&
        selectedMenu === "UTILISATEURS" && <UtilisateurById />}
    </div>
  );
}
