import React, { useEffect, useState, useContext, useCallback } from "react";
import "../css/Compteurs.css";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { customAxios } from "../Utilities/utilities";
import io from 'socket.io-client';
import Cookies from "js-cookie";

export function CountProjetsClientsByUserId(props ) {
  const { cibleProjets } = props; // Cible CLIENT ou PARTENAIRE
  const { urlServeurNode } = useContext(ServerContext);
  const { userId } = useContext(AuthContext);
  const [projets, setListeProjets] = useState([]);
  const [etatProjet, setEtatProjet] = useState("ouvert"); // État local pour gérer l'état des projets

  const fetchListeProjetsEtModeles = useCallback(async () => {
    if (!userId) return;

    try {
      const response = await customAxios.get(`${urlServeurNode}/api/projets/projetsEtModeles/byUser/${userId}/${etatProjet}`);
      console.log("CountProjetsClientsByUserId: fetchListeProjetsEtModeles response.data",userId, response.data);

      const projetsFiltrésParCible = response.data.filter(
        (projet) => projet.modeleCible === cibleProjets
      );
      console.log("CountProjetsClientsByUserId: fetchListeProjetsEtModeles projetsFiltrésParCible",cibleProjets,  projetsFiltrésParCible);

      setListeProjets(projetsFiltrésParCible);
    } catch (error) {
      console.error("Erreur lors de la récupération des projets et des modèles :", error);
      setListeProjets([]);  // Assurer que projets est réinitialisé en cas d'erreur
    }
  }, [userId, urlServeurNode, etatProjet]);

  useEffect(() => {
    if (!urlServeurNode) return;
    fetchListeProjetsEtModeles();

    console.log("CountProjetsClientsByUserId: const socket = io(urlServeurNode)", urlServeurNode);

    const socket = io(urlServeurNode, {
      path: '/ws',
      auth: {
        token: Cookies.get("userTokenAcces")
      }
    });
    const updateProjects = () => {
      fetchListeProjetsEtModeles();
    };

    socket.on('nouveauProjet', updateProjects);
    socket.on('updateProjet', updateProjects);

    // return () => {
    //   socket.off('nouveauProjet', updateProjects);
    //   socket.off('updateProjet', updateProjects);
    //   socket.disconnect();
    // };

  }, [fetchListeProjetsEtModeles]);

  return (
    <>{userId && (
      <span className="badge badge-info">
        {projets.length}
      </span>
    )}</>
  );
}



