import { useContext } from 'react';
import { customAxios } from './utilities';
import ServerContext from '../../contexts/ServeurProvider';
import useGetModeles from './GetModeles';
import { useGetTiers } from './GetTiers';

export const useCreateNewProjet = () => {
  const { urlServeurNode } = useContext(ServerContext);
  const { fetchEtapesFromModeleId } = useGetModeles();
  const { getNameTiersByTiersId } = useGetTiers();

  const createNewProjet = async (
    modeleId,
    typeTiers,
    tiersId,
    userId,
    projets,
    descriptionProjet,
    tousLesGestionnaires
  ) => {
    try {
      const nomTemp = await getNameTiersByTiersId(tiersId);
      console.log("Nom temporaire du tiers:", nomTemp, modeleId);

      const proprieteProjetCommune = {
        createur: userId,
        gestionnaire: typeTiers === "PARTENAIRE" ? tousLesGestionnaires : [userId],
        etat: "enCours",
        modele: modeleId,
        actif: true,
        tiers: [tiersId],
        description: descriptionProjet,
      };

      let nouveauProjet;
      const projetExistant = projets?.find(projet => projet.tiers.includes(tiersId));

      if (!projetExistant) {
        const nomProjet = nomTemp.toUpperCase();
        nouveauProjet = { nom: nomProjet, ...proprieteProjetCommune };
      } else {
        let trancheNum = 2;
        while (true) {
          const trancheNom = `${nomTemp} Tranche ${trancheNum}`;
          const existe = projets.some(projet => projet.nom === trancheNom);
          if (!existe) {
            nouveauProjet = { nom: trancheNom, ...proprieteProjetCommune };
            break;
          }
          trancheNum++;
        }
      }

      if (nouveauProjet) {
        console.log("Création du projet avant envoi:", nouveauProjet);
        const { data: projetCree } = await customAxios.post(
          `${urlServeurNode}/api/projets`,
          nouveauProjet
        );
        console.log("Projet créé avec succès:", projetCree);

        const etapes = await fetchEtapesFromModeleId(modeleId);
        const etapesTransformees = etapes.map(etape => ({
          projet: projetCree._id,
          modeleEtape: etape._id,
          etat: "enCours",
          actionDestinataires: [],
        }));

        const { data: reponseEtapes } = await customAxios.post(
          `${urlServeurNode}/api/etapes/batch`,
          etapesTransformees
        );
        console.log("Étapes ajoutées au projet:", reponseEtapes);

        return projetCree;
      }

      return null;
    } catch (error) {
      console.error("Erreur lors de la création du projet:", error);
      return null;
    }
  };

  return { createNewProjet };
};
