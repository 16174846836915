import React, { useEffect, useState, useContext, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { customAxios } from "../Utilities/utilities";
import "./../css/Global.css";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
// import { fetchProjetByUserIdAndModeleId } from "../Utilities/GetModeles";
import { Tooltip } from "react-tooltip";
import io from 'socket.io-client'; 
import Cookies from "js-cookie";
import useFetchHelpers from "../Utilities/GetModeles";

export function CountProjetsByModeles({ AllAgences, ModeleId }) {
  const { urlServeurNode } = useContext(ServerContext);
  const { userId, agenceId } = useContext(AuthContext);
  const [nbModeles, setNbModeles] = useState(0);
  const {fetchProjetModeleId, fetchProjetByUserIdAndModeleId }  = useFetchHelpers(); 

  const fetchProjets = useCallback(async () => {
    if (!userId || !urlServeurNode || !agenceId) return;

    try {
      if (AllAgences) {
        const modeleIds = [ModeleId].filter(Boolean);
        if (modeleIds.length > 0) {
      
          const projets = await fetchProjetModeleId(modeleIds);
          // console.log("CountProjetsByModeles: fetchProjets ",projets);
          setNbModeles(projets.length);
        }
      } else {
        const projets = await fetchProjetByUserIdAndModeleId(userId, ModeleId);
        setNbModeles(projets ? projets.length : 0);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des projets:", error);
      setNbModeles(0);
    }
  }, [userId, urlServeurNode, agenceId, AllAgences, ModeleId]);

  useEffect(() => {
    if (!urlServeurNode) return;

    fetchProjets();
    
    console.log("CountProjetsByModeles: const socket = io(urlServeurNode)", urlServeurNode);

    const socket = io(urlServeurNode, {
      path: '/ws',
      auth: {
        token: Cookies.get("userTokenAcces")
      }
    });
    const handleNewProject = data => {
      console.log("WebSockets - Nouveau Projet:", data);
      fetchProjets();
    };
    socket.on('nouveauProjet', handleNewProject);

    // return () => {
    //   socket.off('nouveauProjet', handleNewProject);
    //   socket.disconnect();
    // };


  }, [fetchProjets]);

  return (
    <>
      {nbModeles > 0 && (
        <span 
          data-tooltip-id="TooltipCompteurProjets"
          data-tooltip-content="Nombre de Projets attachés à ce modèle"
          className="badge badge-info"
        >
          {nbModeles}
        </span>
      )}
      
      <>
        <Tooltip
          id="TooltipCompteurProjets"
          place="top"
          effect="solid"
          className="custom-tooltip"
        />
      </>
    </>
  );
}
