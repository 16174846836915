import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

function RequestForm({ onSubmit }) {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [requestType, setRequestType] = useState('');
  const [description, setDescription] = useState('');

  const handleSubmitRequest = (e) => {
    e.preventDefault();
    onSubmit({ email, phone, requestType, description });
  };

  return (
  
<Form onSubmit={handleSubmitRequest} style={{
    border: '1px solid #ced4da', 
    borderRadius: '18px',
    padding: '20px', // Ajout d'un peu de padding pour l'esthétique
    backgroundColor: '#fff' // Optionnel: pour assurer que le fond est bien blanc
  }}>
      <Form.Group controlId="formBasicRequestType">
        <Form.Label>Type de demande</Form.Label>
        <Form.Control
          as="select"
          value={requestType}
          onChange={e => setRequestType(e.target.value)}
          required
        >
          <option value="">Choisissez une option...</option>
          <option value="price">Demande de prix</option>
          <option value="demo">Demande de démo</option>
          <option value="misc">Divers</option>
        </Form.Control>
      </Form.Group>

      <Form.Group controlId="formBasicEmail">
        {/* <Form.Label>Email</Form.Label> */}
        <Form.Control
          type="email"
          placeholder="Entrez votre email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          required
        />
      </Form.Group>

      <Form.Group controlId="formBasicPhone">
        {/* <Form.Label>Téléphone</Form.Label> */}
        <Form.Control
          type="tel"
          placeholder="Entrez votre numéro de téléphone"
          value={phone}
          onChange={e => setPhone(e.target.value)}
          required
        />
      </Form.Group>

    

      <Form.Group controlId="formBasicDescription">
        {/* <Form.Label>Description de la demande</Form.Label> */}
        <Form.Control
          as="textarea"
          placeholder="Décrivez votre demande"
          style={{ marginBottom: '10px' }}
          required
          value={description}
          onChange={e => setDescription(e.target.value)}
        />
      </Form.Group>

      <Button variant="primary" type="submit">
        Envoyer la demande
      </Button>
    </Form>
  );
}

export default RequestForm;
