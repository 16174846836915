import React, { useEffect, useState, useContext, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import { CountSansAttentesProjetsByModeles } from "../Projets/CountSansAttentesProjetsByModeles";
import { CountProjetsByModeles } from "../Projets/CountProjetsByModeles";
import "./../css/Global.css";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { customAxios } from "../Utilities/utilities";
import { Tooltip } from "react-tooltip";
import io from "socket.io-client";
import Cookies from "js-cookie";

import { FaTrashAlt } from 'react-icons/fa'; // Import de l'icône de suppression
import useGetModeles from "../Utilities/GetModeles";
import { useFetchAgences } from "../Utilities/fetchAgences"; // Corrected here



export function ListeModeles({ AllAgences, Affichage, ModeGestion = "modeStandard" }) {
  const { fetchAgences } = useFetchAgences();
  const { urlServeurNode } = useContext(ServerContext);
  const [listeModelesDétaillés, setListeModelesDétaillés] = useState([]);
  const [checkedModeles, setCheckedModeles] = useState({});
  const [selectedModeleId, setSelectedModeleId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [listeDesTiers, setListeDesTiers] = useState([]);
  const [selectedAgenceId, setSelectedAgenceId] = useState(null);
  const [nomEnseigne, setNomEnseigne] = useState(null);

  const {
    handleModeleId,
    selectedMenu,
    tiersId,
    userId,
    agenceId,
    triggerAddModele,
    handleTriggerAddAction,
  } = useContext(AuthContext);

  const { fetchModeles }  = useGetModeles(); 

  const addNewModele = async () => {
    console.log("ListeModeles: addNewModele()");
    try {
      // Sauvegarde de la nouvelle action dans la base de données
    } catch (error) {
      console.error("Erreur lors de l'ajout d'un nouveau modèle:", error);
    }
  };

  const chargerTousLesModeles = useCallback(async (localParametreId) => {
    try {
      const tousLesModelesDetailles = await fetchModeles(localParametreId);
      console.log("ListeModeles fetchModeles tous les modèles", tousLesModelesDetailles);

      setListeModelesDétaillés(tousLesModelesDetailles);

      // Trouver et sélectionner le modèle par défaut (s'il existe)
      const modeleParDefaut = tousLesModelesDetailles.find(modele => modele.isDefault);
      if (modeleParDefaut) {
        setSelectedModeleId(modeleParDefaut._id); // Sélectionner le modèle par défaut
        handleModeleId(modeleParDefaut._id); // Mettre à jour le contexte si nécessaire
      }

  
    } catch (error) {
      console.error("Erreur lors du chargement des modèles:", error);
    }
  }, [urlServeurNode]); // Ajoutez toutes les dépendances nécessaires ici

  useEffect(() => {
    if (triggerAddModele) {
      console.log("ListeModeles: useEffect triggerAddModele", triggerAddModele);
      addNewModele(); // Appeler la fonction pour ajouter la nouvelle action
      // Réinitialisez triggerAddAction pour qu'il soit prêt pour le prochain clic
      handleTriggerAddAction(false);
    }
  }, [triggerAddModele, tiersId]);

  const fetchAgencesCallback = useCallback(async () => {
    try {
      const uniqueTiers = await fetchAgences(urlServeurNode);
      setListeDesTiers(uniqueTiers);
    } catch (error) {
      console.error("Erreur lors de la récupération des agences et administrateurs :", error);
    }
  }, [urlServeurNode]);

  const handleLabelClick = (ModeleId) => {
    console.log("ListeModeles handleLabelClick", ModeleId);

    handleModeleId(ModeleId);
    setSelectedModeleId(ModeleId); // Mise à jour avec le dernier ID sélectionné
    setErrorMessage(""); // Effacer le message d'erreur
    setCheckedModeles((prevState) => ({
      ...prevState,
      [ModeleId]: !prevState[ModeleId], // bascule l'état coché
    }));
  };

  const handleSocketEvent = useCallback(() => {
    console.log("ListeModeles handleSocketEvent ModeGestion", ModeGestion);
    if (ModeGestion === "modeAdministration") {
      fetchAgencesCallback();
      setSelectedAgenceId(null);  // On initialise aucun modele
    } else {
      chargerTousLesModeles(agenceId);
    }
  }, [ModeGestion, chargerTousLesModeles, agenceId]);

  useEffect(() => {
    console.log("ListeModeles useEffect", AllAgences, Affichage, ModeGestion);

    handleSocketEvent();

    const socket = io(urlServeurNode, {
      path: '/ws',
      auth: {
        token: Cookies.get("userTokenAcces")
      }
    });

    socket.on('updateModèle', handleSocketEvent);

    return () => {
      socket.off('updateModèle', handleSocketEvent);
    };


  }, [userId, Affichage, AllAgences, tiersId, ModeGestion, agenceId, chargerTousLesModeles, handleSocketEvent]);

  const handleAgenceChange = (event) => {
    const selectedAgenceId = event.target.value;
    setSelectedAgenceId(selectedAgenceId);

    if (selectedAgenceId) {
      console.log("ListeModeles handleAgenceChange: selectedAgenceId", selectedAgenceId);
      chargerTousLesModeles(selectedAgenceId);
    }
  };

  // useEffect(() => {
  //   fetchAgencesCallback();
  // }, [fetchAgencesCallback]);

  const handleModelChange = async (selectedModelId, type) => {
    try {
      const updatedModels = listeModelesDétaillés.map((modele) => {
        if (modele._id === selectedModelId) {
          // Si le modèle est le modèle sélectionné, mettez à jour isDefault ou isCheck basé sur le type
          return {
            ...modele,
            isDefault: type === 'radio' ? true : modele.isDefault, // Change uniquement si le type est 'radio'
            isCheck: type === 'checkbox' ? !modele.isCheck : modele.isCheck, // Bascule si le type est 'checkbox'
            afficheDestinataires: type === 'AfficheDestinataires' ? !modele.afficheDestinataires : modele.afficheDestinataires,
          };
        } else if (type === 'radio' && modele.isDefault) {
          // Pour les boutons radio, assurez-vous qu'un seul modèle est marqué comme par défaut
          return {
            ...modele,
            isDefault: false,
          };
        } else {
          return modele;
        }
      });

      console.log("handleModelChange: updatedModels", updatedModels, selectedModelId);
      setListeModelesDétaillés(updatedModels);

      // Créez un tableau de promesses pour les requêtes PATCH, envoyant uniquement les données pertinentes
      const patchPromises = updatedModels.map((modele) => {
        const updateData = {
          ...(type === 'radio' && { isDefault: modele.isDefault }),
          ...(type === 'checkbox' && { isCheck: modele.isCheck }),
          ...(type === 'AfficheDestinataires' && { afficheDestinataires: modele.afficheDestinataires }),
        };

        console.log("handleModelChange: updateData", updateData, selectedModelId);

        return customAxios.patch(`${urlServeurNode}/api/modeles/ModifieOptionsDuModele/${modele._id}`, updateData);
      });

      const responses = await Promise.all(patchPromises);
      responses.forEach((response, index) => {
        if (response.status === 200) {
          // console.log(`Mise à jour réussie pour le modèle ${updatedModels[index]._id}`);
        } else {
          console.error(`Erreur lors de la mise à jour pour le modèle ${updatedModels[index]._id}`);
        }
      });
    } catch (error) {
      console.error(error);
      alert("Une erreur est survenue lors de la mise à jour sur le serveur.");
    }
  };

  const handleDeleteModele = async (modeleId) => {
    try {
      const response = await customAxios.post(`${urlServeurNode}/api/projets/ByModeleId`, { modeleIds: [modeleId] });
      if (response.data.length > 0) {
        setErrorMessage("Impossible de supprimer le modèle. Des projets lui sont encore attachés.");
        return;
      }

      console.log("ListeModeles selectedAgenceId",selectedAgenceId , "modeleId", modeleId);
      await customAxios.delete(`${urlServeurNode}/api/modeles/${modeleId}`);
      setListeModelesDétaillés((prevModeles) => prevModeles.filter((modele) => modele._id !== modeleId));

      if (selectedAgenceId) {
        await customAxios.patch(`${urlServeurNode}/api/tiers/${selectedAgenceId}/delete-modele`, { modeleId });
      }
    } catch (error) {
      console.error("Erreur lors de la suppression du modèle:", error);
    }
  };

  // Fonction pour rendre l'en-tête du tableau
  const renderTableHeader = () => {
    return (
      <>
        <th></th>
        {Affichage === "Complet" && (
          <>
            <th
              style={{ textAlign: "left", fontWeight: 600 }}
              data-tooltip-id={`Nom du contrat`}
              data-tooltip-content={"Type de contrat."}
              data-tooltip-place="top"
            >
              CONTRATS
            </th>
            <th
              style={{ textAlign: "left", fontWeight: 600 }}
              data-tooltip-id={`Cible du Projet`}
              data-tooltip-content={"A qui s'adresse ce projet?"}
              data-tooltip-place="top"
            >
              CIBLE
            </th>
            <th
              style={{ textAlign: "left", fontWeight: 600 }}
              data-tooltip-id={`Modèle par défaut`}
              data-tooltip-content={"Détermine le modèle par défaut, à la création d'un nouveau projet"}
              data-tooltip-place="top"
            >
              DÉFAUT
            </th>
            <th
              style={{ textAlign: "left", fontWeight: 600 }}
              data-tooltip-id={`A vérifier`}
              data-tooltip-content={"Vérifie si les projets sont à jour dans la Synthèse et DashBoard"}
              data-tooltip-place="top"
            >
              CHECK
            </th>
            <th
              style={{ textAlign: "left", fontWeight: 600 }}
              data-tooltip-id={`Destinataires`}
              data-tooltip-content={"Affiche les destinataires dans les attentes et requêtes."}
              data-tooltip-place="top"
            >
              DESTINATAIRES
            </th>
            <th
              style={{ textAlign: "left", fontWeight: 600 }}
              data-tooltip-id={`Supprimer modèle`}
              data-tooltip-content={"Supprimer ce modèle"}
              data-tooltip-place="top"
            >
              SUPPRIMER
            </th>
          </>
        )}
      </>
    );
  };

  const renderTableBody = () => {
    return listeModelesDétaillés.map((modele, index) => (
      <tr key={modele._id} className={index % 2 === 0 ? "bg-custom" : ""}>
        <td
          className="ps-1"
          style={{
            width: "1%",
            borderRadius: "6px 0 0 6px",
            paddingLeft: "5px",
            cursor: "pointer",
          }}
        >
          <input
            className="form-check-input"
            type="checkbox"
            id={`flexCheckDefault${modele._id}`}
            checked={modele._id === selectedModeleId}
            onChange={() => handleLabelClick(modele._id)}
          />
        </td>
        <td>
          <label
            className={`${modele._id === selectedModeleId ? "label-semi-gras" : ""}`}
            htmlFor={`flexCheckDefault${modele._id}`}
            style={{ cursor: "pointer" }}
            onClick={() => handleLabelClick(modele._id)}
          >
            <span style={{ marginLeft: "5px" }}>{modele.nom}</span>
            <span style={{ marginLeft: "6px" }}>
              <CountProjetsByModeles AllAgences={AllAgences} ModeleId={modele._id} />
              {Affichage === "Léger" && (
                <>
                  <CountSansAttentesProjetsByModeles AllAgences={AllAgences} ModeleId={modele._id} />
                </>
              )}

              {(!modele.etapes || modele.etapes.length === 0) && (
                <>
                  <span style={{ color: "green", marginLeft: "10px", fontSize: '20px' }} data-tooltip-id={`noStep-${modele._id}`} title="Aucune étape rattachée">
                    ▲
                  </span>
                  <Tooltip id={`noStep-${modele._id}`} place="top" effect="solid" className="custom-tooltip">
                    Aucune étape rattachée
                  </Tooltip>
                </>
              )}
            </span>
          </label>
        </td>
        {Affichage === "Complet" && (
          <>
            <td>
              <label>{modele.cible}</label>
            </td>
            <td style={{ textAlign: "center" }}>
              <input
                type="radio"
                name="isDefault"
                id={`radioDefault${modele._id}`}
                checked={modele.isDefault}
                onChange={() => handleModelChange(modele._id, "radio")}
              />
            </td>
            <td style={{ textAlign: "center" }}>
              <input
                type="checkbox"
                name="isCheck"
                id={`checkboxCheck${modele._id}`}
                checked={modele.isCheck}
                onChange={() => handleModelChange(modele._id, "checkbox")}
              />
            </td>
            <td
              style={{
                textAlign: "center",
                width: "1%",
                borderRadius: "0 6px 6px 0",
                paddingLeft: "5px",
                cursor: "pointer",
              }}
            >
              <input
                type="checkbox"
                name="afficheDestinataires"
                id={`checkboxDestinataires${modele._id}`}
                checked={modele.afficheDestinataires}
                onChange={() => handleModelChange(modele._id, "AfficheDestinataires")}
              />
            </td>
            <td style={{ textAlign: "center" }}>
              <FaTrashAlt
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => handleDeleteModele(modele._id)}
                data-tooltip-id={`delete-${modele._id}`}
                title="Supprimer ce modèle"
              />
              <Tooltip id={`delete-${modele._id}`} place="top" effect="solid" className="custom-tooltip">
                Supprimer ce modèle
              </Tooltip>
            </td>
          </>
        )}
      </tr>
    ));
  };

  const renderContent = () => {
    if (!userId) return <p>Non connecté</p>;
    if (listeModelesDétaillés.length === 0) return <p>Aucun modèle trouvé.</p>;

    return (
      <>
        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
        <table>
          <thead>
            <tr>{renderTableHeader()}</tr>
          </thead>
          <tbody>{renderTableBody()}</tbody>
        </table>

        {Affichage === "Complet" && (
          <>
            <Tooltip id="Nom du contrat" place="top" effect="solid" className="custom-tooltip" />
            <Tooltip id="Cible du Projet" place="top" effect="solid" className="custom-tooltip" />
            <Tooltip id="Modèle par défaut" place="top" effect="solid" className="custom-tooltip" />
            <Tooltip id="A vérifier" place="top" effect="solid" className="custom-tooltip" />
            <Tooltip id="Destinataires" place="top" effect="solid" className="custom-tooltip" />
          </>
        )}
      </>
    );
  };

  return (
    <>
      {ModeGestion === "modeAdministration" && (
        <div className="form-group">
          <label htmlFor="selectAgence">Sélectionnez une agence :</label>
          <select id="selectAgence" className="form-control" onChange={handleAgenceChange}>
            <option value="">Sélectionnez une agence</option>
            {listeDesTiers.map((agence) => (
              <option key={agence._id} value={agence._id}>
                {agence.nom}
              </option>
            ))}
          </select>
        </div>
      )}

      <h5 className="titre-zone2">
        {selectedMenu === "ADMIN MODÈLES" ? "Modèles " : "Contrats "} {nomEnseigne}
      </h5>

      <div className="cadre-zone2">
        {renderContent()}
      </div>
    </>
  );
}
