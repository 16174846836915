import React from "react";
import ReactDOM from "react-dom/client";
import "./css/index.css";
import App from "./App";
import { AuthProvider } from "./contexts/AuthProvider";
import { ProjetProvider } from "./contexts/ProjetContext"; 

import reportWebVitals from "./reportWebVitals";
import ReactModal from 'react-modal';

ReactModal.setAppElement('#root'); // Assurez-vous de configurer cela à l'élément racine de votre application.


const root = ReactDOM.createRoot(document.getElementById("root"));
console.log("Index.js");

root.render(
  // <React.StrictMode>
    <AuthProvider>
       <ProjetProvider>
        <App />
      </ProjetProvider>
    </AuthProvider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
