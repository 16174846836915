import React, { useEffect, useState, useContext, useCallback } from "react";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";

import { fetchEnsembleAgences, customAxios } from "../Utilities/utilities";

import io from "socket.io-client";
import Cookies from "js-cookie";

//<CountListeTiers typeTiers="PARTENAIRE" AllAgences={false} />
// typeTiers = "AGENCE" "PARTENAIRE", "PRESTATAIRE"
// AllAgences = true/false Compteur de tous les tiers rattaché au userId ou l'agence: donc tous les utilisateurs de l'agence
export function CountListeTiers(props) {
  const [countListeTiers, setCountListeTiers] = useState([]);
  const { typeTiers, AllAgences } = props;
  const { urlServeurNode } = useContext(ServerContext);
  const { userId, agenceId } = useContext(AuthContext);
  const fetchListeTiers = useCallback(async () => {

    try {
      const etat = "ouvert";

      if (AllAgences) {
        // On récupère tous les utilisatTiers eurs de l'Agence basé sur typeTiers

        try {
          const response = await customAxios.post(
            `${urlServeurNode}/api/tiers/getTiersByAgenceIdAndTypeTiers`,
            {
              agenceId: agenceId,
              typeTiers: typeTiers,
            }
          );
          console.log("CountListeTiers: AllAgences",AllAgences,"typeTiers",typeTiers,"taille réponse", response.data.tiers.length, "response", response.data);
          setCountListeTiers(response.data.tiers.length);
        } catch (error) {
          console.error("Erreur lors de la récupération des tiers:", error);
        }
      
      } else {
        // Si AllAgences est à false alors On récupère les info sur le userId courant
        if (typeTiers && userId) {
          const response = await customAxios.get(
            `${urlServeurNode}/api/tiers/count/${userId}/${typeTiers}?etat=${etat}`
          );

          console.log("CountListeTiers: typeTiers",typeTiers,"response", response.data);

          setCountListeTiers(response.data.nbTiers);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [userId]);

  useEffect(() => {
    if (!urlServeurNode) return;

    fetchListeTiers();

    console.log(
      "CountListeTiers: const socket = io(urlServeurNode)",
      urlServeurNode
    );

    const socket = io(urlServeurNode, {
      path: "/ws",
      auth: {
        token: Cookies.get("userTokenAcces"),
      },
    });
    // Écoute pour un événement spécifique, exemple: 'updateTiers'
    socket.on("updateTiers", (data) => {
      console.log("CountListeTiers: webSockets updateTiers", data);
      fetchListeTiers(); // Rafraîchit les données chaque fois que l'événement est reçu
    });

    // Nettoyage de la connexion à la fermeture du composant
  }, [userId]);

  return (
    <>
      {userId ? (
        <span className="badge badge-info">{countListeTiers}</span>
      ) : (
        ""
      )}
    </>
  );
}
