import React, { useEffect, useState, useContext, useCallback } from "react";
import "../css/Compteurs.css";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { fetchEnsembleAgences, customAxios } from "../Utilities/utilities";
import io from 'socket.io-client'; 
import Cookies from "js-cookie";


/**
 * Composant CountAllProjetsClients
 * Ce composant récupère et affiche le nombre de projets uniques dont l'état est "ouvert" ou "archivé" pour tous les utilisateurs d'une agence.
 * Il utilise les contextes ServerContext et AuthContext pour obtenir les informations nécessaires sur le serveur et l'utilisateur.
 * Les projets sont récupérés via des appels API et les résultats sont filtrés pour garantir l'unicité des projets.
 * Un socket est utilisé pour mettre à jour la liste des projets en temps réel lorsque de nouveaux projets sont ajoutés.
 */

export function CountAllProjetsClients() {
  // const { etatProjet } = props;
  const { urlServeurNode } = useContext(ServerContext);
  const { userId, agenceId } = useContext(AuthContext);
  const [projets, setListeProjets] = useState([]);

  const fetchProjetsAll = useCallback(async () => {
    try {
      const data = await fetchEnsembleAgences(urlServeurNode, agenceId);
      const projetsListe = [];
      const etats = ["ouvert", "archivé"];
      const projetsResponse = await Promise.allSettled(
        data.TousLesUtilisateurs.flatMap(utilisateur =>
          etats.map(etat =>
            customAxios.get(`${urlServeurNode}/api/projets/projetsEtModeles/byUser/${utilisateur.utilisateurId}/${etat}`)
          )
        )
      );

      console.log("CountAllProjetsClients projetsResponse", projetsResponse);

      const projetIds = new Set();
      projetsResponse.forEach(response => {
        if (response.status === "fulfilled") {
          response.value.data.forEach(projet => {
            if (!projetIds.has(projet._id)) {
              projetsListe.push(projet);
              projetIds.add(projet._id);
            }
          });
        }
      });

      setListeProjets(projetsListe);
    } catch (error) {
      console.error("CountAllProjetsClients: fetchProjetsAll: Erreur lors de la récupération des projets :", error);
    }
  }, [userId, urlServeurNode, agenceId]);

  useEffect(() => {
    if (!urlServeurNode) return;
    fetchProjetsAll();

    console.log("CountAllProjetsClients: const socket = io(urlServeurNode)", urlServeurNode);

    const socket = io(urlServeurNode, {
      path: '/ws',
      auth: {
        token: Cookies.get("userTokenAcces")
      }
    });
    socket.on('nouveauProjet', fetchProjetsAll);

    return () => {
      socket.off('nouveauProjet', fetchProjetsAll);
      socket.disconnect();
    };
  }, [fetchProjetsAll]);

  return userId ? <span className="badge badge-info">
    {projets.filter(projet => projet.modeleCible === "CLIENT").length}
  </span> : null;
}