
// Pour les Tooltip supprime les caractères non alphanumérique
export const  cleanString = (inputStr) => {
  // Supprimer ou remplacer les caractères spéciaux
  return inputStr
  .replace(/:/g, '-')   // Remplacer ':' par '-'
  .replace(/'/g, '')    // Supprimer les apostrophes
  .replace(/"/g, '')    // Supprimer les guillemets
  .replace(/[^a-zA-Z0-9-_]/g, ''); // Supprimer tout caractère qui n'est pas alphanumérique, '-' ou '_'
}


export const  calculerDifferenceJours = (echeance) => {
  const currentDate = new Date();
  const dueDate = new Date(echeance);
  const timeDiff = dueDate - currentDate;
  return Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
};



export const getFontColor = (backgroundClass) => {
    if (backgroundClass.includes("bg-warning")) {
      return "var(--gris-bg-warning)";  // rgb(105, 105, 105)
    } else {
      return "white";
    }
};

export const getBackgroundClass = (joursRestants) => {
    if (joursRestants > 0) {
      return "bg-success bg-gradient";
    } else if (joursRestants > -7 && joursRestants <= 0) {
      return "bg-warning bg-gradient";
    } else if (joursRestants <= -7) {
      return "bg-danger bg-gradient";
    } else {
      return ""; // ou une autre classe par défaut si nécessaire
    }
};


export const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    return `${d.getDate().toString().padStart(2, "0")}/${(d.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${d.getFullYear()}`;
};


// Fonction pour déterminer le contenu de l'infobulle
export const getTooltipTypeActionContent = (field) => {

switch (field) {
  case "FICHIER":
    return "Télécharger les fichiers";
  case "DATE":
    return "Renseigner la date";
  case "N/A":
    return "Just Do It!";
  case "ENVOYER":
    return "Transmettre les éléments";
  case "OUI/NON":
    return "Répondre Oui ou Non?";
  case "TEXTE":
    return "Texte libre";

  default:
    return "Action Non Définie"; // Retourne une chaîne vide par défaut
}
};
