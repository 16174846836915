import React, { useContext, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import ServerContext from "../../contexts/ServeurProvider";
import { customAxios } from "../Utilities/utilities";
import { AuthContext } from "../../contexts/AuthProvider";

//
// Création d'un nouvel utilisateur et ajout au Tiers 
//
export function CreerUtilisateur() {
  const { urlServeurNode } = useContext(ServerContext);
  const { handleAddNewUser, tiersId } = useContext(AuthContext);
  
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
        // reseauxSociaux: [{ type: "", url: "" }],
        role: "Utilisateur", // 'Utilisateur' comme valeur par défaut pour le rôle
        actif: true, // Actif par défaut
        favori: false, // Non favori par défaut
        civilite: "Mr", // 'Mr' comme valeur par défaut pour la civilité
      }
  });

  const emailValue = watch("email"); 

  // React.useEffect(() => {
  useEffect(() => {
    setValue("login", emailValue); // S'assurer que le login est toujours égal à l'email
  }, [emailValue, setValue]);


  const { fields, append, remove } = useFieldArray({
    control,
    name: "reseauxSociaux",
  });

  const onSubmit = async (data) => {
    // Assurez-vous de mapper le champ 'mdp' en 'password' pour l'API
    const formData = {
      ...data,
      password: data.mdp,
      tiersId, // Ajoutez l'ID du tiers à la soumission
    };
    delete formData.mdp; // Supprime l'entrée 'mdp' pour éviter la confusion

    try {
      const response = await customAxios.post(`${urlServeurNode}/api/utilisateurs`, formData);
      console.log("Utilisateur créé avec succès:", response.data);
      // Gérer la suite après création (ex : redirection, notification de succès, etc.)
      handleAddNewUser(false);
    } catch (error) {
      console.error("Erreur lors de la création de l'utilisateur:", error);
      // Gérer l'erreur (ex : afficher un message d'erreur)
    }
  };

  const handleCancel = () => {
    handleAddNewUser(false); // Ceci pourrait masquer le formulaire si c'est l'action souhaitée.
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-utilisateur">
      <h5>Créer un nouvel utilisateur</h5>

      <div className="form-group">
        <label>Civilité</label>
        <select {...register("civilite", { required: "La civilité est requise." })}>
          <option value="Mr">Mr</option>
          <option value="Mme">Mme</option>
        </select>
        {errors.civilite && <p className="error">{errors.civilite.message}</p>}
      </div>

      <div className="form-group">
        <label>Prénom</label>
        <input {...register("prenom", 
        { 
            required: "Le prénom est requis.", 
            minLength: 2, maxLength: 50 ,
            pattern: {
              value: /^[A-Za-zÀ-ÖØ-öø-ÿ]+(?:[ -][A-Za-zÀ-ÖØ-öø-ÿ]+)*$/,
              message: "Format de prenom invalide",
            },
      })} />
        {errors.prenom && <p className="error">{errors.prenom.message}</p>}
      </div>

      <div className="form-group">
        <label>Nom</label>
        <input {...register("nom", 
        { 
          required: "Le nom est requis.", 
          minLength: 2, maxLength: 50,              
          pattern: {
            value: /^[A-Za-zÀ-ÖØ-öø-ÿ]+(?:[ -][A-Za-zÀ-ÖØ-öø-ÿ]+)*$/,
            message: "Format de nom invalide",
          },
       })} 
        
        />
        {errors.nom && <p className="error">{errors.nom.message}</p>}
      </div>

      <div className="form-group">
        <label>Téléphone</label>
        <input {...register("telephone", { required: "Le téléphone est requis." })} />
        {errors.telephone && <p className="error">{errors.telephone.message}</p>}
      </div>

      <div className="form-group">
        <label>Email</label>
        <input type="email" {...register("email", { required: "L'email est requis." })} />
        {errors.email && <p className="error">{errors.email.message}</p>}
      </div>

      <div className="form-group">
        <label>Mot de passe</label>
        <input {...register("mdp", { required: "Un mot de passe est requis.", minLength: { value: 8, message: "Le mot de passe doit contenir au moins 8 caractères." } })} type="password" />
        {errors.mdp && <p className="error">{errors.mdp.message}</p>}
      </div>

      <div className="form-group">
        <label>Adresse</label>
        <input {...register("adresse")} />
      </div>

      <div className="form-group">
        <label>Code Postal</label>
        <input {...register("cp")} />
      </div>

      <div className="form-group">
        <label>Ville</label>
        <input {...register("ville")} />
      </div>

      <div className="form-group">
        <label>Date de naissance</label>
        <input type="date" {...register("dateNaissance", { required: "La date de naissance est requise." })} />
        {errors.dateNaissance && <p className="error">{errors.dateNaissance.message}</p>}
      </div>

      <div className="form-group">
        <label>Réseaux Sociaux</label>
        {fields.map((item, index) => (
          <div key={item.id}>
            <input {...register(`reseauxSociaux.${index}.type`, { required: "Le type de réseau social est requis." })} placeholder="Type de réseau" />
            <input {...register(`reseauxSociaux.${index}.url`, { required: "L'URL du réseau social est requise." })} placeholder="URL" />
            <button type="button" onClick={() => remove(index)}>Supprimer</button>
          </div>
        ))}
        <button type="button" onClick={() => append({ type: "", url: "" })}>Ajouter un réseau social</button>
      </div>

      {/* <button type="submit">Créer Utilisateur</button> */}

      <div className="action-buttons" style={{ display: 'flex', justifyContent: 'space-between', margin: '20px' }}>
        <button type="button" className="btn btn-secondary" onClick={handleCancel}>Annuler</button>
        <button type="submit" className="btn btn-primary">Créer Utilisateur</button>
        <hr />
      </div>
    </form>

  );
}
