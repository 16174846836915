import React, { useContext, useState, useEffect } from "react";
import "./../css/Global.css";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { customAxios } from "../Utilities/utilities";
import { useProjet } from '../../contexts/ProjetContext';

//
// utilisateur attaché à un Projet 
//
export function UtilisateursByTiersId() {
  const { handleLogout, tiersId } =
    useContext(AuthContext);

  const { urlServeurNode } = useContext(ServerContext);
  const [gestionnaire, setGestionnaire] = useState({});
  const [isLoading, setIsLoading] = useState(true);

 

  const fetchUtilisateursByTiersId = async () => {
    try {
      if (tiersId) {
        // console.log("UtilisateursByTiersId: tiersId:", tiersId);
        const responseTiers = await customAxios.get(
          `${urlServeurNode}/api/tiers/${tiersId}`
        );

     
        const  gestionnaireIds= responseTiers.data.gestionnaire;
        console.log(
          "UtilisateursByTiersId fetchUtilisateursByTiersId gestionnaireIds",
          gestionnaireIds
        );
        if (gestionnaireIds && gestionnaireIds.length > 0) {
          console.log(
            "UtilisateursByTiersId fetchUtilisateursByTiersId gestionnaireIds",
            gestionnaireIds
          );

          const gestionnaires = [];

          for (const gestionnaireId of gestionnaireIds) {
            const response = await customAxios.get(
              `${urlServeurNode}/api/utilisateurs/${gestionnaireId}?actif=true`
            );
            console.log(
              "UtilisateursByTiersId UtilisateurById fetchUtilisateurByid utilisateurId",
              gestionnaireId,
              "response.data",
              response.data
            );

            gestionnaires.push(response.data); // On récupère le nom de l'utilisateur et l'ajoute au tableau
          }

          setGestionnaire(gestionnaires); // On définit le tableau des créateurs
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log(
          `UtilisateursByTiersId: erreur 401 pas d'accès Token expiré`
        );
        // Déconnexion de l'utilisateur et redirection vers la page de login
        handleLogout();
      } else {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    // console.log("UtilisateursByTiersId useEffect tiersId", tiersId);
    fetchUtilisateursByTiersId();
  }, [tiersId]);

  return (
    // <div className="d-flex flex-column cadre-zone4 contenu-zone4 center-content">
    <div className="cadre-zone4 center-content">
      {!isLoading &&
        gestionnaire.map((g, index) => (
          <div key={index}>
            {g.prenom} {g.nom}
          </div>
        ))}
    </div>
  );
}
