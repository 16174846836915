import React, { useEffect, useState, useContext } from "react";
import "../css/Compteurs.css";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { customAxios } from "../Utilities/utilities";

export function CountCorpsDeMétiers() {
  const [nbCorpsDeMetiers, setnbCorpsDeMetiers] = useState([]);
  const { urlServeurNode } = useContext(ServerContext);
  const { userId } = useContext(AuthContext);

  useEffect(() => {
    const fetchNbCorpsDeMetiers = async () => {
      try {
        const response = await customAxios.get(
          `${urlServeurNode}/api/corpsdemetiers`
        );
    
        setnbCorpsDeMetiers(response.data.length); // Obtention de la taille de la liste
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.log(
            `CountCorpsDeMétiers: erreur 401 pas d'accès Token expiré`
          );
        } else {
          console.error(error);
        }
      }
    };

    fetchNbCorpsDeMetiers();
  }, [userId]);

  return (
    <>
      {userId ? (
        <span className="badge badge-info">{nbCorpsDeMetiers}</span>
      ) : (
        ""
      )}
    </>
  );
}
