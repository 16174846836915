import React, { useEffect, useState, useContext, useMemo } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/ListeProjets.css";
import "./../css/Global.css";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { customAxios } from "../Utilities/utilities";
import { Tooltip } from "react-tooltip";

// On affiche les projets en fonction du typeTiers qui cibleProjets
// Cible == CLIENT ou PARTENAIRE
export function ListeProjetsByUserId(props) {
  const { cibleProjets } = props; // Cible CLIENT ou PARTENAIRE
  const { urlServeurNode } = useContext(ServerContext);
  const [projets, setListeProjets] = useState([]);
  const [hoveredProjetId, setHoveredProjetId] = useState("");
  const [isToggled, setIsToggled] = useState(true); // Pour Afficher les Projets Clients ou autres
  const [etatProjet, setEtatProjet] = useState("ouvert"); // État local pour gérer l'état des projets

  const { handleProjetId, userId, handleEtapeId, searchTerm } =
    useContext(AuthContext);

  const handleToggleChange = () => {
    setIsToggled(!isToggled);
    setEtatProjet(isToggled ? "archivé" : "ouvert"); // Mettre à jour l'état en fonction du toggle
  };

  const handleLabelClick = (projetId, e) => {
    e.stopPropagation(); // Empêcher la propagation du clic au niveau de la checkbox

    console.log("ListeProjetsByUserId: ListeProjetsByUserId handleLabelClick", projetId);
    handleProjetId(projetId);
    console.log(
      "ListeProjetsByUserId: ListeProjetsByUserId handleLabelClick etapeId NULL"
    );
    handleEtapeId(null);
  };

  const ToggleSwitch = () => (
    <label className="toggle-switch">
      <input
        type="checkbox"
        checked={isToggled}
        onChange={handleToggleChange}
      />
      <span className="switch-slider round">
        <span className="switch-state">
          {isToggled ? "Actifs" : "En Archive"}
        </span>
      </span>
    </label>
  );

  useEffect(() => {
    async function fetchListeProjets() {
      try {
        // Faire la requête à l'API qui retourne les projets enrichis
        const response = await customAxios.get(
          `${urlServeurNode}/api/projets/projetsEtModeles/byUser/${userId}/${etatProjet}`
        );
        const projetsAvecDetails = response.data;

        // Mise à jour de l'état avec la liste des projets triés
        setListeProjets(projetsAvecDetails);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des projets et des modèles :",
          error
        );
      }
    }

    fetchListeProjets();
  }, [cibleProjets, userId, urlServeurNode, etatProjet, isToggled]);

  // Effet pour mettre à jour le toggle et l'état du projet lorsque cibleProjets change
  useEffect(() => {
    setIsToggled(true);
    setEtatProjet("ouvert");
  }, [cibleProjets]);

  const handleCheckClick = async (projetId) => {
    if (cibleProjets === "PARTENAIRE") {
      console.warn("Archivage des projets non autorisé pour les PARTENAIRES");
      return;
    }

    try {
      // En global on a accès à tous les projets dans projets
      // Trouver le projet correspondant à projetId
      const projet = projets.find(projet => projet._id === projetId);
  
      if (!projet) {
        console.error("Projet introuvable");
        return;
      }
  
      // Trouver l'accès projet pour le userId
      const accesProjet = projet.accesProjet.find(acces => acces.utilisateur === userId);
  
      if (!accesProjet) {
        console.error("Accès projet pour l'utilisateur introuvable");
        return;
      }
  
      // Déterminer le nouvel état
      const nouvelEtat = accesProjet.etat === "ouvert" ? "archivé" : "ouvert";
  
      // Mettez à jour l'état du projet sur le serveur
      const response = await customAxios.put(
        `${urlServeurNode}/api/projets/${projetId}`,
        { etat: nouvelEtat, userId: userId }
      );
  
      
      if (response.data && response.data._id) {
        // Mettre à jour l'état local des projets
        setListeProjets((prevProjets) =>
          prevProjets.filter((projet) => projet._id !== projetId)
        );

        handleProjetId(null);
        handleEtapeId(null);
  

      } else {
        console.error("La mise à jour du projet a échoué.");
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du projet:", error);
    }
  };

  // useMemo pour mémoriser les projets filtrés et triés
  const filteredProjets = useMemo(() => {
    // console.log(
    //   "ListeProjetsByUserId filteredProjets",
    //   projets
    // );

    const projetsFiltrésParNom = projets.filter((projet) =>
      projet.nom.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const projetsFiltrésParCible = projetsFiltrésParNom.filter(
      (projet) => projet.modeleCible === cibleProjets
    );

    projetsFiltrésParCible.sort((a, b) => a.nom.localeCompare(b.nom));

    console.log(
      "ListeProjetsByUserId projetsFiltrésParCible",
      projetsFiltrésParCible
    );

    return projetsFiltrésParCible;
  }, [projets, searchTerm, cibleProjets, isToggled]); // Dépend de l'état projets, searchTerm, cibleProjets et isToggled

  return (
    <div className="fenetre-liste-projets">
      {/* On affiche ici le toggle les projets des cibles Clients ou les autres */}

      <div
        className="titre-zone2"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "90%",
        }}
      >
        <h5>PROJETS</h5>
        <h5>{cibleProjets}S</h5>
      </div>

      <div className="fenetre-liste-tiers">
        <div className="center-toggle-switch">
          <ToggleSwitch />
        </div>
        <div className="cadre-zone2">
          {filteredProjets.length > 0 ? (
            <ul className="liste-sans-puces">
              {filteredProjets.map((projet, index) => (
                <li key={projet._id}>
                  <div
                    className="form-check d-flex ms-1"
                    onMouseEnter={() => setHoveredProjetId(projet._id)}
                    onMouseLeave={() => setHoveredProjetId("")}
                  >
                    <input
                      data-tooltip-id={`infoCheckBox-${projet._id}`}
                      data-tooltip-content={
                        projet.etat === "enCours"
                          ? "Archiver"
                          : "Réactiver le projet"
                      }
                      data-tooltip-place="top"
                      className="form-check-input me-1"
                      type="checkbox"
                      // Désactiver la case à cocher si cibleProjets est "PARTENAIRE"
                      disabled={cibleProjets === "PARTENAIRE"}
                      onChange={() => handleCheckClick(projet._id)}
                    />

                    <Tooltip
                      id={`infoCheckBox-${projet._id}`}
                      place="top"
                      effect="solid"
                      className="custom-tooltip"
                    ></Tooltip>

                    <label
                      onClick={(e) => handleLabelClick(projet._id, e)}
                      className={`form-check-label ${
                        projet._id === hoveredProjetId && "label-semi-gras"
                      }`}
                      style={{ cursor: "pointer" }}
                    >
                      {projet.nom} ({projet.modeleName || "Chargement..."})
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p>Aucun projet trouvé.</p>
          )}
        </div>
      </div>
    </div>
  );
}