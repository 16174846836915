import React, { useContext, useState, useEffect } from "react";
import "./../css/Global.css";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { customAxios } from "../Utilities/utilities";
import ContentEditableField from "../Utilities/ContentEditableField";
import { useProjet } from '../../contexts/ProjetContext';

export function DescriptionByProjetId() {
  const { handleLogout, projetId } = useContext(AuthContext);
  const { urlServeurNode } = useContext(ServerContext);
  const [description, setDescription] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { projetData } = useProjet();


  const fetchDescriptionByProjetId = async () => {
    try {
      if (projetId) {
        const responseProjet = projetData;  // On utilise le contexte
        console.log("DescriptionByProjetId Réponse du projet :", responseProjet);
        console.log("DescriptionByProjetId Description du projet :", responseProjet.description);

        if (responseProjet.description) {
          setDescription(responseProjet.description);
        } else {
          console.log("Description non disponible dans la réponse du projet");
          setDescription('');
        }
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log(`DescriptionByProjetId: erreur 401 pas d'accès Token expiré`);
        handleLogout();
      } else {
        console.error(error);
      }
    }
  };

  const handleSave = async (value) => {
    console.log("DescriptionByProjetId: handleSave description", value);
    if (!projetId) return;
    setIsLoading(true);
    try {
      const payload = { description: value };
      console.log("DescriptionByProjetId: handleSave payload", payload);
      const response = await customAxios.patch(
        `${urlServeurNode}/api/projets/alone/${projetId}`,
        payload
      );
      setDescription(value);
    } catch (error) {
      console.error(error);
    }
    setEditMode(false);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDescriptionByProjetId().catch(error => {
      console.error("Erreur lors du chargement de la description", error);
    });
  }, [projetId]);

  return (
    <div className="cadre-zone4 contenu-zone4 center-content">
      {isLoading ? (
        <p>Loading...</p>
      ) : editMode ? (
        <ContentEditableField
          initialValue={description}
          onSave={(value) => handleSave(value)}
        />
      ) : (
        <div onClick={() => setEditMode(true)}>
          {description || "Description du projet"}
        </div>
      )}
    </div>
  );
}
