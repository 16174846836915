import React, { useState, useEffect, useContext } from 'react';
import { FiChevronRight, FiChevronDown } from "react-icons/fi";
import { Tooltip } from "react-tooltip";
import {
    BsFiles,
    BsArchive,
    BsEnvelope,
} from "react-icons/bs";

import io from 'socket.io-client';
import Cookies from "js-cookie";

import ServerContext from "../../contexts/ServeurProvider";
import { customAxios } from '../Utilities/utilities';
import { useProjet } from "../../contexts/ProjetContext";
import useHandleFileClick from '../Utilities/GetFiles'; // Importation du hook personnalisé

export const ListeFichiers = () => {
    const { urlServeurNode } = useContext(ServerContext);

    const [fichiers, setFichiers] = useState([]);
    const [activeRubriques, setActiveRubriques] = useState({});
    const [activePrefixes, setActivePrefixes] = useState({});
    const { projetData } = useProjet();
    const [selectedFiles, setSelectedFiles] = useState(new Set());

    const handleCheckboxChange = (fileId) => {
        setSelectedFiles(prev => {
            const newSet = new Set(prev);
            if (newSet.has(fileId)) {
                newSet.delete(fileId);
            } else {
                newSet.add(fileId);
            }
            return newSet;
        });
    };

    useEffect(() => {
        if (!urlServeurNode) return;

        if (!projetData || !projetData._id) return;

        const fetchFichiers = async () => {
            try {
                const response = await customAxios.get(`${urlServeurNode}/api/files/projet/${projetData._id}`);
                setFichiers(groupByRubriqueAndPrefixe(response.data));
            } catch (error) {
                console.log("ListeFichiers fetchFichiers PAS DE FICHIERS");
                console.error('Erreur lors de la récupération des fichiers:', error);
                setFichiers([]);
            }
        };

        fetchFichiers();

        const socket = io(urlServeurNode, {
            path: '/ws',
            auth: {
                token: Cookies.get("userTokenAcces")
            }
        });
        socket.on('updateEtape', fetchFichiers);
        socket.on('updateProjet', fetchFichiers);

    }, [projetData?._id, urlServeurNode]);

    const groupByRubriqueAndPrefixe = (data) => {
        const grouped = data.reduce((acc, fichier) => {
            acc[fichier.rubrique] = acc[fichier.rubrique] || {};
            acc[fichier.rubrique][fichier.prefixe] = acc[fichier.rubrique][fichier.prefixe] || [];
            acc[fichier.rubrique][fichier.prefixe].push(fichier);
            return acc;
        }, {});
    
        Object.keys(grouped).forEach(rubrique => {
            grouped[rubrique] = Object.keys(grouped[rubrique]).sort().reduce((sortedAcc, prefixe) => {
                sortedAcc[prefixe] = grouped[rubrique][prefixe].sort((a, b) => new Date(b.uploadDate) - new Date(a.uploadDate));
                return sortedAcc;
            }, {});
        });
    
        return grouped;
    };

    const toggleRubrique = rubrique => {
        setActiveRubriques(prev => ({ ...prev, [rubrique]: !prev[rubrique] }));
    };

    const togglePrefixe = (rubrique, prefixe) => {
        setActivePrefixes(prev => ({
            ...prev,
            [rubrique]: { ...prev[rubrique], [prefixe]: !prev[rubrique]?.[prefixe] }
        }));
    };

    const handleFileClick = useHandleFileClick(); // Utilisation du hook personnalisé

    if (!projetData || !projetData._id || !projetData.nom) {
        return <h5 className="titre-zone3">Nom du projet indisponible</h5>;
    }

    return (
        <div className="cadre-zone3-note" style={{ marginBottom: '40px', paddingBottom: '20px' }}>
            <h6 style={{
                textAlign: 'center',
                color: 'var(--texte-zone)',
                marginBottom: '15px'
            }}>DOCUMENTS {projetData.nom}</h6>
            {Object.keys(fichiers).length > 0 ? (
                Object.entries(fichiers).map(([rubrique, prefixeMap]) => (
                    <div key={rubrique} style={{
                        paddingLeft: '10px',
                        cursor: 'pointer',
                        fontWeight: '500',
                        color: 'var(--texte-zone)',
                        fontSize: 'var(--fs-normale)',
                        borderTop: '1px solid #ddd',
                        paddingTop: '10px',
                        marginTop: '10px'
                    }}>
                        <div onClick={() => toggleRubrique(rubrique)} style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: 'var(--fs-normale)',
                        }}>
                            {activeRubriques[rubrique] ? <FiChevronDown /> : <FiChevronRight />}
                            <span style={{ marginLeft: '5px' }}>{rubrique}</span>
                        </div>
                        {activeRubriques[rubrique] && Object.entries(prefixeMap).map(([prefixe, fichiers]) => (
                            <div key={prefixe} style={{ paddingLeft: '20px' }}>
                                <div onClick={() => togglePrefixe(rubrique, prefixe)} style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontSize: 'var(--fs-plus-petite)',
                                    paddingLeft: '20px'
                                }}>
                                    {activePrefixes[rubrique]?.[prefixe] ? <FiChevronDown /> : <FiChevronRight />}
                                    <span style={{ marginLeft: '5px' }}>{prefixe}</span>
                                </div>
                                {activePrefixes[rubrique]?.[prefixe] && fichiers.map((fichier, index) => (
                                    <div key={fichier._id} style={{
                                        paddingLeft: '40px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        fontSize: 'var(--fs-plus-petite)',
                                        transition: 'color 0.3s'
                                    }} >
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <input
                                                type="checkbox"
                                                checked={selectedFiles.has(fichier._id)}
                                                onChange={() => handleCheckboxChange(fichier._id)}
                                                style={{ marginRight: '6px' }}
                                                onClick={e => e.stopPropagation()}
                                            />
                                            <span onClick={() => handleFileClick(fichier._id)}>
                                                {fichier.nomTiers}_{new Date(fichier.uploadDate).toLocaleString()}
                                            </span>
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            paddingRight: '10px',
                                        }}>
                                            <BsFiles size="1.2em"
                                                data-tooltip-id={`duplicateFile-${index}`}
                                                data-tooltip-content="Duplication du fichier."
                                                style={{
                                                    cursor: "pointer",
                                                    margin: "0 5px",
                                                    alignSelf: 'center'
                                                }}
                                            />
                                            <BsEnvelope size="1.4em"
                                                data-tooltip-id={`emailFile-${index}`}
                                                data-tooltip-content="Email le fichier"
                                                style={{
                                                    cursor: "pointer",
                                                    margin: "0 5px",
                                                    alignSelf: 'center'
                                                }}
                                            />
                                            <BsArchive size="1.3em"
                                                data-tooltip-id={`archiveFile-${index}`}
                                                data-tooltip-content="Archivage du fichier"
                                                style={{
                                                    cursor: "pointer",
                                                    margin: "0 5px",
                                                    alignSelf: 'center'
                                                }}
                                            />
                                            <>
                                                <Tooltip
                                                    id={`duplicateFile-${index}`}
                                                    place="top"
                                                    effect="solid"
                                                    className="custom-tooltip"
                                                />
                                                <Tooltip
                                                    id={`archiveFile-${index}`}
                                                    place="top"
                                                    effect="solid"
                                                    className="custom-tooltip"
                                                />
                                                <Tooltip
                                                    id={`emailFile-${index}`}
                                                    place="top"
                                                    effect="solid"
                                                    className="custom-tooltip"
                                                />
                                            </>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                ))
            ) : (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <p>No documents available for this project.</p>
                </div>
            )}
        </div>
    );
};
