import React from 'react';
import { BsSend, BsEye, BsCalendar2Date, BsCheckCircle } from 'react-icons/bs';
import BoutonDownLoad from '../Actions/BoutonDownLoad'; // Assurez-vous que le chemin est correct


// iconRendering
export const iconRendering = (etape) => {
  const style = { fontSize: "1.2em", color: "var(--texte-zone)" };

  switch (etape.typeEtape) {
    case "ENVOYER":
      return <BsSend style={style} />;
    case "N/A":
      return <BsEye style={style} />;
    case "FICHIER":
      return etape.destinatairesEtape.length > 0 && <BoutonDownLoad etape={etape} />;
    case "DATE":
      return <BsCalendar2Date style={style} />;
    case "OUI/NON":
      return <BsCheckCircle style={style} />;
    default:
      return <span style={style}>{etape.typeEtape}</span>;
  }
};
