import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/Global.css";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { customAxios } from "../Utilities/utilities";

//
// Liste des Projets où ce type de tiers est impliqué
//
export function ListeProjets({ typeTiers }) {
  const { urlServeurNode } = useContext(ServerContext);
  const { tiersId, handleProjetId, handleSelectedMenu } =
    useContext(AuthContext);
  const [projets, setProjets] = useState([]);
  const [hoveredProjectId, setHoveredProjectId] = useState(null); // Définir ici

  // console.log("ListeProjets pour tiersId", tiersId);
  useEffect(() => {
    // Fonction pour charger la liste des projets en cours pour le type de tiers donné
    const loadProjets = async () => {
      try {
        const response = await customAxios.get(
          `${urlServeurNode}/api/projets/projets-enCours-pour-tiers/${tiersId}`
        );

        if (response.status !== 200) {
          throw new Error("Erreur lors de la récupération des projets.");
        }

        setProjets(response.data);
      } catch (error) {
        console.error(error);
        // Gérer l'erreur ici (peut-être afficher un message d'erreur à l'utilisateur)
      }
    };

    if (tiersId) {
      loadProjets();
    }
  }, [urlServeurNode, tiersId]);

  const handleLabelClick = (projetId) => {
    handleProjetId(projetId);
    handleSelectedMenu("PROJETS CLIENTS"); // Pour simuler un reroutage
  };

  return (
    <div className="cadre-zone4 listeProjets">
      <ul className="liste-sans-puces"
      >
        {projets.map((projet, index) => (
          <li
            key={projet._id}
        
            onClick={() => handleLabelClick(projet._id)}
            onMouseEnter={() => setHoveredProjectId(projet._id)} // Mettre à jour l'état ici
            onMouseLeave={() => setHoveredProjectId(null)} // Mettre à jour l'état ici
            style={{
              // fontWeight: hoveredProjectId === projet._id ? "bold" : "normal",
              fontWeight:
                hoveredProjectId === projet._id
                  ? "var(--font-400)"
                  : "var(--font-300)",
              paddingLeft: "8px",
              cursor: "pointer",
            }}
          >
            {projet.nom}
          </li>
        ))}
      </ul>
    </div>
  );
}
