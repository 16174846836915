import React, { useEffect, useState, useContext, useCallback } from "react";
import { BsCalendar2Date } from "react-icons/bs";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/Global.css";
import { AuthContext } from "../../contexts/AuthProvider";
import ServerContext from "../../contexts/ServeurProvider";
import { customAxios } from "../Utilities/utilities";
import { Tooltip } from "react-tooltip";
import io from "socket.io-client";
import Cookies from "js-cookie";
import { iconRendering } from "../Utilities/iconRendering"; // Ajustez le chemin d'accès selon votre structure de dossiers
import {
  getFontColor,
  getBackgroundClass,
  formatDate,
  calculerDifferenceJours,
  getTooltipTypeActionContent,
  cleanString,
} from "../Utilities/AttentesEtapes";
import { useProjet } from "../../contexts/ProjetContext";
import { ModalCheckProjet } from "../Modal/ModalCheckProjet"; // Importez le composant modal

export function ListeEtapes() {
  const [nbEtapes, setNbEtapes] = useState(0);
  const { urlServeurNode } = useContext(ServerContext);
  const {
    projetId,
    handleEtapeId,
    modificationDestinataires, // Permet de savoir qu'un <destinataire a été modifié dans LsiteDestinataires
    handleToggleDestinataires,
    handleProjetId,
  } = useContext(AuthContext);

  const { refreshProjects } = useProjet();
  const [showArchived, setShowArchived] = useState(false); // Ajouté pour contrôler l'affichage des étapes enArchives

  const [editingEcheance, setEditingEcheance] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [allEtapes, setAllEtapes] = useState([]);
  const [selectedEtapeId, setSelectedEtapeId] = useState(null);
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    body: "",
  });
  const [previousProjetId, setPreviousProjetId] = useState(projetId);

  // Fonction pour montrer le DatePicker
  const showDatePicker = (etapeId, currentEcheance) => {
    setEditingEcheance(etapeId); // Définir l'édition pour l'étape actuelle dans les deux cas
    refreshProjects();
    if (currentEcheance) {
      setSelectedDate(new Date(currentEcheance)); // Utiliser l'échéance actuelle si elle est définie
    } else {
      setSelectedDate(new Date()); // Utiliser la date actuelle si aucune échéance n'est définie
    }
  };

  // Fonction pour activer un projet
  const activateProject = async (projectId) => {
    try {
      const response = await customAxios.patch(
        `${urlServeurNode}/api/projets/activer/${projectId}`
      );
      if (response.status === 200) {
        console.log("Projet activé avec succès");
      }
    } catch (error) {
      console.error("Erreur lors de l'activation du projet:", error);
    }
  };

  // Fonction pour désactiver un projet
  const deactivateProject = async (projectId) => {
    try {
      const response = await customAxios.patch(
        `${urlServeurNode}/api/projets/desactiver/${projectId}`
      );
      if (response.status === 200) {
        console.log("Projet désactivé avec succès");
      }
    } catch (error) {
      console.error("Erreur lors de la désactivation du projet:", error);
    }
  };

  // Fonction pour réinitialiser les sélections
  const resetSelections = () => {
    setSelectedEtapeId(null);
    handleEtapeId(null);
    handleToggleDestinataires(false);
  };

  // Fonctions
  const fetchEtapes = useCallback(
    async (validProjetId) => {
      if (!validProjetId) return;
      try {
        const requestBody = {
          projetId: validProjetId,
          actif: true,
        };
        const response = await customAxios.post(
          `${urlServeurNode}/api/projets/etapesByprojetId`,
          requestBody
        );

        const etapesData = response.data || [];
        setAllEtapes(etapesData);
        setNbEtapes(etapesData.length);
      } catch (error) {
        console.error("Erreur lors du chargement des étapes:", error);
      }
    },
    [editingEcheance]
  );

  const validateProject = useCallback(
    async (currentProjetId) => {
      if (!currentProjetId) return;
      try {
        const response = await customAxios.get(
          `${urlServeurNode}/api/projets/validate/${currentProjetId}`
        );
        const { isValid, nomProjet, invalidSteps } = response.data;

        console.log("ListeEtapes validateProject ", nomProjet, isValid, invalidSteps);
        if (!isValid) {
          const formattedSteps = invalidSteps
            .map((step) => `<li>${step}</li>`)
            .join("");
          setModalInfo({
            show: true,
            title: `Projet ${nomProjet} Non Valide`,
            body: `Les étapes suivantes posent problème:<ul>${formattedSteps}</ul>`,
          });
          deactivateProject(currentProjetId); // Désactiver le projet si non valide
          return false;
        } else {
          setModalInfo({ show: false, title: "", body: "" });
          activateProject(currentProjetId); // Activer le projet si valide
          return true;
        }
      } catch (error) {
        console.error("Erreur lors de la validation du projet:", error);
        return false;
      }
    },
    [urlServeurNode]
  );

  const upDateIsValidProject = useCallback(
    async (currentProjetId) => {
      if (!currentProjetId) return;
      try {
        const response = await customAxios.get(
          `${urlServeurNode}/api/projets/validate/${currentProjetId}`
        );
        const { isValid, nomProjet, invalidSteps } = response.data;

        console.log("ListeEtapes upDateIsValidProject ", nomProjet, isValid, invalidSteps);
        if (!isValid) {
          deactivateProject(currentProjetId); // Désactiver le projet si non valide
          return false;
        } else {

          activateProject(currentProjetId); // Activer le projet si valide
          return true;
        }
      } catch (error) {
        console.error("Erreur lors de la validation du projet:", error);
        return false;
      }
    },
    [urlServeurNode]
  );

  // Vérifie et valide le projet uniquement lorsqu'un nouveau projetId est défini
  useEffect(() => {
    const checkAndValidateProject = async () => {
      const isValid = await validateProject(previousProjetId);
      console.log("ListeEtapes checkAndValidateProject", isValid);
      if (!isValid) {
        // Réinitialiser le projetId à l'ancien si le nouveau projet est invalide
        handleProjetId(previousProjetId);
      } else {
        setPreviousProjetId(projetId);
      }
    };

    if (projetId !== previousProjetId && urlServeurNode) {
      console.log(
        "ListeEtapes checkAndValidateProject projetId !== previousProjetId"
      );
      checkAndValidateProject();
    }
  }, [
    projetId,
    validateProject,
    urlServeurNode,
    previousProjetId,
    handleProjetId,
  ]);

  useEffect(() => {
    if (!urlServeurNode || !previousProjetId) return;
    fetchEtapes(previousProjetId);
    resetSelections();

    const socket = io(urlServeurNode, {
      path: "/ws",
      auth: {
        token: Cookies.get("userTokenAcces"),
      },
    });
    socket.on("updateEtape", () => fetchEtapes(previousProjetId));
    socket.on("updateProjet", () => fetchEtapes(previousProjetId));

    return () => socket.disconnect();
  }, [fetchEtapes, previousProjetId, urlServeurNode]);

  const toggleArchived = () => setShowArchived((prev) => !prev);

  const handleEtapeSelection = (event, etapeId) => {
    event.preventDefault();
    console.log("ListeEtapes handleEtapeSelection", etapeId);
    const isActive = selectedEtapeId === etapeId;
    setSelectedEtapeId(isActive ? null : etapeId);
    handleEtapeId(isActive ? null : etapeId);
    handleToggleDestinataires(!isActive);
  };

  const onDateChange = (nouvelleEcheance, etapeId) => {
    let nouvelEtat;
    const updateEcheance = async () => {
      try {
        if (nouvelleEcheance === null) {
          nouvelEtat = "enCours";
        } else {
          nouvelEtat = "enAttente";
        }

        const response = await customAxios.patch(
          `${urlServeurNode}/api/projets/projet/${projetId}/etapeId/${etapeId}`,
          {
            etat: nouvelEtat,
            echeanceEtape: nouvelleEcheance,
          }
        );

        if (response.status === 200) {
          setSelectedDate(nouvelleEcheance ? new Date(nouvelleEcheance) : null);
          setEditingEcheance(null);
          refreshProjects();
          await upDateIsValidProject(projetId); // Valider le projet après la mise à jour de la date
   
        }
      } catch (error) {
        console.error("Erreur lors de la mise à jour de l'échéance", error);
      }
    };

    updateEcheance();
    refreshProjects();
  };

  const handleCheckboxChange = (etape, index) => {
    toggleEtapeEtat(etape);
  };

  const toggleEtapeEtat = async (etapeCliquee) => {
    try {
      let etapesAMettreAJour = [];

      if (etapeCliquee.etapeEtat === "enArchive") {
        const nouvelEtat = "enCours";
        const indexClique = allEtapes.findIndex(
          (etape) => etape.etapeId === etapeCliquee.etapeId
        );
        if (indexClique === -1) return;

        etapesAMettreAJour = allEtapes
          .slice(indexClique)
          .filter((etape) => etape.etapeEtat === "enArchive")
          .map((etape) => ({
            etapeId: etape.etapeId,
            nouvelEtat: nouvelEtat,
            echeanceEtape: null,
          }));
      } else {
        etapesAMettreAJour.push({
          etapeId: etapeCliquee.etapeId,
          nouvelEtat: "enArchive",
          echeanceEtape: null,
        });
      }

      const updatedEtapes = allEtapes.map((etape) => {
        const estAMettreAJour = etapesAMettreAJour.find(
          (e) => e.etapeId === etape.etapeId
        );
        return estAMettreAJour
          ? {
              ...etape,
              etapeEtat: estAMettreAJour.nouvelEtat,
              echeanceEtape: estAMettreAJour.echeanceEtape,
            }
          : etape;
      });

      setAllEtapes(updatedEtapes);

      await customAxios.patch(
        `${urlServeurNode}/api/projets/updateEtapesBatch`,
        {
          etapesUpdates: etapesAMettreAJour,
        }
      );

      refreshProjects();
    } catch (error) {
      console.error("Erreur lors de la mise à jour des étapes:", error);
    }
  };

  const handleDestinataireClick = (event, destinataire, etape) => {
    event.stopPropagation();
    const nouvelEtat =
      destinataire.etat === "aRépondu" ? "enAttente" : "aRépondu";

    const updatedDestinataires = etape.destinatairesEtape.map((d) =>
      d.nom === destinataire.nom ? { ...d, etat: nouvelEtat } : d
    );

    updateDestinataireEtape(
      etape.projetId,
      etape.etapeId,
      updatedDestinataires
    );
  };

  const updateDestinataireEtape = async (
    projetId,
    etapeId,
    updatedDestinataires
  ) => {
    try {
      console.log("ListeEtapes updateDestinataireEtape", projetId,etapeId,updatedDestinataires );
      const response = await customAxios.patch(
        `${urlServeurNode}/api/projets/projet/${projetId}/etapeId/${etapeId}`,
        {
          destinatairesEtape: updatedDestinataires,
        }
      );
      if (response.status === 200) {
        fetchEtapes(previousProjetId);
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour des destinataires", error);
    }
  };

  const renderDestinataires = (destinataires, etape) => {
    const sortedDestinataires = (destinataires || [])
      .filter((destinataire) => destinataire && destinataire.nom)
      .sort((a, b) => (a.nom || "").localeCompare(b.nom || ""));

    return sortedDestinataires.map((destinataire, destinataireIndex) => {
      const key = `${destinataire.nom || ""}-${destinataireIndex}-`;

      return (
        <React.Fragment key={key}>
          <span
            style={{
              cursor: destinataire.etat === "aRépondu" ? "pointer" : "default",
              borderRadius: "var(--border-radius-destinataires)",
              marginRight: "2px",
              marginTop: "2px",
              marginBottom: "2px",
            }}
            className={
              destinataire.etat === "enAttente"
                ? "destinataire-enAttente"
                : destinataire.etat === "aRépondu"
                ? "destinataire-aRépondu"
                : ""
            }
            onClick={(event) => {
              if (destinataire.etat === "aRépondu") {
                handleDestinataireClick(event, destinataire, etape);
              }
            }}
            data-tooltip-id={`infoDestinataire-${cleanString(
              destinataire.nom
            )}`}
            data-tooltip-content={
              destinataire.etat === "enAttente"
                ? "Document en Attente"
                : destinataire.etat === "aRépondu"
                ? "Document Reçu"
                : ""
            }
            data-tooltip-place="top"
          >
            {`${destinataire.nom || ""}`}
          </span>

          <Tooltip
            id={`infoDestinataire-${cleanString(destinataire.nom)}`}
            place="top"
            effect="solid"
            className="custom-tooltip"
          ></Tooltip>
        </React.Fragment>
      );
    });
  };

  return (
    <>
      {projetId ? (
        <>
          {nbEtapes > 0 ? (
            <div className="cadre-zone3">
              <table>
                <tbody>
                  {allEtapes
                    .filter(
                      (etape) => showArchived || etape.etapeEtat !== "enArchive"
                    )
                    .map((etape, index) => {
                      const joursRestants = etape.echeanceEtape
                        ? calculerDifferenceJours(etape.echeanceEtape)
                        : "N/A";
                      const classeBackground =
                        getBackgroundClass(joursRestants);
                      const fontColor = getFontColor(classeBackground);
                      const key = etape.etapeId;
                      const rowClass =
                        index % 2 === 0 ? "bg-custom-requetes" : "";

                      return (
                        <tr
                          key={`${key}-${index}`}
                          className={`${rowClass} ${
                            selectedEtapeId === key
                              ? "selected-row"
                              : "annuler-selected-row"
                          }`}
                        >
                          <td
                            style={{
                              width: "1%",
                              borderRadius: "6px 0 0 6px",
                              paddingLeft: "8px",
                              cursor: "pointer",
                            }}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={etape.etapeEtat === "enArchive"}
                              onChange={() =>
                                handleCheckboxChange(etape, index)
                              }
                              data-tooltip-id={`infoArchive-${etape.etapeId}-${index}`}
                              data-tooltip-content={
                                etape.etapeEtat === "enArchive"
                                  ? "Réactiver la tâche"
                                  : "Archiver la tâche"
                              }
                              data-tooltip-place="top"
                            />
                          </td>
                          <Tooltip
                            id={`infoArchive-${etape.etapeId}--${index}`}
                            place="top"
                            effect="solid"
                            className="custom-tooltip"
                          ></Tooltip>

                          <td
                            className="selection-description-col"
                            style={{
                              cursor: "pointer",
                              width: "300px",
                              paddingLeft: "5px",
                            }}
                            data-tooltip-id={`infoDescription-${etape.etapeId}-${index}`}
                            data-tooltip-content={
                              etape.destinatairesModifiablesEtape
                                ? "Cliquer pour modifier les destinataires"
                                : "Sélection des destinataires impossible"
                            }
                            data-tooltip-place="top"
                            onClick={(event) =>
                              etape.destinatairesModifiablesEtape
                                ? handleEtapeSelection(event, etape.etapeId)
                                : undefined
                            }
                          >
                            {etape.ordreEtape} - {etape.descriptionEtape}
                            <Tooltip
                              id={`infoDescription-${etape.etapeId}-${index}`}
                              place="top"
                              effect="solid"
                              className="custom-tooltip"
                            ></Tooltip>
                          </td>

                          <td
                            className="destination-col"
                            style={{
                              width: "100%",
                              paddingLeft: "5px",
                              paddingTop: "2px",
                              paddingBottom: "2px",
                              display: "flex",
                              fontSize: "var(--fs-beaucoup-plus-petite)",
                              flexWrap: "wrap",
                              justifyContent: "left",
                              alignItems: "center",
                            }}
                          >
                            {renderDestinataires(
                              etape.destinatairesEtape,
                              etape
                            )}
                          </td>

                          <td
                            style={{
                              width: "6%",
                              textAlign: "center",
                            }}
                          >
                            <span
                              className="action-col"
                              {...(etape.echeanceEtape && {
                                "data-tooltip-id": `infoAction-${etape.etapeId}-${index}`,
                                "data-tooltip-content":
                                  getTooltipTypeActionContent(etape.typeEtape),
                                "data-tooltip-place": "top",
                              })}
                            >
                              {etape.echeanceEtape && iconRendering(etape)}
                            </span>
                            <Tooltip
                              id={`infoAction-${etape.etapeId}-${index}`}
                              place="top"
                              effect="solid"
                              className="custom-tooltip"
                            ></Tooltip>
                          </td>

                          <td
                            className={`echeance-col ${classeBackground}`}
                            style={{
                              cursor: "pointer",
                              width: "80px",
                              fontSize: "var(--fs-beaucoup-plus-petite)",
                              color: fontColor,
                              textAlign: "center",
                            }}
                            {...(etape.echeanceEtape && {
                              "data-tooltip-id": `infoEcheance-${etape.etapeId}-${index}`,
                              "data-tooltip-content":
                                etape.etapeEtat !== "enArchive"
                                  ? "Modifier l'échéance"
                                  : "Échéance non modifiable",
                              "data-tooltip-place": "top",
                            })}
                            onClick={() => {
                              if (etape.etapeEtat !== "enArchive") {
                                showDatePicker(
                                  etape.etapeId,
                                  etape.echeanceEtape
                                );
                              }
                            }}
                          >
                            {editingEcheance === etape.etapeId ? (
                              <>
                                <ReactDatePicker
                                  key={etape.etapeId}
                                  selected={selectedDate}
                                  onChange={(date) =>
                                    onDateChange(
                                      date,
                                      etape.etapeId,
                                      etape.projetId
                                    )
                                  }
                                  inline
                                />
                                <button
                                  onClick={() =>
                                    onDateChange(
                                      null,
                                      etape.etapeId,
                                      etape.projetId
                                    )
                                  }
                                >
                                  Réinitialiser la date
                                </button>
                              </>
                            ) : etape.echeanceEtape ? (
                              formatDate(etape.echeanceEtape)
                            ) : (
                              <BsCalendar2Date
                                color="var(--texte-zone)"
                                style={{ fontSize: "1.3em" }}
                              />
                            )}
                            <Tooltip
                              id={`infoEcheance-${etape.etapeId}-${index}`}
                              place="top"
                              effect="solid"
                              className="custom-tooltip"
                            ></Tooltip>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          ) : (
            <>Aucune étape à afficher.</>
          )}
          <button
            className="bouton-commun bouton-archived"
            onClick={toggleArchived}
            style={{ fontSize: "var(--fs-plus-petite)", marginTop: "10px" }}
          >
            {showArchived ? "Masquer les archives" : "Afficher les archives"}
          </button>
        </>
      ) : (
        <p>Projet ID ou type de requête non défini.</p>
      )}

      <ModalCheckProjet
        show={modalInfo.show}
        handleClose={() => setModalInfo({ show: false, title: "", body: "" })}
        title={modalInfo.title}
        body={<div dangerouslySetInnerHTML={{ __html: modalInfo.body }} />}
      />
    </>
  );
}
