import React, { useEffect, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/Global.css";
import { Tooltip } from "react-tooltip";

import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { fetchEnsembleAgences } from "../Utilities/utilities";

// Liste les Agences auquel est rattaché l'utilisateur
export function ListeAgences() {
  const [ListeAgences, setListeAgences] = useState([]);
  const [selectedTiers, setSelectedTiers] = useState({});
  const { urlServeurNode } = useContext(ServerContext);
  const {
    handleLogout,
    userId,
    agenceId,
    handleTiersId,
    handleUtilisateurId,
  } = useContext(AuthContext);

  const handleLabelClick = (agenceId) => {
    setSelectedTiers({ [agenceId]: true });
    handleUtilisateurId(""); // On reset l'utilisateurId en cours
    handleTiersId(agenceId);
  };

  useEffect(() => {
    const fetchListeAgences = async () => {
      try {
        // handleTiersId("");
        // on récupère toutes les informations de l'ensemble des Agences
        const data = await fetchEnsembleAgences(urlServeurNode, agenceId);
        const responseTiers = data.TiersAgences;
        const agencesTiers = responseTiers.filter(
          (agence) => agence.typeTiers === "AGENCE"
        );

        console.log("ListeAgences FIN", agencesTiers);
        setListeAgences(agencesTiers);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.log(`ListeAgences: erreur 401 - pas d'accès, token expiré.`);
          handleLogout();
        } else {
          console.error(error);
        }
      }
    };
    fetchListeAgences();
  }, [userId]);

  const ListeAgencesTriees = ListeAgences.slice().sort((a, b) => {
    const nomA = a.enseigne.toUpperCase(); // Convertir en majuscules pour un tri insensible à la casse
    const nomB = b.enseigne.toUpperCase();

    if (nomA < nomB) {
      return -1;
    }
    if (nomA > nomB) {
      return 1;
    }
    return 0;
  });

  return (
    <div className="fenetre-liste-tiers ">

<div 
        className="titre-zone2"
        style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '90%', }}>
          <h5
              data-tooltip-id={`TooltipListeAgences`}
              data-tooltip-content={
                "Les agences ci-dessous sont créées par l'Administrateur"
              }
          >LISTE DES AGENCES</h5>
        </div>


       <div className="cadre-zone2">
        {ListeAgencesTriees.length > 0 ? (
          <ul className="liste-sans-puces">
   
            {ListeAgencesTriees.map((agence) => (
              <li key={agence._id} style={{ listStyleType: "none" }}>
                <div className="form-check d-flex">
                  <input
                    className="form-check-input me-2"
                    type="checkbox"
                    value=""
                    id={`flexCheckDefault${agence._id}`}
                    checked={selectedTiers[agence._id] || false}
                    onChange={() => handleLabelClick(agence._id)}
                  />
                  <label
                    className={`form-check-label ${
                      selectedTiers[agence._id] ? "label-semi-gras" : ""
                    }`}
                    htmlFor={`flexCheckDefault${agence._id}`}
                    onClick={() => handleLabelClick(agence._id)}
                    onMouseEnter={() => {
                      setSelectedTiers((prevSelectedTiers) => ({
                        ...prevSelectedTiers,
                        [agence._id]: true,
                      }));
                    }}
                    onMouseLeave={() => {
                      setSelectedTiers((prevSelectedTiers) => ({
                        ...prevSelectedTiers,
                        [agence._id]: false,
                      }));
                    }}
                  >
                    {agence.enseigne}
                  </label>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>Aucune Agence déclarée.</p>
        )}
      </div>
      <Tooltip
        id="TooltipListeAgences"
        place="top"
        effect="solid"
        className="custom-tooltip"
      ></Tooltip>
    </div>
  );
}
