import React, { useContext, useState, useEffect } from "react";

import "./../css/Global.css";

import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { customAxios } from "../Utilities/utilities";
import ContentEditableField from "../Utilities/ContentEditableField";

import { useProjet } from "../../contexts/ProjetContext";

//
// utilisateur Sélectionné
//
export function AdresseByProjetId() {
  const { handleLogout, projetId } = useContext(AuthContext);
  const { urlServeurNode } = useContext(ServerContext);
  const [donneesProjet, setDonneesProjet] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [adresse, setAdresse] = useState("");
  const [cp, setCp] = useState("");
  const [ville, setVille] = useState("");
  const { projetData } = useProjet();

  const fetchAdresseByProjetId = async () => {
    try {
      if (projetId) {

        // On utilise le contexte pour récupérer les informations du projet
        const responseProjet = projetData; 
        console.log(
          "AdresseByProjetId: responseProjet.adresse:",
          responseProjet
        );

        setDonneesProjet(responseProjet);
        setIsLoading(false);
        setDonneesProjet(responseProjet);
        return responseProjet; // Ajoutez cette ligne pour retourner la réponse.
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log(`AdresseByProjetId: erreur 401 pas d'accès Token expiré`);
        // Déconnexion de l'utilisateur et redirection vers la page de login
        handleLogout();
      } else {
        console.error(error);
      }
    }
  };

  const handleSave = async (typePayload, value) => {
    if (!projetId) return;
    setIsLoading(true);
    try {
      // console.log("AdresseByProjetId: handleSave On va changer ",typePayload,value);
      if (typePayload === "adresse") {
        setAdresse(value);
      }
      if (typePayload === "cp") {
        setCp(value);
      }
      if (typePayload === "ville") {
        setVille(value);
      }
      // const payload = { adresse, cp, ville };

      const payload = {
        adresse: typePayload === "adresse" ? value : adresse,
        cp: typePayload === "cp" ? value : cp,
        ville: typePayload === "ville" ? value : ville,
      };

      // console.log("AdresseByProjetId: handleSave payload Apres ",payload);
      const retourPatch = await customAxios.patch(
        `${urlServeurNode}/api/projets/alone/${projetId}`,
        payload
      );
      console.log("AdresseByProjetId: handleSave retourPatch", retourPatch);
      setDonneesProjet({ ...donneesProjet, adresse, cp, ville }); // Mise à jour de l'état avec les nouvelles valeurs
    } catch (error) {
      // Gérer les erreurs ici.
      console.error(error);
    }
    setEditMode(false);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchAdresseByProjetId()
      .then((response) => {
        if (response) {
          const { adresse, cp, ville } = response;
          setAdresse(adresse || "");
          setCp(cp || "");
          setVille(ville || "");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        // Gérer l'erreur ici, si fetchAdresseByProjetId échoue.
        console.error(
          "Une erreur est survenue lors de la récupération des données: ",
          error
        );
      });
  }, [projetData]);

  return (
    // <div className="d-flex flex-column cadre-zone4 contenu-zone4 center-content">
        <div className="d-flex flex-column cadre-zone4 center-content">
          <div onClick={() => setEditMode(true)}>
            {adresse || "Adresse non fournie"}
          </div>
          <div onClick={() => setEditMode(true)}>
            {cp || "CP non fourni"} {ville || "Ville non fournie"}
          </div>



      {/* {editMode ? (
        // Mode d'édition avec des champs de saisie
        <>
          <ContentEditableField
            initialValue={adresse}
            placeholder="Adresse"
            onSave={(value) => handleSave("adresse", value)}
            tabIndex={1}
          />
          <ContentEditableField
            initialValue={cp}
            placeholder="Code postal"
            onSave={(value) => handleSave("cp", value)}
            tabIndex={2}
          />
          <ContentEditableField
            initialValue={ville}
            placeholder="Ville"
            onSave={(value) => handleSave("ville", value)}
            tabIndex={0}
          />
        </>
      ) : (
        // Affichage normal avec les données
        <>
          <div onClick={() => setEditMode(true)}>
            {adresse || "Adresse non fournie"}
          </div>
          <div onClick={() => setEditMode(true)}>
            {cp || "CP non fourni"} {ville || "Ville non fournie"}
          </div>
        </>
      )} */}
    </div>
  );
}
