import React, { useEffect, useState, useContext } from "react";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { customAxios } from "../Utilities/utilities";
import { Tooltip } from "react-tooltip";
import "../css/Global.css";
import io from 'socket.io-client';
import Cookies from "js-cookie";
import useHandleFileClick from '../Utilities/GetFiles';

export const ListeRéponses = () => {
  const [fichiers, setFichiers] = useState([]);
  const [utilisateurs, setUtilisateurs] = useState({});
  const [projets, setProjets] = useState({});
  const { userTiersId, searchTerm } = useContext(AuthContext);
  const { urlServeurNode } = useContext(ServerContext);
  const [sortConfig, setSortConfig] = useState({ key: "uploadDate", direction: "ascending" });

  useEffect(() => {
    if (!urlServeurNode) return;

    const fetchFichiers = async () => {
      try {
        const response = await customAxios.get(`${urlServeurNode}/api/files/tiers/${userTiersId}`);
        console.log("ListeRéponses liste des fichiers trouvés", response.data);
        setFichiers(response.data);

        const emetteurIds = [...new Set(response.data.flatMap(file => file.metadata.emetteurId.map(e => e.userId)))];
       
        const utilisateursResponse = await customAxios.post(`${urlServeurNode}/api/utilisateurs/getByIds`, { ids: emetteurIds });
        const utilisateursMap = utilisateursResponse.data.reduce((acc, utilisateur) => {
          acc[utilisateur._id] = utilisateur;
          return acc;
        }, {});
        console.log("ListeRéponses utilisateursMap", utilisateursMap);
        setUtilisateurs(utilisateursMap);

        const projetIds = [...new Set(response.data.map(file => file.metadata.projetId))];
        const projetsResponse = await customAxios.post(`${urlServeurNode}/api/projets/getByIds`, { ids: projetIds });
        const projetsMap = projetsResponse.data.reduce((acc, projet) => {
          acc[projet._id] = projet;
          return acc;
        }, {});
        setProjets(projetsMap);
      } catch (error) {
        console.error("Erreur lors de la récupération des fichiers, des utilisateurs ou des projets :", error);
      }
    };

    fetchFichiers();

    const socket = io(urlServeurNode, {
      path: '/ws',
      auth: {
        token: Cookies.get("userTokenAcces")
      }
    });

    socket.on('updateEtape', () => {
      fetchFichiers();
    });


  }, [userTiersId, urlServeurNode]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const handleFileClick = useHandleFileClick();

  const filteredFichiers = fichiers.filter(file => {
    const projet = projets[file.metadata.projetId];
    const utilisateurLocal = utilisateurs[file.metadata.emetteurId[0]?.userId];

    const matchesUploadDate = new Date(file.uploadDate).toLocaleString().toLowerCase().includes(searchTerm.toLowerCase());
    const matchesNomProjet = file.nomProjet.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesRubrique = file.rubrique.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesUtilisateur = utilisateurLocal ? `${utilisateurLocal.prenom} ${utilisateurLocal.nom}`.toLowerCase().includes(searchTerm.toLowerCase()) : false;
    const matchesAdresse = projet ? `${projet.adresse.toLowerCase()} ${projet.cp.toLowerCase()} ${projet.ville.toLowerCase()}`.includes(searchTerm.toLowerCase()) : false;

    return matchesUploadDate || matchesNomProjet || matchesRubrique || matchesUtilisateur || matchesAdresse;
  });

  const sortedFichiers = React.useMemo(() => {
    let sortedItems = [...filteredFichiers];
    if (sortConfig.key) {
      sortedItems.sort((a, b) => {
        if (sortConfig.key === "contact") {
          const aContact = utilisateurs[a.metadata.emetteurId[0]?.userId];
          const bContact = utilisateurs[b.metadata.emetteurId[0]?.userId];
          const aContactName = aContact ? `${aContact.prenom} ${aContact.nom}` : "";
          const bContactName = bContact ? `${bContact.prenom} ${bContact.nom}` : "";
          if (aContactName < bContactName) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (aContactName > bContactName) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        } else {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        }
      });
    }
    return sortedItems;
  }, [filteredFichiers, sortConfig, utilisateurs]);

  return (
    <div>
      <table style={{ width: '100%', tableLayout: 'fixed', borderCollapse: 'collapse' }}>
        <thead className="table-header-requetes">
          <tr style={{ backgroundColor: 'var(--bg-zone)' }}>
            <th style={{ width: "20%", textAlign: "center", cursor: "pointer" }} onClick={() => requestSort("uploadDate")}>
              DATE DE RÉCEPTION
            </th>
            <th style={{ width: "20%", textAlign: "center", cursor: "pointer" }} onClick={() => requestSort("nomProjet")}>
              PROJET
            </th>
            <th style={{ width: "20%", textAlign: "center", cursor: "pointer" }} onClick={() => requestSort("rubrique")}>
              TYPE
            </th>
            <th style={{ width: "20%", textAlign: "center", cursor: "pointer" }} onClick={() => requestSort("contact")}>
              CONTACT
            </th>
            <th style={{ width: "20%", textAlign: "center" }}>
              ADRESSE
            </th>
          </tr>
        </thead>
        <tbody style={{ display: "table", maxHeight: "800px", overflowY: "auto", width: "100%" }}>
          {sortedFichiers.map((file, index) => {
            const rowClass = index % 2 === 0 ? "bg-custom-requetes" : "";
            const utilisateur = utilisateurs[file.metadata.emetteurId[0]?.userId];
            const projet = projets[file.metadata.projetId];
            const adresse = projet ? `${projet.adresse}, ${projet.cp} ${projet.ville}` : "N/A";

            return (
              <tr key={file._id} className={rowClass}>
                <td style={{ width: "20%", textAlign: "center" }}>
                  {new Date(file.uploadDate).toLocaleString()}
                </td>
                <td style={{ width: "20%", textAlign: "center" }}>
                  {file.nomProjet}
                </td>
                <td
                  style={{ width: "20%", textAlign: "center", cursor: "pointer" }}
                  onClick={() => handleFileClick(file._id)}
                >
                  {file.rubrique}
                </td>
                <td style={{ width: "20%", textAlign: "center" }}>
                  {utilisateur ? `${utilisateur.prenom} ${utilisateur.nom}` : "N/A"}
                </td>
                <td style={{ width: "20%", textAlign: "center" }}>
                  {adresse}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
