import React, { useEffect, useState, useContext, useMemo, useCallback } from "react";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { fetchEnsembleAgences, customAxios } from "../Utilities/utilities";
import { Tooltip } from "react-tooltip";

// On récupère tous les projets ouvert archivé de tous les utilisateurs de l'Agence
export function ListeAllProjets(props) {
  const { cibleProjets } = props; // CLIENT ou PARTENAIRE
  const { urlServeurNode } = useContext(ServerContext);
  const [projets, setListeProjets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Ajoute etat par défaut à "ouvert"
  const [etatProjet, setEtatProjet] = useState("ouvert");

  const {
    userId,
    handleProjetId,
    agenceId,
    searchTerm
  } = useContext(AuthContext);

  const handleLabelClick = (projetId, e) => {
    e.stopPropagation(); // Empêcher la propagation du clic au niveau de la checkbox

    console.log("ListeAllProjets handleLabelClick");
    handleProjetId(projetId);
    console.log("ListeAllProjets handleLabelClick projetId", projetId);
    console.log("ListeAllProjets handleLabelClick etapeId NULL");
    // handleEtapeId(null);
  };

  const fetchProjetsAll = useCallback(async () => {
    try {
      const data = await fetchEnsembleAgences(urlServeurNode, agenceId);
      const projetsListe = [];
      const etats = ["ouvert", "archivé"];
      const projetsResponse = await Promise.allSettled(
        data.TousLesUtilisateurs.flatMap(utilisateur =>
          etats.map(etat =>
            customAxios.get(`${urlServeurNode}/api/projets/projetsEtModeles/byUser/${utilisateur.utilisateurId}/${etat}`)
          )
        )
      );

      console.log("ListeAllProjets projetsResponse", projetsResponse);

      const projetIds = new Set();
      projetsResponse.forEach(response => {
        if (response.status === "fulfilled") {
          response.value.data.forEach(projet => {
            if (!projetIds.has(projet._id)) {
              projetsListe.push(projet);
              projetIds.add(projet._id);
            }
          });
        }
      });

      setListeProjets(projetsListe);
    } catch (error) {
      console.error("ListeAllProjets: fetchProjetsAll: Erreur lors de la récupération des projets :", error);
    } finally {
      setIsLoading(false);
    }
  }, [userId, urlServeurNode, agenceId]);

  useEffect(() => {
    console.log("ListeAllProjets: useEffect DEBUT etat", etatProjet);
    fetchProjetsAll();
  }, [userId, etatProjet]);

  // useMemo pour mémoriser les projets filtrés et triés
  const filteredProjets = useMemo(() => {
    const projetsActifs = projets.filter(projet => projet.actif === true);
    const projetsFiltrésParNom = projetsActifs.filter(projet =>
      projet.nom.toLowerCase().includes(searchTerm.toLowerCase())
    );
    // Filtrer les projets sur projet.modeleCible === cibleProjets
    const projetsFiltrésParCible = projetsFiltrésParNom.filter(projet => projet.modeleCible === cibleProjets);
    // Trier les projets par nom
    projetsFiltrésParCible.sort((a, b) => a.nom.localeCompare(b.nom));
    return projetsFiltrésParCible;
  }, [projets, searchTerm, cibleProjets]); // Dépend de l'état projets, searchTerm et cibleProjets

  const handleCheckClick = async (projetId, etatActuel) => {
    try {
      const nouvelEtat = etatActuel === "enCours" ? "enArchive" : "enCours";
      console.log("ListeAllProjets: handleCheckClick etatActuel", etatActuel);
      // Mettez à jour l'état du projet sur le serveur
      const response = await customAxios.put(
        `${urlServeurNode}/api/projets/${projetId}`,
        { etat: nouvelEtat }
      );

      if (response.status === 200) {
        // Retirez le projet de la liste des projets en cours ou archivés en fonction de son état actuel
        setListeProjets((prevProjets) =>
          prevProjets.filter((projet) => projet._id !== projetId)
        );

        handleProjetId(null);
      } else {
        console.error("ListeAllProjets: La mise à jour du projet a échoué.");
      }
    } catch (error) {
      console.error("ListeAllProjets: Erreur lors de la mise à jour du projet:", error);
    }
  };

  return (
    <div className="fenetre-liste-projets">
      {etatProjet !== 'archivé' && (
        <div 
          className="titre-zone2"
          style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '90%' }}>
          <h5>ALL PROJETS</h5>
        </div>
      )}
      
      <div className="cadre-zone2">
        {filteredProjets.length > 0 ? (
          <ul className="liste-sans-puces">
            {filteredProjets.map((projet, index) => (
              <li key={projet._id}>
                <div className="form-check d-flex ms-1">
                  <input
                    data-tooltip-id={`infoCheckBox-${projet._id}`}
                    data-tooltip-content={
                      projet.etat === "enCours"
                        ? "Archiver"
                        : "Réactiver le projet"
                    }
                    data-tooltip-place="top"
                    className="form-check-input me-1"
                    type="checkbox"
                    checked={projet.etat === "enArchive"}
                    onChange={() => handleCheckClick(projet._id, projet.etat)}
                  />
                  <Tooltip
                    id={`infoCheckBox-${projet._id}`}
                    place="top"
                    effect="solid"
                    className="custom-tooltip"
                  ></Tooltip>

                  <span
                    className={`form-check-label ${
                      projet.labelHovered ? "label-semi-gras" : ""
                    }`}
                    onClick={(e) => handleLabelClick(projet._id, e)}
                    onMouseEnter={() => {
                      setListeProjets((prevProjets) =>
                        prevProjets.map((prevProjet) =>
                          prevProjet._id === projet._id
                            ? { ...prevProjet, labelHovered: true }
                            : prevProjet
                        )
                      );
                    }}
                    onMouseLeave={() => {
                      setListeProjets((prevProjets) =>
                        prevProjets.map((prevProjet) =>
                          prevProjet._id === projet._id
                            ? { ...prevProjet, labelHovered: false }
                            : prevProjet
                        )
                      );
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {projet.nom} ({projet.modeleName}) 
                  </span>
                </div>
              </li>
            ))}
          </ul>
        ) : isLoading ? (
          <div>Chargement en cours...</div>
        ) : (
          <div>Pas de projets à afficher.</div>
        )}
      </div>
    </div>
  );
}