import React, { useState } from "react";
import axios from "axios";


// ATTENTION A CREER UN NOUVEAU JETON SI ERREUR 

export function Siret() {
  const [searchType, setSearchType] = useState("siret");
  const [identifier, setIdentifier] = useState("");
  const [companyInfo, setCompanyInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [nafLibelle, setNafLibelle] = useState("");

  const fetchCompanyInfo = async (identifier, type) => {
    setLoading(true);
    setError(null);
    try {
      let siret = identifier;
      if (type === "siren") {
        const urlSiren = `https://api.insee.fr/entreprises/sirene/V3.11/siren/${identifier}`;
        const responseSiren = await axios.get(urlSiren, {
          headers: {
            Authorization: `Bearer f2c7cb43-ae2a-3c39-b35d-793697c947c7`,
          },
        });

        const periodes = responseSiren.data.uniteLegale?.periodesUniteLegale;
        if (periodes && periodes.length > 0) {
          const nic = periodes[0].nicSiegeUniteLegale;
          siret = identifier + nic;
        }
      }

      const urlSiret = `https://api.insee.fr/entreprises/sirene/V3.11/siret/${siret}`;
      const responseSiret = await axios.get(urlSiret, {
        headers: {
          Authorization: `Bearer f2c7cb43-ae2a-3c39-b35d-793697c947c7`,
        },
      });

      setCompanyInfo(responseSiret.data);
      console.log("Siret", responseSiret.data);
      const codeNaf =
        responseSiret.data.etablissement?.uniteLegale
          ?.activitePrincipaleUniteLegale;
      if (codeNaf) {
        fetchNafLibelle(codeNaf);
      }
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 301:
            setError("L'unité légale est fermée pour cause de doublon.");
            break;
          case 400:
            setError("Paramètres incorrects ou mal formatés.");
            break;
          case 401:
            setError("Jeton d'accès manquant ou invalide.");
            break;
          case 403:
            setError("Droits insuffisants pour consulter les données de cette unité.");
            break;
          case 404:
            setError("Entreprise non trouvée dans la base Sirene.");
            break;
          case 405:
            setError("Ce service n'est plus assuré.");
            break;
          case 406:
            setError("Le paramètre 'Accept' de l'en-tête HTTP contient une valeur non prévue.");
            break;
          case 429:
            setError("Quota d'interrogations de l'API dépassé.");
            break;
          case 500:
            setError("Erreur interne du serveur.");
            break;
          case 503:
            setError("Service indisponible.");
            break;
          default:
            setError("Erreur inconnue lors de la récupération des informations de l'entreprise.");
        }
      } else {
        setError("Erreur lors de la récupération des informations de l'entreprise. Veuillez vérifier le numéro et réessayer.");
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchNafLibelle = async (codeNaf) => {
    try {
      const response = await axios.get(
        `https://api.insee.fr/metadonnees/V1/codes/nafr2/sousClasse/${codeNaf}`,
        {
          headers: {
            Authorization: `Bearer f2c7cb43-ae2a-3c39-b35d-793697c947c7`,
          },
        }
      );
      setNafLibelle(response.data.intitule);
    } catch (err) {
      console.error("Error fetching NAF libelle:", err);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      (searchType === "siret" &&
        (identifier.length !== 14 || isNaN(identifier))) ||
      (searchType === "siren" && (identifier.length !== 9 || isNaN(identifier)))
    ) {
      setError(
        `Le numéro ${searchType.toUpperCase()} doit contenir ${
          searchType === "siret" ? "14" : "9"
        } chiffres.`
      );
      return;
    }
    fetchCompanyInfo(identifier, searchType);
  };

  return (
    <div>
      <h4>Recherche d'entreprise par {searchType.toUpperCase()}</h4>
      <form onSubmit={handleSubmit}>
        <label>
          Type de recherche:
          <select
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
          >
            <option value="siret">SIRET</option>
            <option value="siren">SIREN</option>
          </select>
        </label>
        <label>
          Numéro {searchType.toUpperCase()}:
          <input
            type="text"
            value={identifier}
            onChange={(e) => setIdentifier(e.target.value)}
            required
          />
        </label>
        <button type="submit">Rechercher</button>
      </form>
      {loading && <p>Chargement...</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}

      {companyInfo && (
        <div>
          <h2>Informations de l'entreprise</h2>
          <p>
            <strong>Numéro RCS:</strong> {companyInfo.etablissement?.siren}
          </p>
          <p>
            <strong>Nom:</strong>{" "}
            {companyInfo.etablissement?.uniteLegale?.denominationUniteLegale ||
              companyInfo.etablissement?.periodesEtablissement[0]
                ?.denominationUsuelleEtablissement}
          </p>
          <p>
            <strong>Enseigne:</strong>{" "}
            {
              companyInfo.etablissement?.uniteLegale
                ?.denominationUsuelle1UniteLegale
            }
          </p>
          <p>
            <strong>Activité:</strong>{" "}
            {
              companyInfo.etablissement?.uniteLegale
                ?.activitePrincipaleUniteLegale
            }{" "}
            ({nafLibelle})
          </p>
          <p>
            <strong>Adresse:</strong>{" "}
            {
              companyInfo.etablissement?.adresseEtablissement
                ?.numeroVoieEtablissement
            }{" "}
            {
              companyInfo.etablissement?.adresseEtablissement
                ?.typeVoieEtablissement
            }{" "}
            {
              companyInfo.etablissement?.adresseEtablissement
                ?.libelleVoieEtablissement
            }
            ,{" "}
            {
              companyInfo.etablissement?.adresseEtablissement
                ?.codePostalEtablissement
            }{" "}
            {
              companyInfo.etablissement?.adresseEtablissement
                ?.libelleCommuneEtablissement
            }
          </p>
          <p>
            <strong>Date de Création:</strong>{" "}
            {companyInfo.etablissement?.uniteLegale?.dateCreationUniteLegale}
          </p>
          <p>
            <strong>Tranche Effectifs:</strong>{" "}
            {
              companyInfo.etablissement?.uniteLegale
                ?.trancheEffectifsUniteLegale
            }
          </p>
          <p>
            <strong>Année Effectifs:</strong>{" "}
            {companyInfo.etablissement?.uniteLegale?.anneeEffectifsUniteLegale}
          </p>
          <p>
            <strong>Date Dernier Traitement:</strong>{" "}
            {
              companyInfo.etablissement?.uniteLegale
                ?.dateDernierTraitementUniteLegale
            }
          </p>
          <p>
            <strong>Catégorie Entreprise:</strong>{" "}
            {companyInfo.etablissement?.uniteLegale?.categorieEntreprise}
          </p>
          <p>
            <strong>Année Catégorie Entreprise:</strong>{" "}
            {companyInfo.etablissement?.uniteLegale?.anneeCategorieEntreprise}
          </p>
        </div>
      )}
    </div>
  );
}
