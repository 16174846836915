import React, { useEffect, useState, useContext } from "react";
import Table from 'react-bootstrap/Table';
import { Tooltip } from "react-tooltip";
import { customAxios } from "../Utilities/utilities";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";

// Composant pour afficher le tableau comparatif
// Permet de visualiser la ventilation Prévisionnel échéancier
export const EtapesComparatifTableau = () => {
  const { urlServeurNode } = useContext(ServerContext);
  const { modeleId, handleLogout } = useContext(AuthContext);

  const [etapesModele, setEtapesModele] = useState([]);
  const [etapesPrevi, setEtapesPrevi] = useState([]);
  const [etapesEcheancier, setEtapesEcheancier] = useState([]);

  useEffect(() => {
    const fetchModeleById = async () => {
      try {
        if (modeleId) {
          const response = await customAxios.get(
            `${urlServeurNode}/api/modeles/${modeleId}`
          );

          setEtapesModele(response.data.etapes);
          setEtapesPrevi(response.data.etapesPrevisionnel);
          setEtapesEcheancier(response.data.etapesEcheancier);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Déconnexion de l'utilisateur et redirection vers la page de login
          handleLogout();
        } else {
          console.error(error);
        }
      }
    };
    fetchModeleById();
  }, [urlServeurNode, modeleId]);

  const isEtapeInArray = (etapeId, etapesArray) => {
    return etapesArray.includes(etapeId);
  };

  const CELL_STYLE = {
    textAlign: 'center',
    color: 'var(--texte-zone)'
  };

  const updateDatabase = async (updatedEtapesPrevi, updatedEtapesEcheancier) => {
    try {
      updatedEtapesPrevi.sort((a, b) => a - b);
      updatedEtapesEcheancier.sort((a, b) => a - b);

      const response = await customAxios.patch(`${urlServeurNode}/api/modeles/${modeleId}`, {
        etapesPrevisionnel: updatedEtapesPrevi,
        etapesEcheancier: updatedEtapesEcheancier,
      });

      console.log('Base de données mise à jour avec succès', response.data);
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la base de données :", error);
    }
  };

  const handleCheckboxChange = (etapeOrdre, type) => {
    let updatedEtapesPrevi = [...etapesPrevi];
    let updatedEtapesEcheancier = [...etapesEcheancier];

    if (type === "previ") {
      if (!isEtapeInArray(etapeOrdre, updatedEtapesPrevi)) {
        updatedEtapesPrevi.push(etapeOrdre);
      } else {
        updatedEtapesPrevi = updatedEtapesPrevi.filter((ordre) => ordre !== etapeOrdre);
      }
      setEtapesPrevi(updatedEtapesPrevi);
    } else if (type === "echeancier") {
      if (!isEtapeInArray(etapeOrdre, updatedEtapesEcheancier)) {
        updatedEtapesEcheancier.push(etapeOrdre);
      } else {
        updatedEtapesEcheancier = updatedEtapesEcheancier.filter((ordre) => ordre !== etapeOrdre);
      }
      setEtapesEcheancier(updatedEtapesEcheancier);
    }

    updateDatabase(updatedEtapesPrevi, updatedEtapesEcheancier);
  }

  return (

    <div>
  {/* <h6 
    data-tooltip-id={`infoPreviEcheancier`}
    data-tooltip-content={"Etapes Prévisionnelles ou Échéancier. Application immédiate à tous les projets"}
    data-tooltip-place="top"
  >Répartition Prévisionnel Échéancier</h6> */}
  <Tooltip
    id="infoPreviEcheancier"
    place="top"
    effect="solid"
    className="custom-tooltip"
  ></Tooltip>

  {/* En-tête des colonnes */}
  <ul className="cadre-zone4">
    <li className="table-zone3-header" style={{ listStyle: 'none' }}>
      <div style={{ display: 'flex', justifyContent: 'space-around',   listStyle: 'none'}}> 
        <span>Étape</span> <span>Prévisionnel</span> <span>Échéancier</span>
      </div>
    </li>
    
    {etapesModele.map((etape, index) => (
        <li key={etape.ordre}
        style={{ listStyle: 'none'}}
        className={`liste-sans-puces table-body ${index % 2 === 0 ? 'bg-custom' : ''}`}
      >

        <div style={{ display: 'flex', justifyContent: 'space-around' , listStyle: 'none'}}>
          <span style={CELL_STYLE}>{index + 1}</span>

          {/* Prévisionnel */}
          <span style={CELL_STYLE}>
            <input
              type="checkbox"
              checked={isEtapeInArray(etape.ordre, etapesPrevi)}
              onChange={() => handleCheckboxChange(etape.ordre, "previ")}
            />
          </span>

          {/* Échéancier */}
          <span style={CELL_STYLE}>
            <input
              type="checkbox"
              checked={isEtapeInArray(etape.ordre, etapesEcheancier)}
              onChange={() => handleCheckboxChange(etape.ordre, "echeancier")}
            />
          </span>
        </div>
      </li>
    ))}
  </ul>
</div>


  );
};
