import React, { useEffect, useState, useContext } from "react";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";

import { customAxios } from "../Utilities/utilities";


// On récupère la liste des utilisateurs attaché à un tiersId
export function CountUtilisateursByTiers(props) {
  const [CountUtilisateursByTiers, setCountUtilisateursByTiers] = useState([]);
  const { urlServeurNode } = useContext(ServerContext);
  const { userId,tiersId } = useContext(AuthContext);

  // console.log("CountUtilisateursByTiers: DEBUT userId", userId);

  useEffect(() => {
    const fetchUtilisateursByTiersId = async () => {
  
        if (userId) {
          // on récupère toutes les informations de l'ensemble des Agences
          try {
            const url = `${urlServeurNode}/api/tiers/${tiersId}`;
            const reponse = await customAxios.get(url);
            console.log("CountUtilisateursByTiers: FIN ", reponse);
            setCountUtilisateursByTiers(reponse.data.utilisateurs.length);
            // console.log("CountUtilisateursByTiers: FIN ");
   
          } catch (error) {     
            console.error(error);
          } 
        }
  
    };

    fetchUtilisateursByTiersId();
  }, [userId, tiersId, urlServeurNode]);

  // console.log("CountUtilisateursByTiers: FIN ");

  return (
      <>{userId ? <span className="badge badge-info">{CountUtilisateursByTiers}</span> : null}</> // Afficher null plutôt que une chaîne vide pour le cas sans utilisateur

  );
}
