import React, { useState, useContext, useEffect } from "react";

import {
  Navbar,
  Form,
  FormControl,
  Button,
  Nav,
  Container,
  Card,

} from "react-bootstrap";

import axios from "axios";
import Cookies from "js-cookie";
import ServerContext from "../../contexts/ServeurProvider";

import { AuthContext } from "../../contexts/AuthProvider";
import { customAxios } from "../Utilities/utilities";
import { FooterAccueil } from "../Footer/FooterAccueil";

import RequestForm from "../RequestForm/RequestForm"; // Ajustez le chemin selon votre structure

import "./css/login.css";

export function Login() {
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false); // État pour contrôler l'ouverture de Navbar et le formulaire
  const [showRequestForm, setShowRequestForm] = useState(false);

  const { urlServeurNode } = useContext(ServerContext);
  const {
    handleLogin,
    setIsManager,
    setIsAdmin,
    setIsPartenaire,
    setIsAgence,
    setIsClient,
    setIsPrestataire,
    handleAgenceId,
    handleSelectedMenu,
    handleTiersId,
    handleUserTiersId,
    isHttps,
    handleIsHttps,
  } = useContext(AuthContext);

  const [showDetails, setShowDetails] = useState(false);
  const [showAvis, setShowAvis] = useState(false);

  const toggleAvis = () => {
    setShowAvis(!showAvis);
  };
  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleLoginClick = () => {
    // Ouvre la Navbar et le formulaire de connexion
    setIsOpen(true);
  };

  const handleSubmitRequest = (e) => {
    // Traiter la demande ici, puis :
    setShowRequestForm(false); // Cacher le formulaire après la soumission
  };

  const handleRequestClick = () => {
    setShowRequestForm(!showRequestForm);
  };

  useEffect(() => {
    const statutHttps = process.env.HTTPS || false;
    handleIsHttps(statutHttps);
    console.log("LOGIN POST handleIsHttps ", isHttps);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      console.log("LOGIN POST urlServeurNode", urlServeurNode, credentials);
      const response = await axios.post(
        `${urlServeurNode}/api/login`,
        credentials,
        {
          headers: {
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      );
      console.log("LOGIN POST processLoginResponse", response);
      processLoginResponse(response);
    } catch (err) {
      // const errMsg =
      //   err.response?.data?.error ||
      //   "Failed to login. Please check your credentials and try again.";

      setIsLoading(false);
    }
  };

  const processLoginResponse = async (response) => {
    const { userTokenAcces, expirationDate, userId } = response.data;
    console.log("LOGIN POST processLoginResponse DEBUT");

    if (process.env.HTTPS === "true") {
      console.log("LOGIN POST processLoginResponse DEBUT HTTPS true");
      // HTTPS est activé
    } else {
      // HTTPS est désactivé
      console.log("LOGIN POST processLoginResponse DEBUT HTTPS false");
    }
    console.log(
      "LOGIN POST processLoginResponse Expiration Date:",
      expirationDate,
      isHttps
    ); // Ajout du console.log pour vérifier la valeur de expirationDate

    Cookies.set("userTokenAcces", userTokenAcces, {
      expires: new Date(expirationDate),
      secure: isHttps,
      sameSite: "Strict",
    });



    // localStorage.setItem('token', userTokenAcces);

    customAxios.defaults.headers.Authorization = `Bearer ${userTokenAcces}`;

    Cookies.set("expirationDate", expirationDate, {
      expires: new Date(expirationDate),
      secure: isHttps,
      sameSite: "strict",
    });

    console.log(
      "LOGIN POST processLoginResponse Expiration Date APRES:",
      Cookies.get("expirationDate")
    );

    Cookies.set("userNomConnecté", response.data.nom, {
      secure: isHttps,
      sameSite: "strict",
    });

    console.log("LOGIN Cookies userNomConnecté", response.data.nom);

    Cookies.set("userPrénomConnecté", response.data.prenom, {
      secure: isHttps,
      sameSite: "strict",
    }); // On stocke le prénom

    console.log("LOGIN Cookies userPrénomConnecté", response.data.prenom);

    await handleEntiteTiers(userId);
    const params = new URLSearchParams({ actif: true });
    const utilisateursResponse = await customAxios.get(
      `${urlServeurNode}/api/utilisateurs/${userId}?${params}`
    );
    // const utilisateursResponse = await customAxios.post(`${urlServeurNode}/api/utilisateurs/getByIds`, { ids: userId });

    console.log("Login utilisateursResponse", utilisateursResponse);
    handleRolesAndPermissions(utilisateursResponse.data);
    handleLogin(userId, utilisateursResponse.data.role);
    setIsLoading(false);
  };

  const handleEntiteTiers = async (userId) => {
    // A quelle Agence appartient ce userId
    const entitéTiers = await customAxios.get(
      `${urlServeurNode}/api/login/agence/${userId}`
    );
    console.log("Login handleEntiteTiers: ", entitéTiers);

    // On considère que AgenceId c'est le tiersId de l'agence sur lequel on trouveras les acces à l'extranet et la liste des modèles
    handleAgenceId(entitéTiers.data.tiersId);

    // On récupère le tiersId de l'utilisateur connecté
    handleTiersId(entitéTiers.data.tiersId);
    handleUserTiersId(entitéTiers.data.tiersId);  // Entité auquelle le user appartient

   
    const typeTiers = entitéTiers.data.typeTiers;
    const userNomTiers = entitéTiers.data.nomTiers;

 
    console.log("Login userNomTiers: ", userNomTiers);
    console.log("Login Type Tiers: ", typeTiers);
    console.log("Login AgenceId: ", entitéTiers.data.tiersId);

    console.log("Login tiersId: ", entitéTiers.data.tiersId);

    Cookies.set("userTypeTiers", typeTiers, {
      secure: isHttps,
      sameSite: "strict",
    });

    Cookies.set("userNomTiers", userNomTiers, {
      secure: isHttps,
      sameSite: "strict",
    });

    if (typeTiers === "AGENCE") {
      // On sait si l'utilisateur appartient à une Agence
      Cookies.set("isAttachedToAllAgences", true, {
        secure: isHttps,
        sameSite: "strict",
      });
    } else {
      Cookies.set("isAttachedToAllAgences", false, {
        secure: isHttps,
        sameSite: "strict",
      });
    }

    setIsPartenaire(typeTiers === "PARTENAIRE");
    setIsAgence(typeTiers === "AGENCE");
    setIsClient(typeTiers === "CLIENT");
    setIsPrestataire(typeTiers === "PRESTATAIRE");
    definirMenuParDefautALaConnexion(typeTiers);
  };

  const handleRolesAndPermissions = (data) => {
    setIsManager(data.role === "Manager");
    setIsAdmin(data.role === "Admin");
  };

  function definirMenuParDefautALaConnexion(typeTiers) {
    switch (typeTiers) {
      case "PARTENAIRE":
        console.log("Connexion handleSelectedMenu(ATTENTES)");
        handleSelectedMenu("ATTENTES");
        break;

      case "PRESTATAIRE":
      case "AGENCE":
        console.log("Connexion handleSelectedMenu(REQUETES)");
        handleSelectedMenu("REQUÊTES");
        break;

      case "ADMINISTRATEUR":
        console.log("Connexion handleSelectedMenu(A DEFINIR)");
        handleSelectedMenu("ADMIN AGENCES");
        break;

      default:
        console.log(
          "Aucun menu par défaut défini pour le typeTiers:",
          typeTiers
        );
        break; // Optionnellement gérer le cas par défaut, peut-être en définissant un menu vraiment par défaut ou en ne faisant rien.
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        color: "var(--texte-fond)",
      }}
    >
      <Navbar
        className="d-flex space-between"
        expand="lg"
        style={{ margin: "20px" }}
      >
        <Navbar.Brand
          href="#"
          className="d-flex space-around align-items-center"
        >
          {/* <span style={{ fontSize: "40px", paddingLeft: "2px", fontWeight: "600",   color: "var(--bg-gris-tres-fonce)" }}>
            İAkka
          </span> */}
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={toggleNavbar}
        />
        <Navbar.Collapse
          in={isOpen}
          id="basic-navbar-nav"
          className="justify-content-between"
        >
          <Nav className="mr-auto"></Nav>
          <Form onSubmit={handleSubmit} className="d-flex align-items-center">
            <FormControl
              className="password-input"
              style={{
                fontSize: "14px",
                padding: "5px",
                borderRadius: "8px",
                marginRight: "10px",
                border: "0px",
                backgroundColor: "var(--bg-gris)",
                color: "var(--texte-zone)",
              }}
              type="text"
              placeholder="Login"
              value={credentials.username}
              onChange={handleChange}
              name="username"
              autoComplete="username"
            />
            <FormControl
              className="password-input"
              style={{
                fontSize: "14px",
                padding: "5px",
                borderRadius: "8px",
                marginRight: "20px",
                backgroundColor: "var(--bg-gris)",
                border: "0px",
                color: "var(--texte-zone)",
              }}
              type="password"
              placeholder="Mot de passe"
              value={credentials.password}
              onChange={handleChange}
              name="password"
              autoComplete="current-password"
            />
            <Button
              variant="outline-success"
              type="submit"
              disabled={isLoading}
              style={{
                fontSize: "14px",
                borderRadius: "8px",
                padding: "6px 20px",
                backgroundColor: "var(--bg-gris-tres-fonce)",
              }}
            >
              {isLoading ? "Loading..." : "Go"}
            </Button>
          </Form>
        </Navbar.Collapse>
      </Navbar>

      <h1
        className="text-center display-4"
        style={{
          fontWeight: "600",
          margin: "20px"
        }}
      >
        Bienvenue sur İAkka
      </h1>

      <Container
        className="text-center"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          borderRadius: "18px",
          marginBottom: "20px",
        }}
      >
        <p
          style={{
            fontSize: "20px",
            fontWeight: "400",
            padding: "10px 20px",
            marginTop: "20px",
            borderRadius: "18px",
          }}
        >
          Découvrez iAkka, la solution ultime pour simplifier et optimiser la gestion de vos processus métier. <br />
          Avec iAkka, collaborez efficacement, gagnez du temps, et améliorez la transparence de vos projets. <br />
          Rejoignez-nous et révolutionnez la gestion de vos projets dès aujourd'hui !
        </p>
        <Button
          variant="primary"
          size="lg"
          className="custom-button-accueil show-border-shadow"
          style={{
            fontSize: "22px",
            padding: "10px 45px",
            borderRadius: "18px",
            backgroundColor: "var(--bg-gris-fonce)",
          }}
          onClick={toggleDetails}
        >
          İAkka en savoir plus
        </Button>

        {showDetails && (
          <Card className="card-style-acceuil">
            <Card.Body
              style={{
                borderRadius: "18px",
                backgroundColor: "var(--bg-gris-tres-fonce)",
                color: "var(--texte-zone)",
              }}
            >
      <Card.Text>
  <div
    style={{
      borderRadius: "18px",
      backgroundColor: "var(--bg-gris-tres-fonce)",
      color: "var(--texte-zone)",
      width: "50%",
      margin: "0 auto",
      padding: "20px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      fontSize: "16px",
      lineHeight: "1.5"
    }}
  >
    <div
      style={{
        margin: "0",
        padding: "0px",
      }}
    >
      <h4 style={{
        border: "1px solid lightgray",
        padding: "10px",
        borderRadius: "8px",
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        fontWeight: "bold",
        textAlign: "center",
        color: "var(--texte-principal)",
        marginBottom: "20px"
      }}>
        Fonctionnalités principales :
      </h4>
      <p>Gestion des étapes avec description et délais.</p>
      <p>Notifications automatiques pour les parties prenantes.</p>
      <p>Suivi en temps réel de l’avancement des projets.</p>
      <p>Interface utilisateur intuitive et conviviale.</p>
      <p>Récupération, classement et partage de tous types de documents.</p>

      <h4 style={{
        border: "1px solid lightgray",
        padding: "10px",
        borderRadius: "8px",
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        fontWeight: "bold",
        textAlign: "center",
        color: "var(--texte-principal)",
        marginTop: "20px",
        marginBottom: "20px"
      }}>
        Avantages pour les utilisateurs :
      </h4>
      <p>Flexibilité, efficacité, transparence, collaboration améliorée.</p>

      <h4 style={{
        border: "1px solid lightgray",
        padding: "10px",
        borderRadius: "8px",
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        fontWeight: "bold",
        textAlign: "center",
        color: "var(--texte-principal)",
        marginTop: "20px",
        marginBottom: "20px"
      }}>
        Cas d'utilisation typiques :
      </h4>
      <h5 style={{
        fontWeight: "bold",
        marginTop: "20px",
        marginBottom: "10px",
        color: "var(--texte-secondaire)"
      }}>Gestion de projet en construction :</h5>
      <p>Coordination entre entrepreneurs, architectes, ingénieurs, fournisseurs de matériaux et clients pour assurer le respect des délais et la qualité des travaux.</p>
      <p>Partage de plans, contrats et rapports de progrès.</p>

      <h5 style={{
        fontWeight: "bold",
        marginTop: "20px",
        marginBottom: "10px",
        color: "var(--texte-secondaire)"
      }}>Architecture :</h5>
      <p>Communication entre les architectes, les clients, les entreprises de construction et les municipalités pour l'obtention de permis et l'approbation des plans.</p>
      <p>Partage de dessins techniques et documents réglementaires.</p>

      <h5 style={{
        fontWeight: "bold",
        marginTop: "20px",
        marginBottom: "10px",
        color: "var(--texte-secondaire)"
      }}>Consulting :</h5>
      <p>Gestion des relations avec les clients et les fournisseurs de services, garantissant que les livrables sont fournis à temps et selon les spécifications.</p>
      <p>Partage de rapports, analyses et propositions.</p>

      <h5 style={{
        fontWeight: "bold",
        marginTop: "20px",
        marginBottom: "10px",
        color: "var(--texte-secondaire)"
      }}>Agence de marketing :</h5>
      <p>Gestion des campagnes publicitaires en coordonnant les designers, les rédacteurs, les clients et les plateformes de publication.</p>
      <p>Partage de briefs créatifs, conceptions et résultats de campagnes.</p>

      <h5 style={{
        fontWeight: "bold",
        marginTop: "20px",
        marginBottom: "10px",
        color: "var(--texte-secondaire)"
      }}>Bureau d'études :</h5>
      <p>Coordination entre les ingénieurs, les clients, les autorités locales et les fournisseurs de données techniques.</p>
      <p>Partage d'études de faisabilité, plans et rapports techniques.</p>

      <h5 style={{
        fontWeight: "bold",
        marginTop: "20px",
        marginBottom: "10px",
        color: "var(--texte-secondaire)"
      }}>Immobilier :</h5>
      <p>Aide les agents immobiliers à coordonner les acheteurs, les vendeurs, les inspecteurs et les notaires pour assurer des transactions fluides.</p>
      <p>Partage de contrats, inspections et évaluations.</p>

      <h5 style={{
        fontWeight: "bold",
        marginTop: "20px",
        marginBottom: "10px",
        color: "var(--texte-secondaire)"
      }}>Événementiel :</h5>
      <p>Gestion des prestataires de services, des lieux, des clients et des participants pour garantir le succès de l'événement.</p>
      <p>Partage de programmes, listes de participants et contrats.</p>

      <h5 style={{
        fontWeight: "bold",
        marginTop: "20px",
        marginBottom: "10px",
        color: "var(--texte-secondaire)"
      }}>Agences de recrutement :</h5>
      <p>Gestion du processus de recrutement en coordonnant les candidats, les clients, les intervieweurs et les fournisseurs de tests d'évaluation.</p>
      <p>Partage de CV, évaluations et contrats.</p>
    </div>
  </div>
</Card.Text>




            </Card.Body>
          </Card>
        )}
      </Container>
      <hr
        className="my-4"
        style={{
          width: "40%",
          height: "3px", // Changez cette valeur pour ajuster l'épaisseur
          margin: "0 auto", // Centre l'élément horizontalement en ajustant la marge
          backgroundColor: "var(--texte-fond)", // Changez la couleur si nécessaire
          border: "none" // Supprime la bordure par défaut pour éviter les conflits avec la couleur de fond
        }}
      />


      <p
        className="text-center"
        style={{
          fontSize: "20px",
          fontWeight: "400",
          padding: "10px 20px",
       
        }}
      >
        Pour commencer :
      </p>

      <Container
        className="text-center"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "18px",
          marginBottom: "20px",
        }}
      >
        <div className="d-flex flex-column flex-sm-row align-items-center ">
          <Button
            variant="primary"
            size="lg"
            // className="custom-button-accueil show-border-shadow"
            style={{
              fontSize: "20px",
              padding: "10px 30px",
              borderRadius: "18px",
               margin: "5px 20px",           
              backgroundColor: "var(   --bg-gris-tres-fonce)",
            }}
            onClick={handleLoginClick}
          >
            İAkka se connecter
          </Button>

          <Button
            variant="primary"
            size="lg"
            // className="custom-button-accueil show-border-shadow"
            style={{
              fontSize: "20px",
              padding: "10px 30px",
              borderRadius: "18px",
               margin: "5px 20px",    
              backgroundColor: "var(   --bg-gris-tres-fonce)",
            }}
          >
            İAkka s'inscrire
          </Button>
          <Button
            variant="primary"
            size="lg"
            // className="custom-button-accueil show-border-shadow"
            style={{
              fontSize: "20px",
              padding: "10px 30px",
              borderRadius: "18px",
                margin: "5px 20px",    
              backgroundColor: "var(   --bg-gris-tres-fonce)",
            }}
          >
            İAkka essayer
          </Button>
          <Button
            variant="secondary"
            size="lg"
            // className="custom-button-accueil show-border-shadow"
            style={{
              fontSize: "20px",
              padding: "10px 30px",
              borderRadius: "18px",
                margin: "5px 20px",    
              backgroundColor: "var(   --bg-gris-tres-fonce)",
            }}
            onClick={handleRequestClick}
          >
            İAkka demander
          </Button>

          {showRequestForm && (
            <div style={{ margin: "30px" }}>
              <RequestForm onSubmit={handleSubmitRequest} />
            </div>
          )}
        </div>
      </Container>
      <hr
        className="my-4"
        style={{
          width: "40%",
          height: "3px", // Changez cette valeur pour ajuster l'épaisseur
          margin: "0 auto", // Centre l'élément horizontalement en ajustant la marge
          backgroundColor: "var(--texte-fond)", // Changez la couleur si nécessaire
          border: "none" // Supprime la bordure par défaut pour éviter les conflits avec la couleur de fond
        }}
      />

      <Container
        className="text-center"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          borderRadius: "18px",
          marginBottom: "30px",
        }}
      >
        {/* <div className="d-flex flex-column flex-sm-row align-items-center"> */}
        <p
          className="text-center"
          style={{
            fontSize: "20px",
            fontWeight: "400",
            padding: "10px 20px",
            // margin: "0",
          }}
        >
          Sinon :
        </p>

        <Button
          variant="primary"
          size="lg"
          // className="custom-button-accueil show-border-shadow"
          style={{
            fontSize: "20px",
            padding: "10px 30px",
            borderRadius: "18px",
            marginRight: "20px",
            backgroundColor: "var(   --bg-gris-tres-fonce)",
          }}
          onClick={toggleAvis}
        >
          İAkka lire
        </Button>

        {showAvis && (
          <span
            style={{
              display: "flex", // Utilise flexbox pour le layout interne
              flexDirection: "column", // Aligne les enfants en colonne
              alignItems: "center", // Centre les enfants horizontalement
              marginBottom: "30px", // Garde votre marge initiale
            }}
          >
            <Card className="card-style-acceuil">
              <Card.Body
                style={{
                  // margin: "10px",
                  borderRadius: "18px",
                  backgroundColor: "var(--bg-gris-tres-fonce)",
                  color: "var(--texte-zone)",
                }}
              >
                <Card.Text>
                  "Maintenant, grâce à İAkka, chaque membre de l'équipe a son
                  propre assistant personnel digital, rendant tout
                  incroyablement organisé et accessible."
                </Card.Text>
                <footer className="blockquote-footer">
                  Thomas, <cite title="Source Title">partenaire étonné</cite>
                </footer>
              </Card.Body>
            </Card>
            <Card className="card-style-acceuil">
              <Card.Body
                style={{
                  // margin: "10px",
                  borderRadius: "18px",
                  backgroundColor: "var(--bg-gris-tres-fonce)",
                  color: "var(--texte-zone)",
                }}
              >
                <Card.Text>
                  "İAkka a transformé la façon dont nous gérons nos projets..."
                </Card.Text>
                <footer className="blockquote-footer">
                  Marc, <cite title="Source Title">client satisfait</cite>
                </footer>
              </Card.Body>
            </Card>
            <Card className="card-style-acceuil">
              <Card.Body
                style={{
                  // margin: "10px",
                  borderRadius: "18px",
                  backgroundColor: "var(--bg-gris-tres-fonce)",
                  color: "var(--texte-zone)",
                }}
              >
                <Card.Text>
                  "L'efficacité qu'apporte İAkka à notre travail est comparable
                  à un orchestre symphonique bien accordé."
                </Card.Text>
                <footer className="blockquote-footer">
                  Anissa,{" "}
                  <cite title="Source Title">maître d'oeuvre rigoureuse</cite>
                </footer>
              </Card.Body>
            </Card>
          </span>
        )}

        {/* 

        {showAvis && (
          <span
          style={{
              marginBottom: "30px",
          }}
          >
            <Card className="card-style-acceuil">
              <Card.Body>
                <Card.Text>
                  "Maintenant, grâce à İAkka, chaque membre de l'équipe a son
                  propre assistant personnel digital, rendant tout
                  incroyablement organisé et accessible."
                </Card.Text>
                <footer className="blockquote-footer">
                  Thomas, <cite title="Source Title">partenaire étonné</cite>
                </footer>
              </Card.Body>
            </Card>
            <Card className="card-style-acceuil">
              <Card.Body>
                <Card.Text>
                  "İAkka a transformé la façon dont nous gérons nos projets..."
                </Card.Text>
                <footer className="blockquote-footer">
                  Marc, <cite title="Source Title">client satisfait</cite>
                </footer>
              </Card.Body>
            </Card>

            <Card className="card-style-acceuil">
              <Card.Body>
                <Card.Text>
                  "L'efficacité qu'apporte İAkka à notre travail est comparable
                  à un orchestre symphonique bien accordé."
                </Card.Text>
                <footer className="blockquote-footer">
                  Anissa,{" "}
                  <cite title="Source Title">maître d'oeuvre rigoureuse</cite>
                </footer>
              </Card.Body>
            </Card>
          </span>
        )} */}
        {/* </div> */}
      </Container>

      <FooterAccueil />
    </div>
  );
}
