import React, { useEffect, useState, useContext } from "react";
import Cookies from "js-cookie";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { fetchEnsembleAgences } from "../Utilities/utilities";

export function UtilisateurSelecteur() {
  const { handleLogin, agenceId, tiersId , handleSelectedMenu} = useContext(AuthContext);
  const userNom = Cookies.get("userNomConnecté");
  const userPrenom = Cookies.get("userPrénomConnecté");
  const prenomNom = `${userPrenom} ${userNom}`;

  const [utilisateurs, setUtilisateurs] = useState([]);
  const [utilisateurSelectionne, setUtilisateurSelectionne] = useState(prenomNom);
  const { urlServeurNode } = useContext(ServerContext);

  useEffect(() => {
    const fetchUtilisateurs = async () => {
      if (!agenceId) {
        console.log("agenceId is undefined or null, skipping fetch");
        return; // Skip fetch if agenceId is not available
      }
      try {
        console.log("UtilisateurSelecteur agenceId", agenceId);
        const data = await fetchEnsembleAgences(urlServeurNode, agenceId);
        
        const utilisateursData = data.TousLesUtilisateurs;
        console.log("UtilisateurSelecteur utilisateursData", utilisateursData);
        setUtilisateurs(utilisateursData);
      } catch (err) {
        console.error("Failed to fetch data:", err);
      }
    };
  
    fetchUtilisateurs();
  }, [urlServeurNode, agenceId]); // Ensure useEffect runs when urlServeurNode or agenceId changes
  

  const handleChange = async (event) => {
    setUtilisateurSelectionne(event.target.value);
    const selectedUser = utilisateurs.find(utilisateur => utilisateur.nomPrenom === event.target.value);
    if (selectedUser) {
      handleLogin(selectedUser.utilisateurId, selectedUser.role, agenceId, tiersId); 
      handleSelectedMenu("REQUÊTES"); // Pour basculer sur les Requetes
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "8vh" }}>
      <label htmlFor="utilisateurSelect"
           style={{ 
            color: "var(--texte-fond)",
            fontSize: "0.8em", 
            paddingRight:'6px' }}
      >Choisir un utilisateur :</label>
      <select id="utilisateurSelect" 
      name="utilisateurSelect" 
      value={utilisateurSelectionne} 
      style={{ fontSize: "0.8em", paddingLeft:'8px' }}
      onChange={handleChange}>
        {utilisateurs.map((utilisateur, index) => (
          <option key={index} value={utilisateur.nomPrenom}>
            {utilisateur.nomPrenom}
          </option>
        ))}
      </select>
    </div>
  );
}
