import React, { useEffect, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Table from "react-bootstrap/Table";

import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { customAxios } from "../Utilities/utilities";

// On affiche les règles du modèle
//
export function ReglesModeles() {
  const [etapesModele, setEtapesModele] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { urlServeurNode } = useContext(ServerContext);

  const { modeleId, modeleOrder } = useContext(AuthContext);

  useEffect(() => {
    const fetchModele = async () => {
      setLoading(true);
      try {
        const response = await customAxios.get(
          `${urlServeurNode}/api/modeles/${modeleId}`
        );
        if (response.status !== 200) {
          throw new Error(`Erreur: ${response.status}`);
        }
        console.log("ReglesModeles les étapes", response.data.etapes);
        setEtapesModele(response.data.etapes);
        console.log("ReglesModeles etapesModele", etapesModele);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (modeleId) {
      fetchModele();
    }
  }, [modeleId, urlServeurNode, modeleOrder]);

  if (loading) return <div>Chargement...</div>;
  if (error) return <div>Erreur: {error}</div>;
  if (!etapesModele) return <div>Pas de données disponibles.</div>;

  return (
    <div className="cadre-zone4">
      <h6>Règles des étapes</h6>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Etape</th>
            <th>Type</th>
            <th>Action</th>
            <th>CC Destinataire</th>
            <th>Etapes Suivantes</th>
            <th>Échéance à J+</th>
          </tr>
        </thead>
        <tbody>
          {etapesModele.map((etape, indexEtape) =>
            etape.actionRegles.map((regle, indexRegle) => (
              <tr key={`${indexEtape}-${indexRegle}`}>
                <td>{indexRegle === 0 ? etape.ordre : ""}</td>
                <td>{regle.typeRegle}</td>
                <td>{regle.descriptionRegle}</td>
                <td>{regle.copieDestinataireRegle ? "Oui" : "Non"}</td>
                <td>
                  {Array.isArray(regle.etapeSuivantesRegle)
                    ? regle.etapeSuivantesRegle.join(", ")
                    : "N/A"}
                </td>
                <td>{regle.nouvelleEcheanceRegle}</td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </div>
  );
}
