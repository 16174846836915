import React, { useEffect, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/ListeTiers.css";
import "./../css/Global.css";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { customAxios } from "../Utilities/utilities";
import { Tooltip } from "react-tooltip";

// On récupère les tiers d'un tiers Agence en fonction de typeTiers
// Pour La zone 4 Depuis All Agence

export function ListeAllTiers({ typeTiers }) {
  const [listeTiers, setListeTiers] = useState([]);
  const { urlServeurNode } = useContext(ServerContext);
  const [selectedTiers, setSelectedTiers] = useState({});
  const { userId, handleTiersId, tiersId , agenceId} =
    useContext(AuthContext);

  console.log(
    `ListeAllTiers: AXIOS DEBUT ${urlServeurNode}/api/tiers/byTiersIdAndTypeTiers/${agenceId}/${typeTiers}`
  );

  const handleLabelClick = (tiersId) => {
    console.log(`ListeAllTiers Tiers: TiersID cliqué : ${tiersId}`);
    handleTiersId(tiersId);
    // handleUtilisateurId(""); // On reset l'utilisateurId en cours
  };

  useEffect(() => {
    const fetchListeTiers = async () => {
      try {
        if (typeTiers && userId && agenceId) {
          // handleTiersId(""); // On reset le TiersId

          console.log(`ListeAllTiers:  agenceId=${agenceId}`);
          //   On récupère tous les typeTiers de ce agenceId
          const responsedutiers = await customAxios.get(
            `${urlServeurNode}/api/tiers/byTiersIdAndTypeTiers/${agenceId}/${typeTiers}`
          );

          console.log(
            "ListeAllTiers responsedutiers.data.tiersAttaches",
            responsedutiers.data
          );

          if (Array.isArray(responsedutiers.data.tiersAttaches)) {
            console.log("ListeAllTiers Array.isArray");
    
            // Trier le tableau par le nom de chaque tiersAttaché
            const listeTiersTries = responsedutiers.data.tiersAttaches.sort((a, b) => {
              const nomA = a.nom.toUpperCase(); // ignore la casse
              const nomB = b.nom.toUpperCase(); // ignore la casse
              if (nomA < nomB) {
                return -1;
              }
              if (nomA > nomB) {
                return 1;
              }
              // noms égaux
              return 0;
            });
    
            setListeTiers(listeTiersTries);
          }

          const byTiersIdAndAllTiers = await customAxios.get(
            `${urlServeurNode}/api/tiers/byTiersIdAndAllTiers/${tiersId}`
          );
          console.log(
            "ListeAllTiers CHECK TOUS LES TIERS",
            typeTiers,
            byTiersIdAndAllTiers.data
          );
          console.log("ListeAllTiers ", responsedutiers.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchListeTiers();
  }, [urlServeurNode, userId, typeTiers, agenceId]);


  const nbRecords = listeTiers.length;
  console.log("ListeAllTiers listeTiers", listeTiers);
  console.log("ListeAllTiers nbRecords", nbRecords);

  return (
    <div className="fenetre-liste-tiers cadre-zone4">
      
      {agenceId ? (
        <div>
          {nbRecords > 0 ? (
            <ul className="liste-sans-puces">
              {listeTiers.map((monTiers, index) => (
                <li
                  key={monTiers._id}
                  style={{ listStyleType: "none" }}
                >
                  <div className="form-check d-flex">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                      id={`flexCheckDefault${monTiers._id}`}
                      checked={selectedTiers[monTiers._id] || false}
                      onChange={() => handleLabelClick(monTiers._id)}
                    />
                    <label
                      className={`form-check-label ${
                        monTiers.labelHovered ? "label-semi-gras" : ""
                      }`}
                      htmlFor="flexCheckDefault"
                      onClick={() => handleLabelClick(monTiers._id)}
                      onMouseEnter={() => {
                        setListeTiers((prevTiers) =>
                          prevTiers.map((prevTiers) =>
                            prevTiers._id === monTiers._id
                              ? { ...prevTiers, labelHovered: true }
                              : prevTiers
                          )
                        );
                      }}
                      onMouseLeave={() => {
                        setListeTiers((prevTiers) =>
                          prevTiers.map((prevTiers) =>
                            prevTiers._id === monTiers._id
                              ? { ...prevTiers, labelHovered: false }
                              : prevTiers
                          )
                        );
                      }}
                      data-tooltip-id={`TooltipTiers-${monTiers._id}`}
                      data-tooltip-content={"Cliquez pour avoir les détails"}
                    >
                      {monTiers.nom}
                    </label>
                    <Tooltip
                      id={`TooltipTiers-${monTiers._id}`}
                      place="top"
                      effect="solid"
                      className="custom-tooltip"
                    ></Tooltip>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p></p>
          )}
        </div>
      ) : (
        <p>Pas Connecté</p>
      )}
    </div>
  );
}
