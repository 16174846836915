import React from 'react';
import ReactModal from 'react-modal';

const Modal = ({ isOpen, onRequestClose, children }) => {
  // console.log("Modal DEBUT"); // Correctly placed before the return of JSX

  return (
    <ReactModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={{
            content: {
              zindex: 8,
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              padding: '30px',
              transform: 'translate(-50%, -50%)',
              borderRadius: '22px', // Corrected property name and added comma
              backgroundColor: 'rgb(122,123,116)',
              color: 'rgb(225,230,230)',
              boxshadow:  '5px 5px 10px rgba(0, 0, 0, 0.5)',
              border:  '1px solid rgb(229, 230, 231)',
              outline: '1px solid rgba(100, 100, 100, 0.5)'
            }
        }}
    >
        {children}
    </ReactModal>
  );
};

export default Modal;
