

import React, { useEffect, useState, useContext, useCallback } from "react";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { customAxios } from "../Utilities/utilities";
import useGetModeles from "../Utilities/GetModeles";
import {
  getFontColor,
  getBackgroundClass,
  calculerDifferenceJours,
} from "../Utilities/AttentesEtapes";
import io from 'socket.io-client'; 
import Cookies from "js-cookie";

export const CountEtapesAttentes = ({ AllAgences }) => {
  const { fetchModeles }  = useGetModeles(); 
  
  const [etapesEnAttente, setEtapesEnAttente] = useState([]);
  const { userId, agenceId } = useContext(AuthContext);
  const { urlServeurNode } = useContext(ServerContext);
  const [attentesCounter, setAttentesCounter] = useState({
    success: 0,
    warning: 0,
    danger: 0,
  });



  const fetchEtapesEnAttente = useCallback(async () => {
    try {
      if (!userId) return;
  
      let response, attentes = [];
  
      if (AllAgences) {
        let listeModeleIds = await fetchModeles(agenceId);
        if (!listeModeleIds) return;
  
        console.log("CountEtapesAttentes fetchEtapesEnAttente listeModeleIds", listeModeleIds);
        for (let modeleIdLocal of listeModeleIds) {
          // const payload = { modeleIds: modeleIdLocal.modeleId };
          const payload = { modeleIds: modeleIdLocal._id };
          // console.log("CountEtapesAttentes fetchEtapesEnAttente payload", payload);
  
          try {
            response = await customAxios.post(`${urlServeurNode}/api/projets/attentesByModeleIds`, payload);
            // console.log("CountEtapesAttentes fetchEtapesEnAttente Réponse de la requête POST:", response.data);

            attentes = [...attentes, ...response.data];
          } catch (postError) {
            console.error("CountEtapesAttentes fetchEtapesEnAttente Erreur lors de la requête POST:", postError);
          }
        }
      } else {
        try {

          const requestBody = {
            userId: userId,
          };
          const response = await customAxios.post(
            `${urlServeurNode}/api/projets/attentesByUserId`,
            requestBody
          );
          attentes = response.data;
        } catch (getError) {
          console.error("CountEtapesAttentes fetchEtapesEnAttente Erreur lors de la requête POST:", getError);
        }
      }
  
      setEtapesEnAttente(attentes || []);
    } catch (error) {
      console.error("CountEtapesAttentes fetchEtapesEnAttente Erreur lors de la récupération des étapes en attente", error);
    }
  }, [userId, AllAgences, agenceId, urlServeurNode]);
  
  
  useEffect(() => {

    if (!urlServeurNode) return;
    fetchEtapesEnAttente();

    console.log("CountEtapesAttentes const socket = io(urlServeurNode)", urlServeurNode,    Cookies.get("userTokenAcces"));

    // authorization
    const socket = io(urlServeurNode, {
      path: '/ws',
      auth: {
        token: Cookies.get("userTokenAcces")
      }
    });

    const handleWebSocketEvent = () => {
      console.log('WebSocket event received');
      fetchEtapesEnAttente(); // Rafraîchit les données chaque fois qu'un événement WebSocket est reçu
    };

    socket.on('updateEtape', handleWebSocketEvent);
    socket.on('updateProjet', handleWebSocketEvent);

    socket.on("connect_error", (err) => {
      console.error('Erreur de connexion WebSocket:',urlServeurNode,  err);
      console.log(err.message);
      console.log(err.description);
      console.log(err.context);
    });
    



  }, [urlServeurNode, fetchEtapesEnAttente]);

  useEffect(() => {
    const newAttentesCounter = { success: 0, warning: 0, danger: 0 };

    etapesEnAttente.forEach((etape) => {
      const joursRestants = calculerDifferenceJours(etape.echeanceEtape);
      const category = getBackgroundClass(joursRestants);

      if (category === "bg-success bg-gradient") {
        newAttentesCounter.success += 1;
      } else if (category === "bg-warning bg-gradient") {
        newAttentesCounter.warning += 1;
      } else if (category === "bg-danger bg-gradient") {
        newAttentesCounter.danger += 1;
      }
    });

    setAttentesCounter(newAttentesCounter);
  }, [etapesEnAttente]);


  return (
    <>
      <div className="badge bg-success bg-gradient"
        style={{ color: getFontColor("bg-success") }}>
        {attentesCounter.success}
      </div>

      <div className="badge bg-warning bg-gradient"
         style={{  
          color: getFontColor("bg-warning") 
         }}>
        {attentesCounter.warning}
      </div>

      <div className="badge bg-danger bg-gradient"
       style={{  color: getFontColor("bg-danger") }}>
        {attentesCounter.danger}
      </div>
      </>
  );


};
