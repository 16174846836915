import React, { useEffect, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/ListeUtilisateurs.css";
import "./../css/Global.css";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { fetchEnsembleAgences } from "../Utilities/utilities";

export function ListeUtilisateurs() {
  const [listeUtilisateurs, setListeUtilisateurs] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState({});
  const { urlServeurNode } = useContext(ServerContext);
  const { userId, handleUtilisateurId, agenceId } = useContext(AuthContext);

  const handleLabelClick = (utilisateurId) => {
    console.log(`ListeUtilisateurs: UserID cliqué : ${utilisateurId}`);
    handleUtilisateurId(utilisateurId);
    setSelectedUsers({ [utilisateurId]: true });
    // Autres actions à effectuer ici...
  };

  // Créer un objet pour regrouper les utilisateurs par enseigne
  const utilisateursParEnseigne = listeUtilisateurs.reduce(
    (acc, utilisateur) => {
      if (utilisateur.enseigne) {
        if (!acc[utilisateur.enseigne]) {
          acc[utilisateur.enseigne] = [utilisateur];
        } else {
          acc[utilisateur.enseigne].push(utilisateur);
        }
      }
      return acc;
    },
    {}
  );

  useEffect(() => {
    const fetchListeUtilisateurs = async () => {
      console.log("ListeUtilisateurs : DEBUT");
      try {
        if (userId) {
          handleUtilisateurId(""); // On reset l'UtilisateurId
          console.log("ListeUtilisateurs: ", userId);
          // on récupère toutes les informations de l'ensemble des Agences
          const data = await fetchEnsembleAgences(urlServeurNode, agenceId);
          const listeUtilisateursData = data.TousLesUtilisateurs; // On récupère la liste des Utilisateurs

          console.log("ListeUtilisateurs : FIN", listeUtilisateursData);

          setListeUtilisateurs(listeUtilisateursData);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchListeUtilisateurs();
  }, [userId]);

  const filteredListeUtilisateurs = listeUtilisateurs;
  const nbRecords = filteredListeUtilisateurs.length;

  return (
    <div className="fenetre-liste-utilisateurs">
      {/* <h4>Utilisateurs V2</h4> */}
      {userId ? (
        <>
          {nbRecords > 0 ? (
            <>
              {Object.entries(utilisateursParEnseigne).map(
                ([enseigne, utilisateurs]) => (
                  <div key={enseigne}>
                    <h6>{enseigne}</h6>
                    <ul className="liste-sans-puces">
                      {utilisateurs.map((monUtilisateur) => (
                        <li
                          key={monUtilisateur.utilisateurId}
                          style={{ listStyleType: "none" }}
                        >
                          <div className="form-check d-flex">
                            <input
                              className="form-check-input me-2"
                              type="checkbox"
                              value=""
                              id={`flexCheckDefault${monUtilisateur.utilisateurId}`}
                              checked={
                                selectedUsers[monUtilisateur.utilisateurId] ||
                                false
                              }
                              onChange={() =>
                                handleLabelClick(monUtilisateur.utilisateurId)
                              }
                            />
                            <label
                              className={`form-check-label ${
                                monUtilisateur.labelHovered
                                  ? "label-semi-gras"
                                  : ""
                              }`}
                              htmlFor="flexCheckDefault"
                              onClick={() =>
                                handleLabelClick(monUtilisateur.utilisateurId)
                              }
                              onMouseEnter={() => {
                                setListeUtilisateurs((prevListeUtilisateurs) =>
                                  prevListeUtilisateurs.map((prevUtilisateur) =>
                                    prevUtilisateur.utilisateurId ===
                                    monUtilisateur.utilisateurId
                                      ? {
                                          ...prevUtilisateur,
                                          labelHovered: true,
                                        }
                                      : prevUtilisateur
                                  )
                                );
                              }}
                              onMouseLeave={() => {
                                setListeUtilisateurs((prevUtilisateur) =>
                                  prevUtilisateur.map((prevUtilisateur) =>
                                    prevUtilisateur.utilisateurId ===
                                    monUtilisateur.utilisateurId
                                      ? {
                                          ...prevUtilisateur,
                                          labelHovered: false,
                                        }
                                      : prevUtilisateur
                                  )
                                );
                              }}
                            >
                              {monUtilisateur.nomPrenom}
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )
              )}
            </>
          ) : (
            <p>Pas de résultats.</p>
          )}
        </>
      ) : (
        <p>Pas Connecté</p>
      )}
    </div>
  );
}
