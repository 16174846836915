import React, { useEffect, useState, useContext, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/Global.css";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { customAxios } from "../Utilities/utilities";
import { Tooltip } from "react-tooltip";
import { BsTrash3 } from "react-icons/bs";
import { BsDashCircle, BsPlusCircle } from "react-icons/bs";

import ContentEditableField from "../Utilities/ContentEditableField";
import { useProjet } from "../../contexts/ProjetContext";

export const ProjetPreviEcheancierById  = React.memo(( props ) => {

  const { type } = props;  //    type="previsionnel" 

  const [data, setData] = useState([]);
 
  const { urlServeurNode } = useContext(ServerContext);

  const { handleLogout,   
    newPrevisionnel,
    handleAddNewPrevisionnel,
    newEcheancier,
    handleAddNewEcheancier
  } = useContext(AuthContext);

  const [deletingIndex, setDeletingIndex] = useState(false);
  const dateTdRef = useRef(null);
  const [checkedItems, setCheckedItems] = useState({});
  const { projetData } = useProjet();


const handleAddNewItem = async () => {
  const newItem = {
    date: new Date().toISOString(),
    EDB: 0,
    AMO: 0,
    total: 0,
  };
  try {
    const updatedData = [...data, newItem];
    const response = await customAxios.patch(`${urlServeurNode}/api/projets/alone/${projetData._id}`, {
      [type]: updatedData,
    });
    if (response.status === 200) {
      setData(updatedData);
    }
  } catch (error) {
    console.error(error);
    alert('Une erreur est survenue lors de l\'ajout de l’élément.');
  }
};

useEffect(() => {
  if ((type === 'previsionnel' && newPrevisionnel) || (type === 'echeancier' && newEcheancier)) {
    handleAddNewItem();
    if (type === 'previsionnel') handleAddNewPrevisionnel(false);
    if (type === 'echeancier') handleAddNewEcheancier(false);
  }
}, [newPrevisionnel, newEcheancier]);


 
  useEffect(() => {
    const fetchDataById = async () => {
      try {
        if (projetData) {
          const dataToSet =
            type === "previsionnel"
              ? projetData.previsionnel && projetData.previsionnel.length > 0
                ? projetData.previsionnel
                : []
              : projetData.echeancier && projetData.echeancier.length > 0
              ? projetData.echeancier
              : [];

          // console.log("ProjetPreviEcheancierById: fetchDataById dataToSet", dataToSet);

          setData(dataToSet);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.log(
            `ProjetPreviEcheancierById: erreur 401 pas d'accès Token expiré`
          );
          handleLogout();
        } else {
          console.error(error);
        }
      }
    };

    fetchDataById();
    console.log("ProjetPreviEcheancierById APPEL fetchDataById à useEffect.", newPrevisionnel,newEcheancier);
  }, [urlServeurNode, projetData, type, handleLogout,newPrevisionnel, newEcheancier]);

  const isValidDate = (dateString) => {
    const dateRegex = /^(0[1-9]|1[0-2]|[1-9])\/[0-9]{4}$/;
    return dateRegex.test(dateString);
  };



const dateToTimestamp = (dateString) => {
  const [month, year] = dateString.split("/").map(Number);
  // console.log("ProjetPreviEcheancierById dateToTimestamp", month, year, dateString);

  // Créer une date en UTC
  // Notez que le mois est ajusté par -1 car les mois en JavaScript commencent à 0
  const nouvelleDate = new Date(Date.UTC(year, month - 1, 1, 0, 0, 0));
  // console.log("ProjetPreviEcheancierById dateToTimestamp nouvelleDate", nouvelleDate);

  return nouvelleDate;
};


  const handleSave = async (index, key, value) => {
    try {
      // console.log("ProjetPreviEcheancierById handleSave Called with:", index, key, value, data);
      const itemToSave = { ...data[index], [key]: value };

      if (key === "AMO" || key === "EDB") {
        itemToSave.total = (itemToSave.AMO || 0) + (itemToSave.EDB || 0);
      }

      if (key === "date") {
        if (!isValidDate(value)) {
          alert("Le format de la date doit être MM/YYYY (mois en chiffres).");
          if (dateTdRef.current) {
            dateTdRef.current.focus();
          }
          return;
        }

        if (typeof itemToSave.date === "string") {
          itemToSave.date = dateToTimestamp(itemToSave.date);
          // console.log(
          //   "ProjetPreviEcheancierById handleSave AVANT PATCH itemToSave.date ",
          //   itemToSave.date
          // );
      }
    }

      const updatedData = [...data];
      updatedData[index] = itemToSave;

      // console.log(
      //   "ProjetPreviEcheancierById handleSave AVANT PATCH updatedData ",
      //   type,
      //   updatedData,
      //   data
      // );
      setData([...updatedData]);
      // console.log(
      //   "ProjetPreviEcheancierById handleSave Mise à jour réussie ! updatedData",
      //   updatedData,
      //   data
      // );

      await updateDataOnServer(updatedData);
    } catch (error) {
      console.error(error);
      alert("ProjetPreviEcheancierByIdUne erreur est survenue lors de la mise à jour de l’élément.");
    }
  };

  const handleCheckboxChange = (index) => {
    const isChecked = !!checkedItems[index];

    setCheckedItems((prevItems) => ({
      ...prevItems,
      [index]: !isChecked,
    }));

    if (isChecked) return;

    handleDelete(index);
  };

  useEffect(() => {
    setCheckedItems({});
  }, [data]);



  const updateDataOnServer = async (donnees) => {
    console.log("ProjetPreviEcheancierById updateDataOnServer ACTIVÉ data type", type,donnees);
    try {
      const response = await customAxios.patch(
        `${urlServeurNode}/api/projets/alone/${projetData._id}`,
        {
          [type]: donnees,
        }
      );

      if (response.status === 200) {
        console.log("ProjetPreviEcheancierByIdMise à jour réussie sur le serveur !");
      }
    } catch (error) {
      console.error(error);
      alert("ProjetPreviEcheancierById Une erreur est survenue lors de la mise à jour sur le serveur.");
    }
  };

  const handleDelete = async (indexToDelete) => {
    setDeletingIndex(indexToDelete);

    if (window.confirm("Êtes-vous sûr de vouloir supprimer cet élément?")) {
      const newData = data.filter((_, index) => index !== indexToDelete);

      try {
        const updateData =
          type === "previsionnel"
            ? { previsionnel: newData }
            : { echeancier: newData };

        const response = await customAxios.patch(
          `${urlServeurNode}/api/projets/alone/${projetData._id}`,
          updateData
        );

        if (response.status === 200) {
          setData(newData);
          console.log("ProjetPreviEcheancierById Élément supprimé avec succès !");
        }
      } catch (error) {
        console.error(error);
        alert("Une erreur est survenue lors de la suppression de l'élément.");
      }
    }

    setDeletingIndex(null);
  };



    return (
      <div className="d-flex flex-column Tooltip">
            <table className="cadre-zone4">
              <thead className=" table-zone4-header">
                <tr>
                  <th style={{ textAlign: "center" }}>Date</th>
                  <th style={{ textAlign: "center" }}>EDB</th>
                  <th style={{ textAlign: "center" }}>AMO</th>
                  <th style={{ textAlign: "center" }}>Total</th>
                  <th style={{ textAlign: "center" }}>
                    {data.length === 0 && <BsPlusCircle onClick={handleAddNewItem} />}
                  </th>
                </tr>
              </thead>
              <tbody className="table-body">
                {data.map((item, index) => {
                  // console.log("ProjetPreviEcheancierById Rendu item.date", item.date);
                  // const dateTimestamp = new Date(item.date).getTime();
                  const dateTimestamp = new Date(item.date);
                  // console.log("Rendu dateTimestamp", dateTimestamp);
  
                  const formattedDate = new Intl.DateTimeFormat("fr-FR", {
                    year: "numeric",
                    month: "2-digit",
                  }).format(dateTimestamp);
  
                  // console.log("ProjetPreviEcheancierById formattedDate", formattedDate);
  
                  return (
                    <tr key={index}
                    >
                      <td  style={{ textAlign: "center" }}>
                        <ContentEditableField
                          initialValue={formattedDate}
                          onSave={(value) => handleSave(index, "date", value)} // Utiliser onSave pour transmettre la valeur mise à jour
                        />
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <ContentEditableField
                          initialValue={item.EDB.toString()}
                          onSave={(value) =>
                            handleSave(index, "EDB", parseInt(value))
                          }
                        />
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <ContentEditableField
                          initialValue={item.AMO.toString()}
                          onSave={(value) =>
                            handleSave(index, "AMO", parseInt(value))
                          }
                        />
                      </td>
                      {/* <td style={{ padding: "0 2px" }}>{item.total}</td> */}
                      <td style={{ textAlign: "center" }} >{item.total}</td>

                      <td style={{ textAlign: "center" }}>
                        <BsDashCircle              
                          data-tooltip-id={`TooltipSuppression`}
                          data-tooltip-content={"Cliquez pour supprimer l'éntrée élément"}
                          onClick={() => handleCheckboxChange(index)}/>

                        <span style={{ paddingRight: "2px" }}></span>
                        <BsPlusCircle onClick={handleAddNewItem} />
                      </td>
                    </tr>
                  );
                })}
                <tr style={{ height: "6px" }}></tr>
              </tbody>
            </table>
      
  
        <Tooltip
          id="TooltipSuppression"
          place="top"
          effect="solid"
          className="custom-tooltip"
        ></Tooltip>
        <Tooltip
          id="TooltipInsertion"
          place="top"
          effect="solid"
          className="custom-tooltip"
        ></Tooltip>
      </div>
      );

})
