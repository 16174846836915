import React, { useEffect, useState, useContext, useMemo } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/ListeTiers.css";
import "./../css/Global.css";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { customAxios } from "../Utilities/utilities";
import { Tooltip } from "react-tooltip";

export function ListeTiersByUserIdAndTypeTiers({ typeTiers }) {
  const [tiers, setTiers] = useState([]);
  const { urlServeurNode } = useContext(ServerContext);
  const { userId, handleTiersId, handleUtilisateurId, searchTerm } =
    useContext(AuthContext);
  const [isToggled, setIsToggled] = useState(true);
  const [selectedTiersId, setSelectedTiersId] = useState("");
  const [etat, setEtat] = useState("enCours"); // État local pour gérer l'état des projets
  const [etatTiers, setEtatTiers] = useState("ouvert"); // État local pour gérer l'état des projets

  const handleLabelClick = async (tiersId, e) => {
    e.preventDefault();
    e.stopPropagation();

    handleTiersId(tiersId);
    handleUtilisateurId("");
    setSelectedTiersId(tiersId);
  };

  const handleToggleChange = () => {
    setIsToggled(!isToggled);
    setEtatTiers(isToggled ? "archivé" : "ouvert"); // Mettre à jour l'état en fonction du toggle
  };



  const handleCheckClick = async (tiersId) => {
    try {
      // Trouver le tiers correspondant à tiersId
      const leTiers = tiers.find(tiers => tiers._id === tiersId);

      if (!leTiers) {
        console.error("Tiers introuvable");
        return;
      }

      // Trouver l'accès tiers pour le userId
      const accesTiers = leTiers.accesTiers.find(acces => acces.utilisateur === userId);

      if (!accesTiers) {
        console.error("Accès tiers pour l'utilisateur introuvable");
        return;
      }

      // Déterminer le nouvel état
      const nouvelEtat = accesTiers.etat === "ouvert" ? "archivé" : "ouvert";

      // Mettre à jour l'état du tiers sur le serveur
      const response = await customAxios.put(
        `${urlServeurNode}/api/tiers/${tiersId}`,
        { etat: nouvelEtat, userId: userId }
      );


      if (response.data && response.data._id) {
        // Mettre à jour l'état local des Tiers
        setTiers((prevTiers) =>
          prevTiers.map((tiers) => tiers._id !== tiersId)
        );

        handleTiersId(null);
  
      } else {
        console.error("La mise à jour du tiers a échoué.");
      }

    } catch (error) {
      console.error("Erreur lors de la mise à jour du tiers:", error);
    }
  };

  const fetchTiersByUserIdAndTypeTiers = async () => {
    try {
      const requestBodyTiers = {
        userId: userId,
        typeTiers: typeTiers,
        etat: etatTiers
      };

      console.log("ListeTiersByUserIdAndTypeTiers: fetchTiersByUserIdAndTypeTiers requestBodyTiers", requestBodyTiers);
      

      const response = await customAxios.post(
        `${urlServeurNode}/api/tiers/byUserIdAndTypeTiers`,
        requestBodyTiers
      );
      console.log("ListeTiersByUserIdAndTypeTiers: fetchTiersByUserIdAndTypeTiers response", response.data);
      setTiers(response.data.tiers);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
 fetchTiersByUserIdAndTypeTiers();

}, [typeTiers, userId, urlServeurNode, etatTiers, isToggled]);



  const ToggleSwitch = () => (
    <label className="toggle-switch">
      <input
        type="checkbox"
        checked={isToggled}
        onChange={handleToggleChange}
      />
      <span className="switch-slider round">
        <span className="switch-state">
          {isToggled ? "Actifs" : "En Archive"}
        </span>
      </span>
    </label>
  );

  useEffect(() => {
    handleTiersId(null);
    setIsToggled(true);
    setEtatTiers("ouvert");
  }, [typeTiers]);


  const filteredListeTiers = useMemo(() => {
    const tiersActifs = tiers.filter(
      (tiersDeTravail) => tiersDeTravail.actif === true
    );
    const tiersFiltrésParNom = tiersActifs.filter((tiersDeTravail) =>
      tiersDeTravail.nom.toLowerCase().includes(searchTerm.toLowerCase())
    );
    tiersFiltrésParNom.sort((a, b) => a.nom.localeCompare(b.nom));
    return tiersFiltrésParNom;
  }, [tiers, searchTerm]);

  const nbRecords = filteredListeTiers.length;

  return (
    <div className="fenetre-liste-tiers">
      <div className="center-toggle-switch">
        <ToggleSwitch />
      </div>
      <div className="cadre-zone2">
        {nbRecords > 0 ? (
          <ul className="liste-sans-puces">
            {filteredListeTiers.map((monTiers, index) => (
              <li key={monTiers._id}>
                <div className="form-check d-flex ms-1">
                  <input
                    data-tooltip-id={`infoCheckBox-${monTiers._id}`}
                    data-tooltip-content={
                      monTiers.etat === "enCours"
                        ? "Ne plus travailler avec ce tiers"
                        : "Réactiver le tiers"
                    }
                    data-tooltip-place="top"
                    className="form-check-input me-2"
                    type="checkbox"
                    id={`flexCheckDefault${monTiers._id}`}
                    onClick={(e) => handleCheckClick(monTiers._id)}
                  />
                  <label
                    className={`form-check-label souris-pointeur ${
                      monTiers.labelHovered ? "label-semi-gras" : ""
                    }`}
                    htmlFor={`flexCheckDefault${monTiers._id}`}
                    onClick={(e) => handleLabelClick(monTiers._id, e)}
                    onMouseEnter={() => {
                      /* Votre logique ici */
                    }}
                    onMouseLeave={() => {
                      /* Votre logique ici */
                    }}
                  >
                    {typeTiers === "CLIENT"
                      ? `${monTiers.nom} ${monTiers.prenom}`
                      : monTiers.nom}
                  </label>
                  <Tooltip
                    id={`infoCheckBox-${monTiers._id}`}
                    place="top"
                    effect="solid"
                    className="custom-tooltip"
                  ></Tooltip>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p></p>
        )}
      </div>
    </div>
  );
}