import React from 'react';
import { Tooltip } from "react-tooltip";


export function ListeRègles({ AllAgences }) {
  // Vous pouvez modifier ces règles selon vos besoins
  const regles = [

    /// Règles Côté Partenaires 
    "Règle 4: Si pas d'attente spécifique côté Partenaire alors creer un bouton en Face de la rubrique DEVIS du Projet par EXEMPLE DÉPOSER NOUVEAU DEVIS",
    "Règle 5: Si nouveau DOCUMENT déposé dans la Rubrique NOUVEAU DEVIS alors créer nouvelle étape en attente Devis (9) et passer le Partenaire en Blanc avec échéance à J si pas d'échéance",
    

    // Par défaut côté Agence en AMO
    "Règle 10: Action:: Création d'un nouveau projet => Assigner échéance de l'Etape 1 à J+7",
    "Règle 11: Si dans une étape on ajoute un nouveau destinataire si pas d'échéance alors échéance à J+7 sur cette étape",
    "Règle 12: Si on archive l'étape en cours alors  échéance à J+7 (si pas d'échéance) sur l'étape suivante ",
    "Règle 13: Si nouveau projet alors échéance à J+7 sur prochaine étape sur la première",
    "Règle 14: Si DEST passe en Blanc(reçu ou répondu) en ETAPE 10 alors créer DEST en Bleu (enAttente) avec échéance à J+60 si pas d'échéance en ETAPE 11",
    "Règle 15: Si DEST passe en Blanc(reçu ou répondu) en ETAPE 11 alors créer DEST en Bleu (enAttente) avec échéance à J+3 si pas d'échéance en ETAPE 12",
    "Règle 16: Si DEST passe en Blanc(reçu ou répondu) en ETAPE 12 alors créer DEST en Bleu (enAttente) avec échéance à J+7 si pas d'échéance en ETAPE 13,14,15",
   
    "Règle 17: Si DEST passe en Blanc(reçu ou répondu) en ETAPE 15 alors créer DEST en Bleu (enAttente) avec échéance à J+15 si pas d'échéance en ETAPE 16",
    "Règle 18: Si DEST passe en Blanc(reçu ou répondu) en ETAPE 16 alors créer DEST en Bleu (enAttente) avec échéance à J+3 si pas d'échéance en ETAPE 17",

    "Règle 19: Si DEST passe en Blanc(reçu ou répondu) en ETAPE 19 alors créer DEST en Bleu (enAttente) avec échéance à J+30 si pas d'échéance en ETAPE 20",
    "Règle 20: Si DEST passe en Blanc(reçu ou répondu) en ETAPE 20 alors créer DEST en Bleu (enAttente) avec échéance à J+3 si pas d'échéance en ETAPE 21",
    "Règle 21: Si DEST passe en Blanc(reçu ou répondu) en ETAPE 21 alors créer DEST en Bleu (enAttente) avec échéance à J+3 si pas d'échéance en ETAPE 22",
    "Règle 22: Si DEST passe en Blanc(reçu ou répondu) en ETAPE 22 alors créer DEST en Bleu (enAttente) avec échéance à J+3 si pas d'échéance en ETAPE 23",
  ];

  return (
    <div>
      <h5>Liste des règles</h5>
      <ul>
        {regles.map((regle, index) => (
          <li key={index}>{regle}</li>
        ))}
      </ul>
    </div>
  );
};

