import { customAxios } from "./utilities";
import { useContext } from 'react';
import ServerContext from '../../contexts/ServeurProvider';

export function extractModelIdWithIsDefaultTrue(listeModeles) {
  const modeleId = listeModeles.find((modele) => modele.isDefault === true);
  return modeleId ? modeleId._id : null;
}

const useGetModeles = () => {
  const { urlServeurNode } = useContext(ServerContext);

  const fetchModelesAdmin = async () => {
    try {
      const responseAgence = await customAxios.get(
        `${urlServeurNode}/api/modeles/AllAdminModeles`
      );
      const listeModeles = responseAgence.data.listeModeles;
      console.log("fetchModelesAdmin: responseAgence", responseAgence);
      console.log("fetchModelesAdmin: listeModeles", listeModeles);

      const modeleDetailsPromises = listeModeles.map((Modele) =>
        fetchModeleDetails(Modele.modeleId._id)
      );

      const results = await Promise.all(modeleDetailsPromises);
      return results;
    } catch (error) {
      console.error("Erreur lors de la récupération des modèles ou des détails des modèles", error);
      return [];
    }
  };

  const fetchModeles = async (tiersId) => {
    try {
      const responseAgence = await customAxios.get(
        `${urlServeurNode}/api/tiers/${tiersId}`
      );
      const listeModeles = responseAgence.data.listeModeles;

      const modeleDetailsPromises = listeModeles.map((Modele) =>
        fetchModeleDetails(Modele.modeleId)
      );

      const results = await Promise.all(modeleDetailsPromises);
      return results;
    } catch (error) {
      console.error("Erreur lors de la récupération des modèles ou des détails des modèles", error);
      return [];
    }
  };

  const fetchProjetModeleId = async (modeleId) => {
    try {
      console.log("fetchProjetModeleId", modeleId);

      const response = await customAxios.post(
        `${urlServeurNode}/api/projets/byModeleId`, { modeleIds: [modeleId] }
      );
    
        // console.log(
        //   "fetchProjetModeleId",
        //   modeleId,
        //   response
        // );
      

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error(
          "fetchProjetModeleId Le modèle avec l'ID spécifié n'a pas été trouvé."
        );
      } else {
        console.error(
          "fetchProjetModeleId Erreur lors de la récupération du nom du modèle:",
          error
        );
      }
      return null;
    }
  };



  const fetchAttentesProjetByUserIdAndModeleId = async (userId, modeleId) => {
    try {
      const response = await customAxios.get(
        `${urlServeurNode}/api/projets/attentesByUserIdAndModeleId/${userId}/${modeleId}`
      );

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error(
          "CountEtapesAttentesProjetsByModeles Le modèle avec l'ID spécifié n'a pas été trouvé."
        );
      } else {
        console.error(
          "CountEtapesAttentesProjetsByModeles Erreur lors de la récupération du nom du modèle:",
          error
        );
      }
      return null;
    }
  };

  const fetchSansAttentesProjetByUserIdAndModeleId = async (userId, modeleId) => {
    try {
      console.log("fetchSansAttentesProjetByUserIdAndModeleId", modeleId);
      const response = await customAxios.get(
        `${urlServeurNode}/api/projets/sansAttentesByUserIdAndModeleId/${userId}/${modeleId}`
      );

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error(
          "CountEtapesAttentesProjetsByModeles Le modèle avec l'ID spécifié n'a pas été trouvé."
        );
      } else {
        console.error(
          "CountEtapesAttentesProjetsByModeles Erreur lors de la récupération du nom du modèle:",
          error
        );
      }
      return null;
    }
  };

  const fetchProjetBydModeleId = async (userId, modeleId) => {
    try {
      console.log("fetchProjetBydModeleId", modeleId);
      const response = await customAxios.get(
        `${urlServeurNode}/api/projets/byModeleId/${modeleId}`
      );

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error(
          "CountEtapesAttentesProjetsByModeles Le modèle avec l'ID spécifié n'a pas été trouvé."
        );
      } else {
        console.error(
          "CountEtapesAttentesProjetsByModeles Erreur lors de la récupération du nom du modèle:",
          error
        );
      }
      return null;
    }
  };

  const fetchProjetByUserIdAndModeleId = async (userId, modeleId) => {
    try {
      console.log("fetchProjetByUserIdAndModeleId modeleId:", modeleId);
      const response = await customAxios.get(
        `${urlServeurNode}/api/projets/byUserAndModeleId/${userId}/${modeleId}`
      );

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error(
          "fetchProjetByUserIdAndModeleId Le modèle avec l'ID spécifié n'a pas été trouvé."
        );
      } else {
        console.error(
          "fetchProjetByUserIdAndModeleId Erreur lors de la récupération du nom du modèle:",
          error
        );
      }
      return null;
    }
  };

  const fetchModeleName = async (modeleId) => {
    try {
      const response = await customAxios.get(
        `${urlServeurNode}/api/modeles/${modeleId}`
      );
      return response.data.nom;
    } catch (error) {
      console.error("Error fetching modele name:", error);
      return null;
    }
  };

  const fetchModeleDetails = async (modeleId) => {
    try {
      const response = await customAxios.get(
        `${urlServeurNode}/api/modeles/${modeleId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching modele name:", error);
      return null;
    }
  };

  const fetchEtapesFromModeleId = async (modeleId) => {
    try {
      const response = await customAxios.get(
        `${urlServeurNode}/api/modeles/${modeleId}`
      );
      console.log("fetchEtapesFromModeleId", modeleId, response.data.etapes);
      return response.data.etapes;
    } catch (error) {
      console.error("fetchEtapesFromModeleId: Error fetching modele name:", error);
      return null;
    }
  };

  return {
    fetchModelesAdmin,
    fetchModeles,
    fetchProjetModeleId,
    fetchAttentesProjetByUserIdAndModeleId,
    fetchSansAttentesProjetByUserIdAndModeleId,
    fetchProjetBydModeleId,
    fetchProjetByUserIdAndModeleId,
    fetchModeleName,
    fetchModeleDetails,
    fetchEtapesFromModeleId,
  };
};

export default useGetModeles;

export function getIdFromName(array, name) {
  const item = array.find((obj) => obj.nom === name);
  return item ? item._id : null;
}

export function getNameFromId(array, id) {
  console.log("getNameFromId id", id);
  console.log("getNameFromId array", array);
  const item = array.find((obj) => obj._id === id);
  return item ? item.nom : null;
}
