import React, { useContext, useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";

import "./../css/Global.css";
import "./css/AfficheTiersByTiersId.css";

import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { customAxios } from "../Utilities/utilities";

import { fetchEnsembleAgences } from "../Utilities/utilities";

//
// On récupère le tiers par tiers ID du contexte et les utilisateurs attachés
//
export function AfficheTiersByTiersId() {
  const { userId, tiersId, handleLogout, agenceId } =
    useContext(AuthContext);
  const { urlServeurNode } = useContext(ServerContext);
  const [isModified, setIsModified] = useState(false);
  const [toutesLesEnseignes, setToutesLesEnseignes] = useState(null);
  const [agenceHote, setAgenceHote] = useState(null); // On stocke l'agence hote du tiersId

  // Utilisation de useForm
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const [tiers, setTiers] = useState({
    nom: "",
    utilisateurs: [],
    tiers: [],
    idtiers: "",
    enseigne: "",
    competences: [],
    disponibilites: [],
    dateImmatriculation: new Date(),
    dateSignatureContrat: new Date(),
    experience: "",
    siren: "",
    siteWeb: "",
    adresse: "",
    cp: "",
    ville: "",
    iban: "",
    commission: "",
    reseauxSociaux: [{ type: "", url: "" }],
    type: "PRESTATAIRE",
    autonome: false,
    observations: "",
    actif: true,
  });

  const fetchTiersById = async () => {
    try {
      console.log(
        "AfficheTiersByTiersId: DEBUG 1000 On récupère les infos de ce Tiers userId",
        userId,
        tiersId
      );
      if (userId) {
        console.log(
          "AfficheTiersByTiersId: DEBUG 2000 On récupère les infos de ce Tiers"
        );

        const response = await customAxios.get(
          `${urlServeurNode}/api/tiers/${tiersId}`
        );

        const tiersData = response.data;

        console.log(
          "AfficheTiersByTiersId: GET On récupère les utilisateurs de ce Tiers",
          tiersId,
          tiersData
        );

        Object.keys(tiersData).forEach((key) => {
          if (typeof tiersData[key] === "boolean") {
            tiersData[key] = tiersData[key] ? "true" : "false";
          }
          setValue(key, tiersData[key]);
        });

        // On récupère les utilisateurs de ce tiers
        const responseUtilisateurs = await customAxios.get(
          `${urlServeurNode}/api/utilisateurs/tiers/${tiersId}`
        );
        const utilisateursData = responseUtilisateurs.data.utilisateurs;
        const nbUtilisateurs = responseUtilisateurs.data.nbUtilisateurs;

        // console.log(
        //   "AfficheTiersByTiersId: On récupère les utilisateurs de ce Tiers DEBUG 1 ",
        //   utilisateursData
        // );
        if (nbUtilisateurs > 0) {
          setTiers({
            ...tiersData,
            utilisateurs: utilisateursData,
          });
        } else {
          setTiers({
            ...tiersData,
            utilisateurs: [],
          });
        }

        // console.log(
        //   "AfficheTiersByTiersId: On récupère les utilisateurs de ce Tiers DEBUG 2 "
        // );
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("TiersById: erreur 401 pas d'accès Token expiré");
        // Déconnexion du tiers et redirection vers la page de login
        handleLogout();
      } else {
        console.error(error);
      }
    }
  };

  const fetchEnseignesAgences = async () => {
    try {
      console.log(
        "AfficheTiersByTiersId fetchEnseignesAgences agenceId",
        agenceId
      );
      const data = await fetchEnsembleAgences(urlServeurNode, agenceId);
      console.log("AfficheTiersByTiersId fetchEnseignesAgences data", data);

      const TiersAgences = data.TiersAgences;
      // Filtrer d'abord les agences où l'enseigne est différente de ""

      // const agencesAvecEnseigne = TiersAgences.filter(
      //   (agence) => agence.enseigne !== "" && agence.typeTiers === "AGENCE"
      // );

      const agencesAvecEnseigne = TiersAgences.filter(
        (agence) => agence.typeTiers === "AGENCE"
      );

      console.log(
        "AfficheTiersByTiersId fetchEnseignesAgences DEBUG 3000 agencesAvecEnseigne ",
        agencesAvecEnseigne,
        "tiersId",
        tiersId
      );

      // On cherche à quelle AGENCE ce tiersId est rattachée
      const agenceHoteData = data.TiersAgences.filter(
        (agence) =>
          agence.typeTiers === "AGENCE" && agence.tiers.includes(tiersId)
      ).map((agence) => ({
        Id: agence._id,
        nom: agence.nom,
        nomEnseigne: agence.enseigne,
      }))[0]; // Supposons qu'il y ait une seule correspondance

      console.log(
        "AfficheTiersByTiersId fetchEnseignesAgences agenceHote AVANT ",
        agenceHoteData
      );
      setAgenceHote(agenceHoteData); // Mettre à jour l'état avec les données de l'agence hôte
      // setValue("attachementAgenceId", agenceHoteData.Id); // Définit le premier élément comme sélectionné par défaut
      // setValue("nomEnseigne", agenceHoteData.nomEnseigne); // Optionnel: si vous avez besoin de définir aussi le nom de l'enseigne

      console.log(
        "AfficheTiersByTiersId fetchEnseignesAgences agenceHote APRES",
        agenceHoteData
      );

      // Ensuite, mapper sur le tableau filtré pour extraire les informations nécessaires
      const enseignesEtIds = agencesAvecEnseigne.map((agence) => ({
        nomEnseigne: agence.enseigne,
        idAgence: agence._id, // Assurez-vous de remplacer '_id' par le nom réel de la propriété ID dans vos objets
      }));

      console.log(
        "AfficheTiersByTiersId fetchEnseignesAgences enseignesEtIds",
        enseignesEtIds
      );
      setToutesLesEnseignes(enseignesEtIds); // On glisse toutes les enseignes
    } catch (err) {
      console.error(err);
    }
  };

  // Utilisez un gestionnaire d'événements pour détecter les modifications des champs
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTiers((prevTiers) => ({
      ...prevTiers,
      [name]: value,
    }));
    setValue(name, value);
    // Marquez le formulaire comme modifié
    setIsModified(true);
  };

  const handleNomChange = (event) => {
    const { name, value } = event.target;

    // Convertir le texte en majuscules
    const upperCaseValue = value.toUpperCase();

    // Mettre à jour le state avec la nouvelle valeur en majuscules
    setTiers((prevTiers) => ({
      ...prevTiers,
      [name]: upperCaseValue,
    }));

    setValue(name, upperCaseValue);

    // Marquer le formulaire comme modifié
    setIsModified(true);
  };

  useEffect(() => {
    if (agenceHote) {
      setValue("attachementAgenceId", agenceHote.Id);
      setValue("nomEnseigne", agenceHote.nomEnseigne);
      // Vous pouvez également définir d'autres champs du formulaire ici si nécessaire
    }
  }, [agenceHote, setValue]);

  useEffect(() => {
    console.log("AfficheTiersByTiersId useEffect");

    setIsModified(false);
    fetchTiersById();
    fetchEnseignesAgences(); // On récupère la liste des Agences
  }, [tiersId]);

  // Mise à jour de l'enregistrement
  const onSubmit = async (data) => {
    try {
      // On s'assure que le tiers est toujours attach& à la meme Agence

      if (agenceHote && agenceHote._id !== data.attachementAgenceId) {
        console.log(
          "AfficheTiersByTiersId onSubmit On a changé d'AGENCE: agenceHote",
          agenceHote
        );

        try {
          // Suppression du tiersId de l'ancienne agence
          const reponseRemoveTiers = await customAxios.patch(
            `${urlServeurNode}/api/tiers/${agenceHote.Id}/removeTiers`,
            { tiersId }
          );

          console.log(
            "AfficheTiersByTiersId onSubmit reponseRemoveTiers",
            reponseRemoveTiers.data
          );
          // Ajout du tiersId à la nouvelle agence
          const reponseAddTiers = await customAxios.patch(
            `${urlServeurNode}/api/tiers/${data.attachementAgenceId}/addTiers`,
            { tiersId }
          );
          console.log(
            "AfficheTiersByTiersId onSubmit reponseAddTiers",
            reponseAddTiers.data
          );

          console.log(
            "AfficheTiersByTiersId Le transfert du tiers entre les agences a été effectué avec succès"
          );
        } catch (error) {
          console.error(
            "AfficheTiersByTiersId Erreur lors du transfert du tiers entre les agences",
            error
          );
          // Gérer l'erreur (par exemple, afficher un message d'erreur à l'utilisateur)
        }
      }

      // Pour forcer en boolean
      data.clientParticulier = data.clientParticulier === "true";

      console.log(
        "AfficheTiersByTiersId Mise à jour du tiers avec ID:",
        tiersId,
        data
      );
      const response = await customAxios.put(
        `${urlServeurNode}/api/tiers/${tiersId}`,
        data
      );

      setIsModified(false); // On fait disparaitre le bouton
      console.log(
        "AfficheTiersByTiersId: Tiers mis à jour avec succès !",
        response
      );
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log(
          "AfficheTiersByTiersId: erreur 401 pas d'accès Token expiré"
        );
        // Déconnexion du tiers et redirection vers la page de login
        handleLogout();
      } else {
        console.error(error);
      }
    }
  };

  return (
    <div className="form-group  cadre-zone3">
      <form onSubmit={handleSubmit(onSubmit)}>
 <h5 className="titre-zone3" style={{  textAlign: 'center' }}>
          {watch("nom")}
        </h5>

        <div>
          {tiers.typeTiers === "PARTENAIRE" && (
            <>
              {/* Fragment pour englober le texte et le span */}
              ID Extranet : <span>{tiers.idtiers}</span>
            </>
          )}
        </div>

        {tiers.typeTiers === "CLIENT" && (
          // <div className="form-field">
          <div className="form-field">
            <label htmlFor="clientParticulier">Particulier :</label>
            <select
              style={{
                fontSize: "var(--fs-plus-grande)",
                width: "20%",
                // border: "none"
              }}
              {...register("clientParticulier", {
                required: "Ce champ est requis",
              })}
              id="clientParticulier"
              name="clientParticulier"
              onChange={handleInputChange} // Ajoutez ceci pour détecter les changements
            >
              <option value="true">Oui</option>
              <option value="false">Non</option>
            </select>
            {errors.clientParticulier && (
              <p>{errors.clientParticulier.message}</p>
            )}
          </div>
        )}

        {(tiers.typeTiers === "PARTENAIRE" ||
          tiers.typeTiers === "PRESTATAIRE" ||
          tiers.typeTiers === "AGENCE" ||
          (tiers.typeTiers === "CLIENT" &&
            watch("clientParticulier") === "false")) && (
          <div className="form-field">
            <label htmlFor="civilite">Raison Sociale :</label>
            <input
              {...register("nom", {
                required: "Raison sociale nécessaire",
              })}
              type="text"
              id="raisonSociale"
              name="nom"
              placeholder="Raison Sociale"
              onChange={handleNomChange} // Ajoutez ceci pour détecter les changements
            />
            {errors.raisonSociale && (
              <p className="errorMessage">{errors.raisonSociale.message}</p>
            )}
          </div>
        )}

        {tiers.typeTiers === "CLIENT" &&
          watch("clientParticulier") === "true" && (
            <div className="form-field">
              <label htmlFor="nom">Nom :</label>
              <input
                {...register("nom", { required: "Le Nom est nécessaire" })}
                type="text"
                id="nom"
                name="nom"
                placeholder="Nom"
                onChange={handleNomChange} // Ajoutez ceci pour détecter les changements
              />
              {errors.nom && (
                <p className="errorMessage">{errors.nom.message}</p>
              )}
            </div>
          )}

        {tiers.typeTiers === "CLIENT" &&
          watch("clientParticulier") === "true" && (
            <div className="form-field">
              <label htmlFor="prenom">Prénom :</label>
              <input
                {...register("prenom", {
                  required: "Le prénom est nécessaire",
                })}
                type="text"
                id="prenom"
                name="enseprenomigne"
                placeholder="Prénom"
                onChange={handleInputChange} // Ajoutez ceci pour détecter les changements
              />
              {errors.prenom && (
                <p className="errorMessage">{errors.prenom.message}</p>
              )}
            </div>
          )}

        {tiers.typeTiers !== "CLIENT" &&
          tiers.typeTiers !== "PRESTATAIRE" &&
          tiers.typeTiers !== "PARTENAIRE" && (
            <div className="form-field">
              <label htmlFor="enseigne">Enseigne :</label>
              <input
                {...register("enseigne", {
                  required: "Enseigne est nécessaire",
                })}
                type="text"
                id="enseigne"
                name="enseigne"
                placeholder="Enseigne"
                onChange={handleInputChange} // Ajoutez ceci pour détecter les changements
              />
              {errors.enseigne && (
                <p className="errorMessage">{errors.enseigne.message}</p>
              )}
            </div>
          )}

        {tiers.typeTiers !== "CLIENT" && (
          <div className="form-field">
            <label htmlFor="siren">Siren :</label>
            <input
              {...register("siren", {
                required: "Numéro de Siren est nécessaire",
              })}
              type="text"
              id="siren"
              name="siren"
              placeholder="Siren"
              onChange={handleInputChange} // Ajoutez ceci pour détecter les changements
            />
            {errors.siren && (
              <p className="errorMessage">{errors.siren.message}</p>
            )}
          </div>
        )}

        <div className="form-field">
          <label htmlFor="adresse">Adresse :</label>
          <input
            {...register("adresse", { required: "L'adresse est requise" })}
            type="text"
            id="adresse"
            name="adresse"
            placeholder="Adresse"
            onChange={handleInputChange} // Ajoutez ceci pour détecter les changements
          />
          {errors.adresse && (
            <p className="errorMessage">{errors.adresse.message}</p>
          )}
        </div>

        <div className="form-field">
          <label htmlFor="cp">CP :</label>
          <input
            {...register("cp", {
              required: "Le code postal est requis",
              pattern: {
                value: /^[0-9]+$/,
                message: "Le code postal doit être un nombre entier",
              },
            })}
            type="text"
            id="cp"
            name="cp"
            placeholder="CP"
            value={tiers.cp}
            onChange={handleInputChange} // Ajoutez ceci pour détecter les changements
          />
          {errors.cp && <p className="errorMessage">{errors.cp.message}</p>}
        </div>

        <div className="form-field">
          <label htmlFor="ville">Ville :</label>
          <input
            {...register("ville", { required: "La ville est requise" })}
            type="text"
            id="ville"
            name="ville"
            placeholder="Ville"
            onChange={handleInputChange} // Ajoutez ceci pour détecter les changements
          />
          {errors.ville && (
            <p className="errorMessage">{errors.ville.message}</p>
          )}
        </div>

        <div className="form-field">
          <label htmlFor="iban">Iban :</label>
          <input
            {...register("iban", {
              required: false,
              pattern: {
                value: /^[0-9]+$/,
                message: "L'Iban doit comporter uniquement des nombres.",
              },
            })}
            type="text"
            id="iban"
            name="iban"
            placeholder="Iban"
            onChange={handleInputChange} // Ajoutez ceci pour détecter les changements
          />
          {errors.iban && <p className="errorMessage">{errors.iban.message}</p>}
        </div>

        <div className="form-field">
          <label htmlFor="observations">Observations :</label>
          <textarea
            {...register("observations")}
            type="text"
            id="observations"
            name="observations"
            placeholder="Observations"
            style={{
              height: "80px", // Définit une hauteur spécifique
              border: "none", // Supprime la bordure
              paddingLeft: "8px",
              // outline: "none" // Supprime l'outline lors de la sélection
            }}
            onChange={handleInputChange} // Ajoutez ceci pour détecter les changements
          />
          {errors.observations && (
            <p className="errorMessage">{errors.observations.message}</p>
          )}
        </div>

        {isModified && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "10px 0",
            }}
          >
            <button
              className="btn"
              onMouseOver={(e) => (e.target.style.backgroundColor = "#0056b3")}
              onMouseOut={(e) => (e.target.style.backgroundColor = "#007bff")}
            >
              Mettre à jour
            </button>
          </div>
        )}
      </form>

      {/* Prévoir de supprimer cette entrepise si pas utiliser au sein de l'agence */}
      {/* Liste le nombre de taches qui utilise ce tiers */}
      <form>
        {(tiers.typeTiers === "PRESTATAIRE" ||
          tiers.typeTiers === "PARTENAIRE") && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "10px 0",
            }}
          >
            <button
              className="btn"
              onMouseOver={(e) => (e.target.style.backgroundColor = "#0056b3")}
              onMouseOut={(e) => (e.target.style.backgroundColor = "#007bff")}
            >
              Ne plus travailler avec cette entreprise
            </button>
          </div>
        )}
      </form>
    </div>
  );
}
