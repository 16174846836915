import { createContext, useContext, useState, useEffect } from 'react';

const ProjetContext = createContext();

export function useProjet() {
  return useContext(ProjetContext);
}

export function ProjetProvider({ children }) {
  const [projetData, setProjetData] = useState(null);
  const [projetDataId, setProjetDataId] = useState(null);
  const [refreshIndicator, setRefreshIndicator] = useState(false);

  const refreshProjects = () => {
    console.log("ProjetProvider refreshProjects varie");
    setRefreshIndicator(oldValue => !oldValue); // Basculer la valeur pour forcer un rafraîchissement
  };

  useEffect(() => {
    // Mettre à jour projetDataId chaque fois que projetData change

    if (projetData) {
      console.log("ProjetProvider projetData varie", projetData.nom);
      setProjetDataId(projetData.id); // Supposons que l'ID de projetData soit stocké dans "id"
    } else {
      console.log("ProjetProvider projetData Pas Initialisé");
      setProjetDataId(null);
    }
  }, [projetData]);

  return (
    <ProjetContext.Provider value={{ projetData, setProjetData, projetDataId , refreshProjects, refreshIndicator }}>
      {children}
    </ProjetContext.Provider>
  );
}
