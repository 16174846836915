import React, { useEffect, useState, useContext } from "react";
import { Navbar } from "react-bootstrap";
import { BsPersonFill, BsBoxArrowRight } from "react-icons/bs";
import Cookies from "js-cookie";
import "./css/Header.css";
import "../css/Global.css";
import { UtilisateurSelecteur } from "../Utilisateurs/UtilisateurSelecteur";
import { CheckExpirationDateOfToken } from "../Session/CheckExpirationDateOfToken";

import { AuthContext } from "../../contexts/AuthProvider";

export function Header() {

  const [leaderName, setLeaderName] = useState("");
  const [leaderLastName, setLeaderLastName] = useState("");

  const [NomTiers, setNomTiers] = useState("");
  const { userId, handleLogout, isManager, showZone1, handleShowZone1,   
    isMenuToggled,
    handleIsMenuToggled } =
    useContext(AuthContext);


  const toggleMenu = () => {
    const newToggleState = !isMenuToggled;
    handleIsMenuToggled(newToggleState); // Mettre à jour l'état dans le contexte
  };

  useEffect(() => {
    const fetchLeaderInfo = async () => {
      try {
    
        const userNom = Cookies.get("userNomConnecté");
        const userPrenom = Cookies.get("userPrénomConnecté");
        const userNomTiers = Cookies.get("userNomTiers");

        console.log(
          "Header userNomTiers",
          userNomTiers,
          "userNom",
          userNom,
          "userPrenom",
          userPrenom
        );
        setLeaderName(userNom);
        setLeaderLastName(userPrenom);
        setNomTiers(userNomTiers);
      } catch (error) {
        console.error(error);
      }
    };

    if (userId) {
      fetchLeaderInfo();
    }
  }, [userId]);

  return (
    <Navbar
      expand="lg"
      className="d-flex justify-content-between "
      style={{
        // backgroundColor: "white",
        borderRadius: "10px",
        marginBottom: "20px",
        // boxShadow: "0 4px 16px rgba(0,0,0,.1)",
      }}
    >


      {showZone1 ? (
        <Navbar.Toggle aria-controls="basic-navbar-nav"    onClick={toggleMenu} />
      ) : (
        <Navbar.Toggle aria-controls="basic-navbar-nav"    onClick={toggleMenu}/>
      )}

      <Navbar.Brand href="#">
        <span
          style={{ fontSize: "35px", paddingLeft: "40px", fontWeight: 500, color : "var(--texte-fond)" }}
        >
          İAkka...
        </span>
      </Navbar.Brand>

      <div className="d-flex justify-content-center flex-grow-1">
        {userId && isManager && <UtilisateurSelecteur />}
      </div>

      <div
        className="d-flex justify-content-end align-items-center"
        style={{ paddingRight: "20px" }}
      >
        <span className="mr-2 header" style={{ paddingRight: "10px" , color : "var(--texte-fond)"}}>
          {/* {userId ? 
            (NomEnseigne ? `${NomEnseigne} - ${leaderLastName} ${leaderName}` : `${NomTiers} - ${leaderLastName} ${leaderName}`) 
            : ''} */}

          {userId ? `${leaderLastName} ${leaderName}` : ""}
        </span>

        {userId ? (
          <>
            <CheckExpirationDateOfToken />
            <BsBoxArrowRight
              className="logout double-size ml-2 title-quick"
              onClick={handleLogout}
              color = "var(--texte-fond)"
              title="Se déconnecter"
            />
          </>
        ) : (
          <>
            <BsPersonFill
              className="login double-size title-quick"
              title="Se connecter"
            />
            <span className="badge bg-danger rounded-pill">!</span>
          </>
        )}
      </div>
    </Navbar>
  );
}
