import React, { useEffect, useState, useContext, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/Global.css";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";

import io from 'socket.io-client'; 
import Cookies from "js-cookie";
import useGetModeles from "../Utilities/GetModeles";

export function CountModeles() {
  const { urlServeurNode } = useContext(ServerContext);
  const { userId, agenceId } = useContext(AuthContext);
  const [nbModeles, setNbModeles] = useState(0);
  const { fetchModeles }  = useGetModeles(); 

  const chargerModeles = useCallback(async () => {
    if (userId && urlServeurNode && agenceId) {
      try {
        const tousLesModelesDetailles = await fetchModeles(agenceId);
        setNbModeles(tousLesModelesDetailles.length);
      } catch (error) {
        console.error("Erreur lors du chargement des modèles:", error);
        setNbModeles(0); // Réinitialiser en cas d'erreur
      }
    }
  }, [userId, urlServeurNode, agenceId]);

  useEffect(() => {

    if (!urlServeurNode) return;

    chargerModeles();

      console.log("CountModeles: const socket = io(urlServeurNode)", urlServeurNode);

    const socket = io(urlServeurNode, {
      path: '/ws',
      auth: {
        token: Cookies.get("userTokenAcces")
      }
    });
    socket.on('nouveauModèle', chargerModeles);

  }, [chargerModeles]);

  return userId ? <span className="badge badge-info">{nbModeles}</span> : null;
}
