import React, { useEffect, useState, useContext } from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/Global.css";
import { Tooltip } from "react-tooltip";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { customAxios } from "../Utilities/utilities";
import io from 'socket.io-client';
import Cookies from "js-cookie";

export function GetModeleById() {
  const [nomModele, setNomModele] = useState("");
  const [etapesModele, setEtapesModele] = useState([]);
  const [etapesPrevi, setEtapesPrevi] = useState([]);
  const [etapesEcheancier, setEtapesEcheancier] = useState([]);
  const [etapeCounts, setEtapeCounts] = useState([]);

  const { urlServeurNode } = useContext(ServerContext);
  const { handleLogout, modeleId, handleModelOrder } = useContext(AuthContext);

  const fetchEtapeCount = async (etapeId) => {
    try {
      const response = await customAxios.get(`${urlServeurNode}/api/etapes/modeleEtape/${etapeId}/count`);
      return response.data.count;
    } catch (error) {
      console.error("Erreur lors de la récupération du nombre d'étapes:", error);
      return null;
    }
  };

  const fetchEtapeDetails = async (etapeId) => {
    try {
      
      // console.log("GetModeleById fetchEtapeDetails etapeId", etapeId);
      const response = await customAxios.get(`${urlServeurNode}/api/modeles/etapesModele/${etapeId}`);
      // console.log("GetModeleById fetchEtapeDetails", response.data);
      return response.data;
    } catch (error) {
      console.error("GetModeleById fetchEtapeDetails Erreur lors de la récupération des détails de l'étape du modèle:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchCounts = async () => {
      if (etapesModele.length === 0) return;

      const countsPromises = etapesModele.map((etape) => fetchEtapeCount(etape._id));
      const countsResults = await Promise.all(countsPromises);

      setEtapeCounts(countsResults);
    };

    fetchCounts();
  }, [etapesModele]);

  useEffect(() => {
    const fetchModeleById = async () => {
      try {
        if (modeleId) {
          const response = await customAxios.get(`${urlServeurNode}/api/modeles/${modeleId}`);
          const data = response.data;

          console.log("GetModeleById fetchModeleById DEBUG 1000", data);
          setNomModele(data.nom);
          setEtapesPrevi(data.etapesPrevisionnel);
          setEtapesEcheancier(data.etapesEcheancier);

          const etapesPromises = data.etapes.map((etapeId) => fetchEtapeDetails(etapeId));
          const etapesDetails = await Promise.all(etapesPromises);

          console.log("GetModeleById fetchModeleById DEBUG 1100", etapesDetails);
          setEtapesModele(etapesDetails);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          handleLogout();
        } else {
          console.error(error);
        }
      }
    };

    fetchModeleById();

    const socket = io(urlServeurNode, {
      path: '/ws',
      auth: { token: Cookies.get("userTokenAcces") }
    });

    socket.on('updateModèle', fetchModeleById);
    socket.on('updateProjet', fetchModeleById);

    return () => {
      socket.off('updateModèle', fetchModeleById);
      socket.off('updateProjet', fetchModeleById);

    };
  }, [urlServeurNode, modeleId]);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    console.log("GetModeleById onDragEnd DEBUG 2000", result);

    const items = Array.from(etapesModele);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setEtapesModele(items);

    const updatedOrder = items.map((item, index) => ({
      ...item,
      ordre: index + 1,
    }));

    handleModelOrder(updatedOrder);

    console.log("GetModeleById onDragEnd DEBUG 2200", updatedOrder);
    updateDatabase(updatedOrder);
  };



  const updateDatabase = async (nouvellesEtapes) => {
    try {
      const data = { etapes: nouvellesEtapes };
      await customAxios.patch(`${urlServeurNode}/api/modeles/${modeleId}`, data);

      // Içi on met à jour les étapes eintermédiare avec le status enCours
      await customAxios.patch(`${urlServeurNode}/api/projets/updateEtapesByModeleId`, { modeleId });
    } catch (error) {
      console.error("Erreur lors de la mise à jour des étapes :", error);
    }
  };


  const LIST_STYLE = {
    listStyleType: "none",
    fontSize: 14.4,
    fontWeight: 300,
    fontFamily: getComputedStyle(document.documentElement).getPropertyValue("--bs-body-font-family").trim(),
    lineHeight: '1.2',
    padding: '0 10px',
    margin: '5px 0',
    borderRadius: "6px",
    paddingLeft: "5px",
    cursor: "pointer",
  };

  return (
    <div className="cadre-zone3">
      {modeleId && nomModele && (
        <>
          <h6
            data-tooltip-id={`infoModele-${modeleId}`}
            data-tooltip-content={"Liste des étapes modifiables. Application immédiate à tous les projets"}
            data-tooltip-place="top"
          >
            Modèle {nomModele}
          </h6>
          <Tooltip
            id={`infoModele-${modeleId}`}
            place="top"
            effect="solid"
            className="custom-tooltip"
          ></Tooltip>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <ul className="liste-sans-puces" {...provided.droppableProps} ref={provided.innerRef}>
                  {etapesModele
                    .sort((a, b) => a.ordre - b.ordre)
                    .map((etape, index) => (
                      <Draggable key={etape._id} draggableId={etape._id.toString()} index={index}>
                        {(provided) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{ ...LIST_STYLE, ...provided.draggableProps.style }}
                            className={index % 2 === 0 ? 'bg-custom' : ''}
                          >
                            {etape.ordre} - {etape.actionRef?.description || 'Description indisponible'} - 
                            <span 
                              data-tooltip-id={`TooltipCompteurTaches-${index}`}
                              data-tooltip-content="Nombre de Projets en attente sur cette tâche"
                              className={`badge ${etapeCounts[index] > 0 ? 'bg-danger bg-gradient' : 'badge-info'}`}
                            >
                              {etapeCounts[index]}
                            </span>

                            <Tooltip
                              id={`TooltipCompteurTaches-${index}`}
                              place="top"
                              effect="solid"
                              className="custom-tooltip"
                            />
                          </li>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </>
      )}
    </div>
  );
}
