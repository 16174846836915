import React, { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import bcrypt from 'bcryptjs';
import "./../css/Global.css";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { customAxios } from "../Utilities/utilities";
import { fakerFR as faker } from "@faker-js/faker";
import { Tooltip } from "react-tooltip";
import { useProjet } from "../../contexts/ProjetContext";
import { fetchEnsembleAgences } from "../Utilities/utilities";


// On récupère le tiers par tiers ID du contexte et les utilisateurs attachés
// Par défaut l'agence auquel appartient celui qui se connecte.
//
import {useCreateNewProjet} from "../Utilities/GetProjets";
import useGetModeles from "../Utilities/GetModeles";


import axios from 'axios';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

export function CreateNewTiers(props) {
  const { fetchModeles, fetchModelesAdmin }  = useGetModeles(); 
  const { createNewProjet }  = useCreateNewProjet(); 

  const { typeTiers } = props;
  const {
    isAdmin,
    userId,

    handleLogout,
    handleTiersId,
    handleUtilisateurId,
    agenceId,
    handleSelectedMenu,
  } = useContext(AuthContext);

  const { urlServeurNode } = useContext(ServerContext);
  const [isModified, setIsModified] = useState(false);
  const [toutesLesEnseignes, setToutesLesEnseignes] = useState([]);
  const [listeModelesDétaillés, setListeModelesDétaillés] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategorie, setSelectedCategorie] = useState("");
  const [agenceHoteData, setAgenceHoteData] = useState(null);
  const [modeleParDefaut, setModeleParDefaut] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [error, setError] = useState(null);
  const [siretValid, setSiretValid] = useState(null);

  const { refreshProjects } = useProjet();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const [tiers, setTiers] = useState({});

  const remplirChampsAvecDonneesFictives = () => {
    const typesProjets = [
      "Extension",
      "Rénovation totale",
      "Rénovation partielle",
      "Surélévation",
    ];

    const indexAleatoire = Math.floor(Math.random() * typesProjets.length);

    const donneesFictives = {
      // siren: faker.number.int({ min: 100000000, max: 999999999 }).toString(), // Générer un nombre à 9 chiffres et le convertir en chaîne
      raisonSociale: faker.company.name().toUpperCase(),
      nom: faker.person.lastName().toUpperCase(),
      prenom: faker.person.firstName(),
      enseigne: "Enseigne-" + faker.company.name(),
      cp: faker.number.bigInt({ min: 10000, max: 65535 }),
      ville: faker.location.city().toUpperCase(),
      adresse: faker.location.streetAddress(),
      iban: faker.finance.accountNumber(12),
      observations: "From Faker Generator",
      email: faker.internet.email(), 
      telephone: faker.phone.number(), 
      descriptionProjet: typesProjets[indexAleatoire],
    };

    Object.keys(donneesFictives).forEach((key) => {
      setValue(key, donneesFictives[key]);
    });

    setIsModified(true);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValue(name, value);
    setIsModified(true);
  };

  const handleNomChange = (event) => {
    const { name, value } = event.target;
    const upperCaseValue = value.toUpperCase();
    setValue(name, upperCaseValue);
    setIsModified(true);
  };

  const handleEnseigneBlur = () => {
    const enseigneValue = watch("enseigne");
    const raisonSocialeValue = watch("raisonSociale");

    if (!enseigneValue) {
      setValue("enseigne", raisonSocialeValue);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await customAxios.get(`${urlServeurNode}/api/categories/allcategories`);
      setCategories(response.data.categories);
    } catch (error) {
      console.error("Erreur lors de la récupération des catégories:", error);
    }
  };

  const chargerTousLesModeles = async () => {
    if (userId && urlServeurNode && agenceId) {
      try {
        const tousLesModeles = await fetchModeles(agenceId);
        const modelesFiltres = tousLesModeles.filter(
          (modele) => modele.cible === typeTiers
        );

        setListeModelesDétaillés(modelesFiltres);

        const modeleParDefaut =
          modelesFiltres.find((modele) => modele.isDefault) ||
          modelesFiltres[0];
        setModeleParDefaut(modeleParDefaut);
      } catch (erreur) {
        console.error(
          "CreateNewTiers Erreur lors de la récupération des modèles :",
          erreur
        );
      }
    }
  };

  const fetchEnseignesAgences = async () => {
    try {
      const data = await fetchEnsembleAgences(urlServeurNode, agenceId);
      const TiersAgences = data.TiersAgences;
      const agencesAvecEnseigne = TiersAgences.filter(
        (agence) => agence.enseigne !== "" && agence.typeTiers === "AGENCE"
      );

      let agenceHoteData;

      const directAgence = data.TiersAgences.find(
        (agence) =>
          agence.utilisateurs.includes(userId) && agence.typeTiers === "AGENCE"
      );
      const prestataire = data.TiersAgences.find(
        (agence) =>
          agence.utilisateurs.includes(userId) &&
          agence.typeTiers === "PRESTATAIRE"
      );

      if (directAgence) {
        agenceHoteData = {
          Id: directAgence._id,
          nom: directAgence.nom,
          nomEnseigne: directAgence.enseigne,
        };
      } else if (prestataire) {
        const agenceRattachement = agencesAvecEnseigne.find((agence) =>
          agence.tiers.includes(prestataire._id)
        );

        if (agenceRattachement) {
          agenceHoteData = {
            Id: agenceRattachement._id,
            nom: agenceRattachement.nom,
            nomEnseigne: agenceRattachement.enseigne,
          };
        }
      }

      setAgenceHoteData({
        Id: agenceHoteData?.Id,
        nomEnseigne: agenceHoteData?.nomEnseigne,
      });

      return agencesAvecEnseigne;
    } catch (err) {
      console.error(err);
    }
  };

  const fetchData = async () => {
    try {
      const toutesLesEnseignesAgences = await fetchEnseignesAgences(); 
      chargerTousLesModeles();
      setToutesLesEnseignes(toutesLesEnseignesAgences);
      if (typeTiers === "AGENCE") {
        fetchCategories();
      }
    } catch (error) {
      console.error(
        "Une erreur s'est produite lors de la récupération des enseignes :",
        error
      );
    }
  };

  useEffect(() => {
    if (agenceHoteData) {
      setValue("attachementAgenceId", agenceHoteData.Id);
      setValue("nomEnseigne", agenceHoteData.nomEnseigne);
    }

    if (modeleParDefaut) {
      setValue("modeleId", modeleParDefaut._id); 
      setValue("nomModele", modeleParDefaut.nom); 
    }
  }, [agenceHoteData, modeleParDefaut, setValue]); 

  useEffect(() => {
    handleTiersId("");
    handleUtilisateurId(""); 
    fetchData(); 
  }, [typeTiers]);

  // Fetch company information based on SIREN
  const fetchCompanyInfoBySiren = async (siren) => {

    setError(null);
    setSiretValid(null);
    try {
      const response = await axios.get(`https://api.insee.fr/entreprises/sirene/V3.11/siren/${siren}`, {
        headers: {
          'Authorization': `Bearer a44ee339-08d1-3364-a655-ce66c6cd92bf`
        }
      });
      console.log("fetchCompanyInfoBySiren", response);
      const uniteLegale = response.data.uniteLegale;
      if (uniteLegale && uniteLegale.periodesUniteLegale.length > 0) {
        const periode = uniteLegale.periodesUniteLegale[0];
        const nic = periode.nicSiegeUniteLegale;
        const siret = siren + nic;
        fetchCompanyInfoBySiret(siret);
      } else {
        setError('Aucune information trouvée pour ce numéro SIREN.');
        setSiretValid(false);
      }
    } catch (err) {
      setError('Erreur lors de la récupération des informations de l\'entreprise. Veuillez vérifier le numéro SIREN et réessayer.');
      setSiretValid(false);
    } 
  };

  // Fetch company information based on SIRET
  const fetchCompanyInfoBySiret = async (siret) => {

    setError(null);
    setSiretValid(null);
    try {
      const response = await axios.get(`https://api.insee.fr/entreprises/sirene/V3.11/siret/${siret}`, {
        headers: {
          'Authorization': `Bearer a44ee339-08d1-3364-a655-ce66c6cd92bf`
        }
      });
      console.log("fetchCompanyInfoBySiret", response);
      setCompanyInfo(response.data);
      const etablissement = response.data.etablissement;

      if (etablissement) {
        const denomination = etablissement.uniteLegale.denominationUniteLegale || etablissement.periodesEtablissement[0]?.denominationUsuelleEtablissement;
        setValue('raisonSociale', denomination);
        setValue('adresse', `${etablissement.adresseEtablissement.numeroVoieEtablissement} ${etablissement.adresseEtablissement.typeVoieEtablissement} ${etablissement.adresseEtablissement.libelleVoieEtablissement}`);
        setValue('cp', etablissement.adresseEtablissement.codePostalEtablissement);
        setValue('ville', etablissement.adresseEtablissement.libelleCommuneEtablissement);
        setSiretValid(true);
    }

    
    } catch (err) {
      setError('Erreur lors de la récupération des informations de l\'entreprise. Veuillez vérifier le numéro SIRET et réessayer.');
      setSiretValid(false);
    } 
  };

  useEffect(() => {
    const siren = watch('siren');
    if (siren) {
      if (siren.length === 9 && !isNaN(siren)) {
        fetchCompanyInfoBySiren(siren);
      } else {
        setValue('raisonSociale', '');
        setValue('adresse', '');
        setValue('cp', '');
        setValue('ville', '');
        setCompanyInfo(null);
        if (siren.length > 0 && siren.length !== 9) {
          setError('Le numéro de SIREN doit contenir 9 chiffres.');
          setSiretValid(false);
        } else {
          setError(null);
          setSiretValid(null);
        }
      }
    }
  }, [watch('siren')]);

  const dupliquerModeles = async (modeles) => {
    try {
      console.log("dupliquerModeles modeles", modeles);
      const response = await customAxios.post(`${urlServeurNode}/api/modeles/dupliquer-modeles`, { modeles });
      return response.data;
    } catch (error) {
      console.error("Erreur lors de la duplication des modèles:", error);
      return [];
    }
  };

  const onSubmit = async (data) => {
    try {
      let idsUtilisateurs = [],
        nomDeFamille = data.nom;

      data.cp = data.cp.toString();
      data.siren = data.siren.toString();
      data.typeTiers = typeTiers;
      data.actif = true;
      data.siteWeb = "";
      data.createur = userId; 
      data.autonome = true;


      if (typeTiers === "PARTENAIRE" || typeTiers === "PRESTATAIRE") {
        data.clientParticulier = false;
        data.nom = data.raisonSociale;
        data.descriptionProjet = "Documents Administratifs";
      }
      if (typeTiers === "AGENCE") {
        data.nom = data.raisonSociale;
        const tousLesModeles = await fetchModelesAdmin();
        console.log("CreateNewTiers: selectedCategorie",selectedCategorie);
        console.log("CreateNewTiers: tousLesModeles",  tousLesModeles);
        const modelesAssocies = tousLesModeles.filter(
          (modele) => modele.categorie && modele.categorie.includes(selectedCategorie)
        );
        console.log("CreateNewTiers: modelesAssocies", modelesAssocies);
     
        const nouveauxModeles = await dupliquerModeles(modelesAssocies);
        data.listeModeles = nouveauxModeles.map(({ modeleId, newId }) => ({
          modeleId: newId
        }));
      }

      if (data.clientParticulier === true) {
        delete data.siren;
        delete data.raisonSociale;
        delete data.clientParticulier;
      }

      if (typeTiers !== "PRESTATAIRE" && typeTiers !== "AGENCE") {
        delete data.enseigne;
      }

      const agenceId = data.attachementAgenceId; 
      delete data.attachementAgenceId; 
      delete data.nomEnseigne; 

      if (typeTiers !== "AGENCE") {
        const dataUtilisateurs = await fetchEnsembleAgences(
          urlServeurNode,
          agenceId
        );
        const listeUtilisateursData = dataUtilisateurs.TousLesUtilisateurs; 

        idsUtilisateurs = listeUtilisateursData.map(
          (utilisateur) => utilisateur.utilisateurId
        );

        data.gestionnaire = idsUtilisateurs; 
      }

      const nouveauUtilisateur = {
        civilite: data.civilite,
        nom: nomDeFamille,
        prenom: data.prenom,
        titre: null,
        adresse: data.adresse,
        login: data.email,
        email: data.email,
        password: await bcrypt.hash("azerty12", 10),
        telephone: data.telephone,
        cp: data.cp.toString(),
        ville: data.ville,
        createur: userId, 
        gestionnaire: idsUtilisateurs, 
        role: "Manager", 
        dateNaissance: null,
        observations: null,
        actif: true,
      };

      const responseUtilisateur = await customAxios.post(
        `${urlServeurNode}/api/utilisateurs/`,
        nouveauUtilisateur
      );

      if (responseUtilisateur.data && responseUtilisateur.data._id) {
        data.utilisateurs = [responseUtilisateur.data._id];
      }

      // Inclure la catégorie sélectionnée dans les données soumises
      data.categorie = selectedCategorie;

      // Création du Tiers
      console.log("CreateNewTiers: AVANT post data", data);
      const response = await customAxios.post(
        `${urlServeurNode}/api/tiers/`,
        data
      );

      console.log("CreateNewTiers: APRES post data", data);
      const nouveauTiersId = response.data._id;

      // if (typeTiers !== "AGENCE") {

      // On ajoute le Tiers ID à la liste des Tiers
        try {
          await customAxios.patch(
            `${urlServeurNode}/api/tiers/${agenceId}/ajouterTiers`,
            {
              tiersId: nouveauTiersId,
            }
          );
        } catch (error) {
          console.error(
            "CreateNewTiers: Erreur lors de la mise à jour de l'agence avec le nouveau tiers",
            error
          );
        }
      // 




      // On doit créer un Projet basé sur data.modeleId
      console.log(
        "CreateNewTiers: data.modeleId ",
        data.modeleId,
        data.descriptionProjet
      );
      const nouveauProjet = await createNewProjet(
        data.modeleId,
        typeTiers, // Si c'est un PARTENAIRE on partage avec tous les utilisateurs
        nouveauTiersId,
        userId,
        null,
        data.descriptionProjet,
        data.gestionnaire /// Là on définit tous les utilisateurs avec qui ce projet sera partagé
      );

      console.log("CreateNewTiers: nouveauProjet ", nouveauProjet);


      refreshProjects();

      switch (typeTiers) {
        case "PARTENAIRE":
          handleSelectedMenu("PARTENAIRES");
          handleTiersId(nouveauTiersId);
          break;

        case "CLIENT":
          handleSelectedMenu("CLIENTS");
          handleTiersId(nouveauTiersId);
          break;

        case "PRESTATAIRE":
          handleSelectedMenu("ALL AGENCES");
          break;

        case "AGENCE":
          handleSelectedMenu("ADMIN AGENCES");
          break;

        default:
          break;
      }
    } catch (error) {
      console.log("CreateNewTiers: error", error);
      if (error.response && error.response.status === 401) {
        handleLogout();
      } else {
        console.error(error);
      }
    }
  };

  return (
    <div className="titre-zone2">
      {typeTiers === "AGENCE" 
        ? <h4>Création d'une nouvelle Agence</h4>
        : <h4>Création nouveau {typeTiers}</h4>
      }

      <form onSubmit={handleSubmit(onSubmit)}>
        <h5>{watch("nom")}</h5>

        {typeTiers !== "AGENCE" && (
          <div className="form-field">
            <label htmlFor="attachementAgenceId">Rattachée à :</label>
            <select
              style={{ fontSize: "var(--fs-normale)", width: "100%" }}
              {...register("attachementAgenceId", {
                required: "Le choix de l'agence est nécessaire",
              })}
              id="attachementAgenceId"
              name="attachementAgenceId"
              onChange={(e) => {
                setValue("attachementAgenceId", e.target.value);

                const selectedEnseigne = toutesLesEnseignes.find(
                  (enseigne) => enseigne._id === e.target.value
                )?.enseigne;

                if (selectedEnseigne) {
                  setValue("nomEnseigne", selectedEnseigne);
                }
                setIsModified(true);
              }}
            >
              {toutesLesEnseignes &&
                toutesLesEnseignes.map((enseigne, index) => (
                  <option key={index} value={enseigne._id}>
                    {enseigne.enseigne}
                  </option>
                ))}
            </select>
            {errors.attachementAgenceId && (
              <p className="errorMessage">
                {errors.attachementAgenceId.message}
              </p>
            )}
          </div>
        )}

        {<hr />}

        {typeTiers === "CLIENT" && (
          <div className="form-field">
            <label htmlFor="clientParticulier">Particulier :</label>
            <select
              style={{ fontSize: "var(--fs-normale)", width: "20%" }}
              {...register("clientParticulier", {
                required: "Ce champ est requis",
              })}
              id="clientParticulier"
              name="clientParticulier"
              defaultValue="true"
              onChange={handleInputChange}
            >
              <option value="true">Oui</option>
              <option value="false">Non</option>
            </select>
            {errors.clientParticulier && (
              <p className="errorMessage">{errors.clientParticulier.message}</p>
            )}
          </div>
        )}

        {typeTiers !== "CLIENT" && (
          <div
            className="form-field"
            data-tooltip-id={isAdmin ? `TooltipSiret` : null}
            data-tooltip-content={
              isAdmin
                ? "Si le Siret existe déjà alors il s'agira d'une agence virtuelle attachée à ce Siret."
                : null
            }
          >
            <label htmlFor="siren">SIREN :</label>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                {...register("siren", {
                  required: "Numéro de SIREN est nécessaire",
                })}
                type="text"
                id="siren"
                name="siren"
                placeholder="SIREN"
                onChange={handleInputChange} 
              />
              {siretValid === true && <FaCheckCircle style={{ color: "green", marginLeft: "8px" }} />}
              {siretValid === false && <FaTimesCircle style={{ color: "red", marginLeft: "8px" }} />}
            </div>
            {errors.siren && (
              <p className="errorMessage">{errors.siren.message}</p>
            )}
            {error && (
              <p className="errorMessage">{error}</p>
            )}
            <Tooltip
              id="TooltipSiret"
              place="top"
              effect="solid"
              className="custom-tooltip"
            ></Tooltip>
          </div>
        )}

        {(typeTiers === "PARTENAIRE" ||
          typeTiers === "PRESTATAIRE" ||
          typeTiers === "AGENCE" ||
          (typeTiers === "CLIENT" &&
            watch("clientParticulier") === "false")) && (
          <div className="form-field">
            <label htmlFor="raisonSociale">Raison Sociale :</label>
            <input
              {...register("raisonSociale", {
                required: "Raison sociale nécessaire",
              })}
              type="text"
              id="raisonSociale"
              name="raisonSociale"
              placeholder="Raison Sociale"
              onChange={handleNomChange} 
            />
            {errors.raisonSociale && (
              <p className="errorMessage">{errors.raisonSociale.message}</p>
            )}
          </div>
        )}

    {<hr />}
        {typeTiers === "AGENCE" && (
          <div className="form-field">
            <label htmlFor="categorie">Catégorie:</label>
            <select
              style={{ fontSize: "var(--fs-normale)", width: "100%" }}
              {...register("categorie", {
                required: "La sélection d'une catégorie est nécessaire",
              })}
              id="categorie"
              name="categorie"
              value={selectedCategorie}
              onChange={(e) => {
                setSelectedCategorie(e.target.value);
                setIsModified(true);
              }}
            >
              <option value="">Sélectionnez une catégorie</option>
              {categories &&
                categories.map((categorie, index) => (
                  <option key={index} value={categorie}>
                    {categorie}
                  </option>
                ))}
            </select>
            {errors.categorie && (
              <p className="errorMessage">{errors.categorie.message}</p>
            )}
          </div>
        )}

        {typeTiers === "CLIENT" && (
          <div className="form-field">
            <label htmlFor="descriptionProjet">Description du projet :</label>
            <input
              style={{ fontSize: "var(--fs-plus-grande)", width: "100%" }}
              {...register("descriptionProjet", {
                required: "Description du projet  nécessaire",
              })}
              type="text"
              id="descriptionProjet"
              name="descriptionProjet"
              placeholder="Description du projet"
              onChange={handleNomChange} 
            />
            {errors.raisonSociale && (
              <p className="errorMessage">{errors.descriptionProjet.message}</p>
            )}
          </div>
        )}

        {<hr />}

        {typeTiers === "AGENCE" && (
            <div style={{ textAlign: 'center' }}>
              <h5>Coordonnées du gérant</h5> 
            </div>
          )}

        <div className="form-field">
          <label htmlFor="civilite">Civilité :</label>
          <select
            style={{ fontSize: "var(--fs-normale)", width: "20%" }}
            {...register("civilite", {
              required: "La civilité est requise",
            })}
            id="civilite"
            name="civilite"
            onChange={handleInputChange} 
          >
            <option value="Mr">Mr</option>
            <option value="Mme">Mme</option>
          </select>
          {errors.civilite && (
            <p className="errorMessage">{errors.civilite.message}</p>
          )}
        </div>

        <div className="form-field">
          <label htmlFor="nom">Nom :</label>
          <input
            {...register("nom", { required: "Le Nom est nécessaire" })}
            type="text"
            id="nom"
            name="nom"
            placeholder="Nom"
            onChange={handleNomChange} 
          />
          {errors.nom && <p className="errorMessage">{errors.nom.message}</p>}
        </div>

        <div className="form-field">
          <label htmlFor="prenom">Prénom :</label>
          <input
            {...register("prenom", { required: "Le prénom est nécessaire" })}
            type="text"
            id="prenom"
            name="prenom"
            placeholder="Prénom"
            onChange={handleInputChange} 
          />
          {errors.prenom && (
            <p className="errorMessage">{errors.prenom.message}</p>
          )}
        </div>

        <div className="form-field">
          <label htmlFor="email">Email :</label>
          <input
            {...register("email", {
              required: "L'email est requis",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Format d'email invalide",
              },
            })}
            type="text"
            id="email"
            name="email"
            placeholder="Email"
            onChange={handleInputChange} 
          />
          {errors.email && (
            <p className="errorMessage">{errors.email.message}</p>
          )}
        </div>

        {(typeTiers === "PARTENAIRE" || typeTiers === "AGENCE") && (
          <div className="form-field">
            <label htmlFor="enseigne">Enseigne :</label>
            <input
              {...register("enseigne", {
                required: "Enseigne est nécessaire",
              })}
              type="text"
              id="enseigne"
              name="enseigne"
              placeholder="Enseigne"
              onChange={handleNomChange}
              onBlur={handleEnseigneBlur} 
            />
            {errors.enseigne && (
              <p className="errorMessage">{errors.enseigne.message}</p>
            )}
          </div>
        )}

        <div className="form-field">
          <label htmlFor="adresse">Adresse :</label>
          <input
            {...register("adresse", { required: "L'adresse est requise" })}
            type="text"
            id="adresse"
            name="adresse"
            placeholder="Adresse"
            onChange={handleInputChange} 
          />
          {errors.adresse && (
            <p className="errorMessage">{errors.adresse.message}</p>
          )}
        </div>

        <div className="form-field">
          <label htmlFor="cp">CP :</label>
          <input
            {...register("cp", {
              required: "Le code postal est requis",
              pattern: {
                value: /^[0-9]+$/,
                message: "Le code postal doit être un nombre entier",
              },
            })}
            type="text"
            id="cp"
            name="cp"
            placeholder="CP"
            value={tiers.cp}
            onChange={handleInputChange}
          />
          {errors.cp && <p className="errorMessage">{errors.cp.message}</p>}
        </div>

        <div className="form-field">
          <label htmlFor="ville">Ville :</label>
          <input
            {...register("ville", { required: "La ville est requise" })}
            type="text"
            id="ville"
            name="ville"
            placeholder="Ville"
            onChange={handleInputChange} 
          />
          {errors.ville && (
            <p className="errorMessage">{errors.ville.message}</p>
          )}
        </div>

        <div className="form-field">
          <label htmlFor="iban">Iban :</label>
          <input
            {...register("iban", {
              required: false,
              pattern: {
                value: /^[0-9]+$/,
                message: "L'Iban doit comporter uniquement des nombres.",
              },
            })}
            type="text"
            id="iban"
            name="iban"
            placeholder="Iban"
            onChange={handleInputChange} 
          />
          {errors.iban && <p className="errorMessage">{errors.iban.message}</p>}
        </div>

        <div className="form-field">
          <label htmlFor="telephone">Téléphone :</label>
          <input
            {...register("telephone", {
              required: "Le téléphone est requis",
              pattern: {
                value: /(\+33\s?[1-9]{1}\d{8})|(0[1-9](?:[ _.-]?[0-9]{2}){4})/,
                message: "Format de téléphone invalide",
              },
            })}
            type="text"
            id="telephone"
            name="telephone"
            placeholder="Numéro de téléphone"
            onChange={handleInputChange} 
          />
          {errors.telephone && (
            <p className="errorMessage">{errors.telephone.message}</p>
          )}
        </div>
        <div className="form-field">
          <label htmlFor="observations">Observations :</label>
          <textarea
            {...register("observations")}
            type="text"
            id="observations"
            name="observations"
            placeholder="Observations"
            style={{ height: "80px" }} 
            onChange={handleInputChange} 
          />
          {errors.observations && (
            <p className="errorMessage">{errors.observations.message}</p>
          )}
        </div>

        {isModified && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "10px 0",
            }}
          >
   

          <button
            className="btn"
            onMouseOver={(e) => (e.target.style.backgroundColor = "#0056b3")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#007bff")}
          >
            {typeTiers === "CLIENT" ? `Cliquer pour créer ${watch("nom")}` : `Cliquer pour créer ${watch("raisonSociale")}`}
          </button>

          </div>
        )}

        <div>
          <button type="button" onClick={remplirChampsAvecDonneesFictives}>
            Fake
          </button>
        </div>
      </form>
    </div>
  );
}
