import React, { useEffect, useState, useContext, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/Global.css";
import { customAxios } from "../Utilities/utilities";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";

import { Tooltip } from "react-tooltip";
import io from 'socket.io-client';
import Cookies from "js-cookie";
import useGetModeles from "../Utilities/GetModeles";

export function CountSansAttentesProjetsByModeles({AllAgences, ModeleId}) {
  const { fetchSansAttentesProjetByUserIdAndModeleId }  = useGetModeles(); 
  const { urlServeurNode } = useContext(ServerContext);
  const { userId, agenceId } = useContext(AuthContext);
  const [nbModeles, setNbModeles] = useState(0);

  const fetchProjetsSansAttentes = useCallback(async () => {
    if (!userId || !urlServeurNode || !agenceId) return;
    try {
      if (AllAgences && ModeleId) {
        const url = `${urlServeurNode}/api/projets/projetsSansAttentes`;
        const modeleIds = [ModeleId].filter(Boolean);
        const response = await customAxios.post(url, {modeleIds});
        console.log("CountSansAttentesProjetsByModeles: response", response.data);
        const projetsUniques = new Set(response.data.map(projet => projet.projetId));
        setNbModeles(projetsUniques.size);
      } else {
        const projets = await fetchSansAttentesProjetByUserIdAndModeleId(userId, ModeleId);
        const projetsUniques = new Set(projets.map(projet => projet.projetId));
        setNbModeles(projetsUniques.size);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setNbModeles(0); // Réinitialiser en cas d'erreur
    }
  }, [userId, urlServeurNode, agenceId, AllAgences, ModeleId]);

  useEffect(() => {
    if (!urlServeurNode) return;
    fetchProjetsSansAttentes();

      console.log("CountSansAttentesProjetsByModeles: const socket = io(urlServeurNode)", urlServeurNode);

    const socket = io(urlServeurNode, {
      path: '/ws',
      auth: {
        token: Cookies.get("userTokenAcces")
      }
    });
    socket.on('nouveauProjet', fetchProjetsSansAttentes);
    socket.on('updateProjet', fetchProjetsSansAttentes);

    // return () => {
    //   socket.off('nouveauProjet', fetchProjetsSansAttentes);
    //   socket.off('updateProjet', fetchProjetsSansAttentes);
    //   socket.disconnect();
    // };

  }, [fetchProjetsSansAttentes]);

  return (
    <>
      {nbModeles > 0 && (
        <span className="badge bg-danger" data-tooltip-id="TooltipCompteurProjetsSansAttentes"
          data-tooltip-content="Nombre de Projets sans attentes">
          {nbModeles}
        </span>
      )}
      <>
        <Tooltip id="TooltipCompteurProjetsSansAttentes" place="top" effect="solid" className="custom-tooltip" />
      </>
    </>
  );
}
