import React, { useRef, useEffect, useContext, useState, useCallback } from "react";
import { BsCloudDownload } from "react-icons/bs";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { customAxios } from "../Utilities/utilities";
import Modal from "../Modal/Modal";

const BoutonDownLoad = ({ etape }) => {
  const { urlServeurNode } = useContext(ServerContext);
  const { userId ,
    userTiersId // Tierd de l'utilisateur

  } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [selectedTiersId, setSelectedTiersId] = useState(null);
  const fileInputRef = useRef(null);
  const [typeTiers, setTypeTiers] = useState("");

  const formatDateForFileName = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = `${d.getMonth() + 1}`.padStart(2, "0");
    const day = `${d.getDate()}`.padStart(2, "0");
    const hour = `${d.getHours()}`.padStart(2, "0");
    const minute = `${d.getMinutes()}`.padStart(2, "0");
    const second = `${d.getSeconds()}`.padStart(2, "0");
    return `${year}${month}${day}_${hour}:${minute}:${second}`;
  };

  const prepareMetadata = (file, uploadDate) => ({
    userId,
    echeanceEtape: etape.echeanceEtape,
    etapeId: etape.etapeId,
    projetId: etape.projetId,
    actionId: etape.actionId,
    destinataireId: selectedTiersId,   // A qui s'adresse ce document?
    typeEtape: etape.typeEtape,
    rubriqueEtape: etape.rubrique,
    prefixeEtape: etape.prefixe,
    uploadDate,                   // Date de téléchargement
    emetteurId: etape.emetteursEtape,
    originalFileName: file.name
  });


  const fetchData = useCallback(async () => {
    try {
      const response = await customAxios.get(`${urlServeurNode}/api/tiers/${userTiersId}`);
      setTypeTiers(response.data.typeTiers);
    } catch (error) {
      console.error("Erreur lors de la récupération des données initiales", error);
    }
  }, [urlServeurNode, userTiersId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);


  const handleFileUpload = async (event) => {
    const files = event.target.files;
    if (!files.length) {
      alert("Aucun fichier sélectionné.");
      return;
    }

    Array.from(files).forEach(async (file) => {
      const uploadDate = formatDateForFileName(new Date());
      const metadata = prepareMetadata(file, new Date());
      console.log("BoutonDownLoad Fichier upload : metadata", metadata);
      // const prefixedFileName = `${etape.prefixe}_${uploadDate}_${file.name}`;
      const prefixedFileName = `${etape.rubrique}_${uploadDate}`;
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", prefixedFileName);
      formData.append("metadata", JSON.stringify(metadata));

      try {
        console.log("BoutonDownLoad Fichier upload : formData", formData);
        const response = await customAxios.post(`${urlServeurNode}/api/files/upload`, formData);
        console.log("BoutonDownLoad Fichier uploadé avec succès :", response.data);
      } catch (error) {
        console.error("Erreur lors de l'upload du fichier :", error);
        alert("Erreur lors de l'upload du fichier.");
      }
    });
  };


  const triggerFileInput = () => {
    if (typeTiers === "AGENCE") {
      if (etape.destinatairesEtape.length > 1) {
        setShowModal(true);
      } else {
        setSelectedTiersId(etape.destinatairesEtape[0].tiers); // Assuming there's always at least one destinataire
        fileInputRef.current.click();
      }
    } else {
      const destinataire = etape.destinatairesEtape.find(dest => dest.tiers === userTiersId);
      if (destinataire) {
        setSelectedTiersId(destinataire.tiers);
        fileInputRef.current.click();
      } else {
        alert("Aucun destinataire correspondant trouvé.");
      }
    }
  };


  const handleModalDownloadClick = () => {
    setShowModal(false);
    fileInputRef.current.click();
  };

  return (
    <>
      <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
        <h4>Sélectionner un destinataire</h4>
        <ul className="liste-sans-puces" style={{ margin: '20px' }}>
          {etape.destinatairesEtape.map((dest, index) => (
            <li key={index}>
              <label style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="radio"
                  name="destinataire"
                  value={dest.tiers}
                  onChange={(e) => setSelectedTiersId(e.target.value)}
                  checked={selectedTiersId === dest.tiers}
                  style={{ marginRight: '10px' }}
                />
                {dest.nom}
              </label>
            </li>
          ))}
        </ul>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <button className="btn" onClick={() => setShowModal(false)}>Annuler</button>
          <button className="btn" onClick={handleModalDownloadClick}>Télécharger</button>
        </div>
      </Modal>

      <input
        type="file"
        multiple
        style={{ display: "none" }}
        onChange={handleFileUpload}
        ref={fileInputRef}
      />

      <BsCloudDownload
        onClick={triggerFileInput}
        style={{
          fontSize: "1.2em",
          color: "var(--texte-zone)",
          cursor: "pointer",
        }}
      />
    </>
  );
};

export default BoutonDownLoad;
