import React, { useEffect, useState, useContext, useCallback } from "react";
import { customAxios } from "../Utilities/utilities";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import io from 'socket.io-client'; 
import Cookies from "js-cookie";

// typeTiers = "AGENCE"
export function CountListeAllAgences(props) {
  const [countListeAllAgences, setCountListeAllAgences] = useState([]);
  const { typeTiers } = props;
  const { urlServeurNode } = useContext(ServerContext);
  const { userId } = useContext(AuthContext);

  const fetchListeAllAgences = useCallback(async () => {
    console.log(`CountListeAllAgences: DEBUT`);
    try {
      // console.log(`CountListeAllAgences:  fetchListeTiers`);
      if (typeTiers && userId) {

        const response = await customAxios.get(
          `${urlServeurNode}/api/tiers/countAllAgences/${typeTiers}`
        );

        console.log(
          `CountListeAllAgences nb ${typeTiers}: ${response.data.nbAgences}`
        );
        setCountListeAllAgences(response.data.nbAgences);
      }
      // console.log(`CountListeAllAgences: FIN `);
    } catch (error) {
      console.error(error);
    }
    // console.log(`CountListeAllAgences: FIN`);
  }, []);

  useEffect(() => {
    if (!urlServeurNode) return;

    fetchListeAllAgences();

      console.log("CountListeAllAgences: const socket = io(urlServeurNode)", urlServeurNode);

    const socket = io(urlServeurNode, {
      path: '/ws',
      auth: {
        token: Cookies.get("userTokenAcces")
      }
    });
    // Écoute pour un événement spécifique, exemple: 'updateTiers'
    socket.on('updateTiers', (data) => {
      console.log( "CountListeAllAgences: webSockets updateTiers", data);
      fetchListeAllAgences(); // Rafraîchit les données chaque fois que l'événement est reçu
    });

    // Nettoyage de la connexion à la fermeture du composant


  }, []);

  return (
    <>
      {userId ? (
        <span className="badge badge-info">{countListeAllAgences}</span>
      ) : (
        "NOK"
      )}
    </>
  );
}
