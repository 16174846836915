import React, { useEffect, useState, useContext } from "react";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { customAxios } from "../Utilities/utilities";
import { iconRendering } from '../Utilities/iconRendering'; // Accès aux Picto en fonction du type daction

import {
  BsArchive,
  BsLightning,
  BsClock,
  BsListCheck,
  BsPeople,
  BsFileEarmarkPerson,
  BsHouseGear,
} from "react-icons/bs";

import { Tooltip } from "react-tooltip";
import "react-datepicker/dist/react-datepicker.css";
import "../css/Global.css";
import io from 'socket.io-client';
import Cookies from "js-cookie";

import {
  getFontColor,
  getBackgroundClass,
  formatDate,
  calculerDifferenceJours,
  getTooltipTypeActionContent,
  cleanString,
} from "../Utilities/AttentesEtapes";

export const ListeAttentesPartenaires = React.memo(({ AllAgences }) => {
  const [etapesEnAttente, setEtapesEnAttente] = useState([]);
  const {
    userId,
    userTiersId,
    tiersId,
    modeleId,
    handleProjetId,
    handleSelectedMenu,
    handleEtapeId,
    modificationDestinataires,
    searchTerm,
  } = useContext(AuthContext);

  const [typeTiers, setTypeTiers] = useState("");
  const { urlServeurNode } = useContext(ServerContext);
  const [sortConfig, setSortConfig] = useState({
    key: "nomProjet",
    direction: "ascending",
  });
  const [selectedDestinataire, setSelectedDestinataire] = useState(null);
  const [selectedEtapeId, setSelectedEtapeId] = useState(null);
  const [editingArchive, setEditingArchive] = useState(null);
  const [editingEcheance, setEditingEcheance] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const ArchiveEtapeEtat = async (etapeId, projetId) => {
    try {
      setEditingArchive(etapeId);
      let nouvelEtat = "enArchive";
      console.log("ArchiveEtapeEtat etapeId", etapeId, "projetId", projetId);

      const response = await customAxios.patch(
        `${urlServeurNode}/api/projets/projet/${projetId}/etapeId/${etapeId}`,
        { etat: nouvelEtat }
      );

      if (response.status === 200) {
        const updatedEtapes = etapesEnAttente.map((etape) =>
          etape.etapeId === etapeId
            ? { ...etape, etapeEtat: nouvelEtat }
            : etape
        );

        setEtapesEnAttente(updatedEtapes);
        setEditingArchive(null);
        console.log("ArchiveEtapeEtat etapesEnAttente", updatedEtapes);
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'étape :", error);
    }
  };

  const handleSimulChange = (etape, index) => {
    toggleEtapeDestinataires(etape);
  };

  const toggleEtapeDestinataires = async (etape) => {
    try {
      if (!etape.destinatairesEtape || etape.destinatairesEtape.length === 0) {
        console.warn("Aucun destinataire à basculer pour l'étape", etape.etapeId);
        return;
      }

      const updatedDestinataires = etape.destinatairesEtape.map(
        (destinataire) => {
          return {
            ...destinataire,
            etat: destinataire.etat === "enAttente" ? "aRépondu" : "enAttente",
          };
        }
      );

      const response = await customAxios.patch(
        `${urlServeurNode}/api/projets/projet/${etape.projetId}/etapeId/${etape.etapeId}`,
        {
          destinatairesEtape: updatedDestinataires,
        }
      );

      if (response.status === 200) {
        setEtapesEnAttente((prevEtapes) =>
          prevEtapes.map((e) =>
            e.etapeId === etape.etapeId
              ? { ...e, destinatairesEtape: updatedDestinataires }
              : e
          )
        );
        console.log("ListeAttentesPartenaires Destinataires mis à jour avec succès");
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour des destinataires", error);
    }
  };

  const showDatePicker = (etapeId, currentEcheance) => {
    console.log("ListeAttentesPartenaires: showDatePicker appelé avec etapeId:", etapeId, "et currentEcheance:", currentEcheance);
    setEditingEcheance(etapeId);

    if (currentEcheance) {
      setSelectedDate(new Date(currentEcheance));
    } else {
      setSelectedDate(new Date());
      console.log("ListeAttentesPartenaires: showDatePicker selectedDate", selectedDate);
    }
  };

  const onDateChange = (nouvelleEcheance, etapeId, projetId) => {
    setSelectedDate(nouvelleEcheance);

    const updateEcheance = async () => {
      try {
        const response = await customAxios.patch(
          `${urlServeurNode}/api/projets/projet/${projetId}/etapeId/${etapeId}`,
          {
            echeanceEtape: nouvelleEcheance,
          }
        );

        if (response.status === 200) {
          console.log("ListeAttentesPartenaires: Mise à jour réussie", response.data, "nouvelleEcheance", nouvelleEcheance);
          setEtapesEnAttente((prevEtapes) =>
            prevEtapes.map((etape) =>
              etape.etapeId === etapeId
                ? { ...etape, echeanceEtape: nouvelleEcheance }
                : etape
            )
          );
          setSelectedDate(new Date(nouvelleEcheance));
          setEditingEcheance(null);
        }
      } catch (error) {
        console.error("ListeAttentesPartenaires: Erreur lors de la mise à jour de l'échéance", error);
      }
    };

    updateEcheance();

    console.log("ListeAttentesPartenaires: onDateChange editingEcheance", editingEcheance);
  };

  const handleDescriptionClick = async (
    event,
    etapeId,
    projetId,
    etapeIndex
  ) => {
    event.preventDefault();

    const etapeActive = selectedEtapeId === etapeId ? null : etapeId;
    try {
      const { data } = await customAxios.get(
        `${urlServeurNode}/api/projets/alone/${projetId}`
      );

      if (!data || !Array.isArray(data.etapes)) {
        console.warn("ListeAttentesPartenaires: handleDescriptionClick Data fetched does not have an 'etapes' array.");
        return;
      }

      const etapes = data.etapes;
      const etapeTrouvee = etapes.find((etape) => etape._id === etapeId);

      if (etapeTrouvee) {
        console.log("ListeAttentesPartenaires: handleDescriptionClick  Étape trouvée:", etapeTrouvee);
      } else {
        console.log("Aucune étape trouvée avec l'ID:", etapeId);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération de l'étape :", error);
    }
    setSelectedEtapeId(etapeActive);
    handleProjetId(projetId);
    handleEtapeId(etapeId);
  };

  useEffect(() => {
    if (!urlServeurNode) return;

    async function fetchData() {
      try {
        const response = await customAxios.get(`${urlServeurNode}/api/tiers/${userTiersId}`);
        setTypeTiers(response.data.typeTiers);
        await fetchAllEtapesEnAttente();
      } catch (error) {
        console.error("Erreur lors de la récupération des données initiales", error);
      }
    }

    const fetchAllEtapesEnAttente = async () => {
      try {
        console.log("ListeAttentesPartenaires: fetchAllEtapesEnAttente: du userTiersId", userTiersId, typeTiers);
        let attentes;

        if (typeTiers !== "" && userTiersId !== null) {
          // const { data } = await customAxios.get(
          //   `${urlServeurNode}/api/projets/attentesByTiersId/${userTiersId}`);
          // attentes = data;

          const requestBody = {
            tiersId: userTiersId,
          };
          const { data }  = await customAxios.post(
            `${urlServeurNode}/api/projets/attentesByTiersId`,
            requestBody
          );
          attentes = data;
        } else {
          attentes = [];
        }

        console.log("ListeAttentesPartenaires: fetchAllEtapesEnAttente 6:", attentes);
        setEtapesEnAttente(attentes || []);
      } catch (error) {
        console.error("ListeAttentesPartenaires: Erreur lors de la récupération des étapes en attente", error);
      }
    };

    fetchData();

    console.log("ListeAttentesPartenaires: useEffect modificationDestinataires", modificationDestinataires);
    setSelectedDestinataire(null);

    console.log("ListeAttentesPartenaires const socket = io(urlServeurNode)", urlServeurNode);
    const socket = io(urlServeurNode, {
      path: '/ws',
      auth: {
        token: Cookies.get("userTokenAcces")
      }
    });

    socket.on('updateEtape', (data) => {
      console.log("ListeAttentesPartenaires: webSockets updateEtape", data);
      fetchAllEtapesEnAttente();
    });

    socket.on('updateProjet', (data) => {
      console.log("ListeAttentesPartenaires: webSockets updateProjet", data);
      fetchAllEtapesEnAttente();
    });



  }, [
    userId,
    typeTiers,
    urlServeurNode,
    modeleId,
    AllAgences,
    editingEcheance,
    editingArchive,
  ]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    console.log(`Sorting by ${key} in ${direction} order`);
    setSortConfig({ key, direction });
  };

  const getStatus = (destinataires) => {
    const destinataireCorrespondant = destinataires?.find(destinataire => destinataire.tiers === tiersId);
    return destinataireCorrespondant ? destinataireCorrespondant.etat : "nonTrouvé";
  };

  const filteredEtapesEnAttente = etapesEnAttente.filter((etape) => {
    const matchesEmetteursEtape = etape.emetteursEtape.some((emetteur) =>
      `${emetteur.nom} ${emetteur.prenom}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );

    const matchesDescriptionProjet = etape.descriptionProjet
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    const matchesDescriptionEtape = etape.descriptionEtape
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    const matchesNomProjet = etape.nomProjet
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    const matchesDestinataires = etape.destinatairesEtape.some((destinataire) =>
      destinataire.nom.toLowerCase().includes(searchTerm.toLowerCase())
    );

    let matchesEcheance = false;
    if (etape.echeanceEtape) {
      const date = new Date(etape.echeanceEtape);
      const dateString = date.toLocaleDateString("fr-FR");
      matchesEcheance = dateString.includes(searchTerm);
    }

    const projet = etape.projet || {};
    const matchesAdresse = `${projet.adresse || ""} ${projet.cp || ""} ${projet.ville || ""}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    return (
      matchesEmetteursEtape ||
      matchesDescriptionEtape ||
      matchesDescriptionProjet ||
      matchesNomProjet ||
      matchesDestinataires ||
      matchesEcheance ||
      matchesAdresse
    );
  });

  const sortedEtapes = React.useMemo(() => {
    console.log("Before sorting:", filteredEtapesEnAttente);

    let sortableItems = filteredEtapesEnAttente.filter((etape) => {
      return (
        etape.etapeEtat === "enAttente" &&
        etape.vueAction.includes(typeTiers)
      );
    });

    if (sortConfig.key) {
      sortableItems.sort((a, b) => {
        if (sortConfig.key === 'statut') {
          const aStatut = getStatus(a.destinatairesEtape);
          const bStatut = getStatus(b.destinatairesEtape);
          console.log(`Comparing ${aStatut} and ${bStatut}`);
          if (aStatut < bStatut) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (aStatut > bStatut) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        }

        if (sortConfig.key === 'emetteursEtape') {
          const aEmetteur = a.emetteursEtape[0]?.nom.toLowerCase() || '';
          const bEmetteur = b.emetteursEtape[0]?.nom.toLowerCase() || '';
          if (aEmetteur < bEmetteur) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (aEmetteur > bEmetteur) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        }

        if (sortConfig.key === 'descriptionProjet' || sortConfig.key === 'descriptionEtape') {
          if (a[sortConfig.key].toLowerCase() < b[sortConfig.key].toLowerCase()) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key].toLowerCase() > b[sortConfig.key].toLowerCase()) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        }

        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    console.log("After sorting:", sortableItems);
    return sortableItems;
  }, [filteredEtapesEnAttente, sortConfig, tiersId]);

  const handleLabelClick = (projetId) => {
    console.log("ListeAttentesPartenaires: handleLabelClick", projetId);
    handleProjetId(projetId);
    handleSelectedMenu("PROJETS CLIENTS");
  };

  const renderDescriptions = (descriptionEtape, destinataires) => {
    const destinataireCorrespondant = destinataires?.find(destinataire => destinataire.tiers === tiersId);
    const etatDuPrestataire = destinataireCorrespondant ? destinataireCorrespondant.etat : "nonTrouvé";

    const classEtatDuPrestataire = etatDuPrestataire === "enAttente"
      ? "destinataire-enAttente"
      : etatDuPrestataire === "aRépondu"
        ? "destinataire-aRépondu"
        : "destinataire-nonTrouvé";

    return (
      <>
        <span
          style={{
            borderRadius: "var(--border-radius-destinataires)",
            marginRight: "2px",
            padding: "1px 8px",
          }}
          // className={classEtatDuPrestataire}
          data-tooltip-id={`infoDescription-${cleanString(descriptionEtape)}`}
          data-tooltip-content={
            etatDuPrestataire === "enAttente"
              ? "Document en attente d'envoi"
              : etatDuPrestataire === "aRépondu"
                ? "Document transmis"
                : "Information non disponible"
          }
          data-tooltip-place="top"
        >
          {descriptionEtape}
        </span>
        <Tooltip
          id={`infoDescription-${cleanString(descriptionEtape)}`}
          place="bottom"
          effect="solid"
          className="custom-tooltip"
        ></Tooltip>
      </>
    );
  };

  const renderEmetteurs = (emetteurs) => {

    console.log("ListeAttentesPartenaires: renderEmetteurs", emetteurs);
    const sortedEmetteurs = (emetteurs || [])
      .filter((emetteur) => emetteur && emetteur.nom)
      .sort((a, b) => (a.nom || "").localeCompare(b.nom || ""));

    const retour = sortedEmetteurs.map(
      (emetteur, emetteurIndex) => {
        const key = `${emetteur.nom || ""}-${emetteurIndex}-`;
        return (
          <React.Fragment key={key}>
            <span
              style={{
                borderRadius: "var(--border-radius-destinataires)",
                marginRight: "2px",
                padding: "1px 8px",
              }}
              data-tooltip-id={`infoEmetteur-${emetteur.nom}`}
              data-tooltip-content={"Gestionnaire du projet"}
              data-tooltip-place="top"
            >
              {`${emetteur.prenom || ""} ${emetteur.nom || ""}`}
            </span>
            <Tooltip
              id={`infoEmetteur-${cleanString(emetteur.nom)}`}
              place="top"
              effect="solid"
              className="custom-tooltip"
            ></Tooltip>
          </React.Fragment>
        );
      }
    );

    return retour;
  };

  const renderStatut = (destinataires) => {
    const destinataireCorrespondant = destinataires?.find(destinataire => destinataire.tiers === tiersId);
    const etatDuPrestataire = destinataireCorrespondant ? destinataireCorrespondant.etat : "nonTrouvé";

    const classStatut = etatDuPrestataire === "enAttente"
      ? "destinataire-enAttente"
      : etatDuPrestataire === "aRépondu"
        ? "destinataire-aRépondu"
        : "destinataire-nonTrouvé";

    const statut = etatDuPrestataire === "aRépondu" ? "TRANSMIS" : "EN ATTENTE";
    return (
      <span className={classStatut}>
        {statut}
      </span>
    );
  };

  return (
    <div>
      <table style={{ width: '100%', tableLayout: 'fixed' }}>
        <thead className="table-header-requetes">
          <tr style={{ backgroundColor: 'var(--bg-zone)' }}>
            <th style={{ width: "2%", textAlign: "center" }}>
              <span style={{ fontSize: "1.2rem", transform: "scale(1.1)" }}>
                <BsArchive />
              </span>
            </th>
   
            <th style={{ width: "10%", textAlign: "center" }} className="sortable-header" onClick={() => requestSort("nomProjet")}>
              <span style={{ fontSize: '1.3rem', transform: 'scale(1.1)' }}>
                <BsFileEarmarkPerson />
              </span>
            </th>
            <th style={{ width: "10%", textAlign: "center" }} className="sortable-header" onClick={() => requestSort("descriptionProjet")}>
              <span style={{ fontSize: '1.3rem', transform: 'scale(1.1)' }}>
                <BsHouseGear />
              </span>
            </th>
            <th style={{ width: "20%", textAlign: "center" }} className="sortable-header" onClick={() => requestSort("descriptionEtape")}>
              <span style={{ fontSize: '1.5rem', transform: 'scale(1.1)' }}>
                <BsListCheck />
              </span>
            </th>
            <th style={{ width: "10%", textAlign: "center" }} className="sortable-header" onClick={() => requestSort("statut")}>
              STATUT
            </th>
            <th style={{ width: "20%", textAlign: "center" }} className="sortable-header" onClick={() => requestSort("emetteursEtape")}>
              <span style={{ fontSize: '1.3rem', transform: 'scale(1.1)' }}>
                <BsPeople />
              </span>
            </th>
            <th style={{ width: "21%", textAlign: "center" }} className="sortable-header" onClick={() => requestSort("typeEtape")}>
              <span style={{ fontSize: '1.3rem', transform: 'scale(1.1)' }}>
                <BsLightning />
              </span>
            </th>
            <th style={{ width: "7%", textAlign: "center" }} className="sortable-header" onClick={() => requestSort("echeanceEtape")}>
              <span style={{ fontSize: '1.3rem', transform: 'scale(1.1)' }}>
                <BsClock />
              </span>
            </th>
          </tr>
        </thead>
        <tbody style={{ display: "table", maxHeight: "800px", overflowY: "auto", width: "100%" }}>
          {sortedEtapes.map((etape, index) => {
            const joursRestants = etape.echeanceEtape
              ? calculerDifferenceJours(etape.echeanceEtape)
              : "N/A";
            const classeBackground = getBackgroundClass(joursRestants);
            const fontColor = getFontColor(classeBackground);
            const rowClass = index % 2 === 0 ? "bg-custom" : "";
            const key = etape.etapeId;

            return (
              <tr
                key={key}
                className={`${rowClass} ${selectedEtapeId === key && !AllAgences ? "selected-row" : "annuler-selected-row"} ${selectedEtapeId !== key && !AllAgences ? "annuler-selected-row" : ""}`}
              >
                <td style={{ width: "2%", borderRadius: "6px 0 0 6px", textAlign: "center", cursor: "pointer" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`archiveTask-${etape.etapeId}`}
                    name={`archiveTask-${etape.etapeId}`}
                    value=""
                    checked={etape.etat === "enArchive"}
                    onChange={() => {
                      ArchiveEtapeEtat(etape.etapeId, etape.projetId);
                    }}
                    data-tooltip-id={`archiveTask-${etape.etapeId}`}
                    data-tooltip-content={"Archive la tâche"}
                    data-tooltip-place="top"
                  />
                  <>
                    <Tooltip
                      id={`archiveTask-${etape.etapeId}`}
                      place="top"
                      effect="solid"
                      className="custom-tooltip"
                    ></Tooltip>
                  </>
                </td>

                <td style={{ width: "10%", paddingLeft: "6px", cursor: "pointer" }}>
                  <div
                    onClick={() => handleLabelClick(etape.projetId)}
                    data-tooltip-id={`infoProjet-${etape.etapeId}`}
                    data-tooltip-content={"Détails du projet"}
                    data-tooltip-place="top"
                  >
                    {etape.nomProjet}
                  </div>
                  <Tooltip
                    id={`infoProjet-${etape.etapeId}`}
                    place="top"
                    effect="solid"
                    className="custom-tooltip"
                  ></Tooltip>
                </td>

                <td className="selection-description-col" style={{ width: "10%", paddingLeft: "6px" }}>
                  {etape.descriptionProjet}
                </td>

                <td className="selection-description-col" style={{ width: "20%", paddingLeft: "6px"  }}>
                  {renderDescriptions(etape.descriptionEtape, etape.destinatairesEtape)}
                </td>

                <td style={{ width: "10%",  textAlign: "center", cursor: "pointer" }}>
                  {renderStatut(etape.destinatairesEtape)}
                </td>

                <td style={{  width: "20%",textAlign: "center", cursor: "pointer" }}>
                  {etape.afficheDestinataires && renderEmetteurs(etape.emetteursEtape)}

                  {/* {renderEmetteurs(etape.emetteursEtape)} */}
                </td>

                <td style={{ width: "21%", textAlign: "center" }}>
                  <div className="action-col" {...(etape.echeanceEtape && {
                    "data-tooltip-id": `infoAction`,
                    "data-tooltip-content": getTooltipTypeActionContent(etape.typeEtape),
                    "data-tooltip-place": "top",
                  })}>
                    {etape.echeanceEtape && iconRendering(etape)}
                  </div>
                  <Tooltip
                    id="infoAction"
                    place="top"
                    effect="solid"
                    className="custom-tooltip"
                  ></Tooltip>
                </td>

                <td className={`echeance-col ${classeBackground}`} style={{
                  cursor: "pointer",
                  width: "7%",
                  color: fontColor,
                  textAlign: "center",
                }} {...(etape.echeanceEtape && {
                  "data-tooltip-id": `infoEcheance`,
                  "data-tooltip-content": "Échéance attendue",
                  "data-tooltip-place": "top",
                })}>
                  {etape.echeanceEtape && formatDate(etape.echeanceEtape)}
                  <Tooltip
                    id="infoEcheance"
                    place="top"
                    effect="solid"
                    className="custom-tooltip"
                  ></Tooltip>
                </td>

              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
});
