import React, { useEffect, useState, useContext, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/Global.css";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { customAxios } from "../Utilities/utilities";

//
// Si etapeId existe alors une étape est sélectionnée
//

export const ListeDestinataires = React.memo(({ typeTiers }) => {
  const [tiers, setListeTiers] = useState([]);
  const { urlServeurNode } = useContext(ServerContext);
  const {
    userId,
    projetId,
    etapeId,
    isDestinatairesOpen,
    handleModificationDestinataires,
  } = useContext(AuthContext);

  const fetchAndUpdateTiersData = useCallback(async () => {
    console.log("ListeDestinataires: fetchAndUpdateTiersData ", typeTiers);
    if (!Array.isArray(typeTiers) || !userId || !projetId || !etapeId) return;

    try {
      // Fetch data for each type in typeTiers array
      let allTiers = [];
      for (const type of typeTiers) {
        const requestBodyTiers = {
          userId: userId,
          typeTiers: type,
        };

        const listeTiersResponse = await customAxios.post(
          `${urlServeurNode}/api/tiers/byUserIdAndTypeTiers`,
          requestBodyTiers
        );
        allTiers = [...allTiers, ...listeTiersResponse.data.tiers];
        console.log(
          "ListeDestinataires: fetchAndUpdateTiersData ",
          type,
          listeTiersResponse.data.tiers
        );
      }

      const requestBodyProjet = {
        etapeId: etapeId,
        projetId: projetId,
      };
      console.log(
        "ListeDestinataires: requestBodyProjet projetId",
        projetId
      );
      const etapeData = await customAxios.post(
        `${urlServeurNode}/api/projets/projetByetapeId`,
        requestBodyProjet
      );
      const currentEtape = etapeData.data;
      console.log(
        "ListeDestinataires: projetByetapeId etapeData.data",
        etapeData.data
      );
      if (currentEtape) {
        allTiers = allTiers.map((tier) => ({
          ...tier,
          checked: currentEtape.actionDestinataires.some(
            (destinataire) => destinataire.tiers === tier._id
          ),
        }));
      }

      console.log(
        "ListeDestinataires: allTiers ",
        allTiers
      );
      setListeTiers(allTiers);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des données des tiers:",
        error
      );
    }
  }, [typeTiers, userId, etapeId, urlServeurNode, projetId]);

  useEffect(() => {
    fetchAndUpdateTiersData();
  }, [fetchAndUpdateTiersData]);

  // A chaque fois qu'on change d'étape on réinitialise les checkbox
  useEffect(() => {
    const updatedTiers = tiers.map((monTiers) => {
      return { ...monTiers, checked: false };
    });

    // console.log("ListeDestinataires useEffect updatedTiers", updatedTiers);
    // console.log("ListeDestinataires useEffect isDestinatairesOpen", isDestinatairesOpen);
    // console.log("ListeDestinataires useEffect etapeId", etapeId);
    setListeTiers(updatedTiers);
  }, [typeTiers, etapeId, isDestinatairesOpen]);

  // Gestion du changement de la checkbox
  const handleCheckboxChange = async (tiersId, checked) => {
    checked ? await addTagsEtape(tiersId) : await removeTagEtape(tiersId);
    fetchAndUpdateTiersData(); // Refresh data to reflect changes
  };

  // Fonction pour activer un projet
  const activateProject = async (projectId) => {
    try {
      const response = await customAxios.patch(
        `${urlServeurNode}/api/projets/activer/${projectId}`
      );
      if (response.status === 200) {
        console.log("ListeDestinataires: Projet activé avec succès");
      }
    } catch (error) {
      console.error("Erreur lors de l'activation du projet:", error);
    }
  };

  // Fonction pour désactiver un projet
  const deactivateProject = async (projectId) => {
    try {
      const response = await customAxios.patch(
        `${urlServeurNode}/api/projets/desactiver/${projectId}`
      );
      if (response.status === 200) {
        console.log("ListeDestinataires: Projet désactivé avec succès");
      }
    } catch (error) {
      console.error("Erreur lors de la désactivation du projet:", error);
    }
  };

  const upDateIsValidProject = useCallback(
    async (currentProjetId) => {
      if (!currentProjetId) return;
      try {
        const response = await customAxios.get(
          `${urlServeurNode}/api/projets/validate/${currentProjetId}`
        );
        const { isValid, nomProjet, invalidSteps } = response.data;

        console.log(
          "ListeDestinataires upDateIsValidProject ",
          nomProjet,
          isValid,
          invalidSteps
        );
        if (!isValid) {
          deactivateProject(currentProjetId); // Désactiver le projet si non valide
          return false;
        } else {
          activateProject(currentProjetId); // Activer le projet si valide
          return true;
        }
      } catch (error) {
        console.error("Erreur lors de la validation du projet:", error);
        return false;
      }
    },
    [urlServeurNode]
  );

  const addTagsEtape = async (tiersId) => {
    try {
      await customAxios.patch(`${urlServeurNode}/api/projets/addDestinataire`, {
        projetId,
        etapeId,
        tiersId,
      });
      handleModificationDestinataires((prevState) => !prevState);
      await upDateIsValidProject(projetId); // Valider le projet après la mise à jour des destinataires
    } catch (error) {
      console.error("Erreur lors de l'ajout du destinataire à l'étape:", error);
    }
  };

  const removeTagEtape = async (tiersId) => {
    try {
      await customAxios.patch(
        `${urlServeurNode}/api/projets/removeDestinataire`,
        {
          projetId,
          etapeId,
          tiersId,
        }
      );
      handleModificationDestinataires((prevState) => !prevState);
      await upDateIsValidProject(projetId); // Valider le projet après la mise à jour des destinataires
    } catch (error) {
      console.error(
        "Erreur lors de la suppression du destinataire de l'étape:",
        error
      );
    }
  };

  const renderTiersList = () => {
    // Trier les tiers par nom
    const sortedTiers = [...tiers].sort((a, b) => {
      if (a.nom < b.nom) return -1;
      if (a.nom > b.nom) return 1;
      return 0;
    });

    console.log(
      "ListeDestinataires renderTiersList",
      sortedTiers.length,
      sortedTiers
    );

    return (
      <ul className="liste-sans-puces">
        {sortedTiers.map((monTiers, index) => (
          <li key={monTiers._id}>
            <div className="form-check d-flex ms-1">
              <input
                className="form-check-input me-2"
                type="checkbox"
                id={`flexCheckDefault${monTiers._id}-${index}`}
                checked={monTiers.checked ?? false}
                onChange={(e) =>
                  handleCheckboxChange(monTiers._id, e.target.checked)
                }
              />
              <label
                className="form-check-label"
                htmlFor={`flexCheckDefault${monTiers._id}-${index}`}
              >
                {typeTiers === "CLIENT"
                  ? `${monTiers.nom} ${monTiers.prenom}`
                  : monTiers.nom}
              </label>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div
      className={`fenetre-liste-tiers cadre-zone4 ${
        etapeId ? "selected-etape" : ""
      }`}
    >
      {userId ? (
        <div>
          {isDestinatairesOpen &&
            (tiers.length > 0 ? (
              renderTiersList()
            ) : (
              <p>Aucun destinataire trouvé.</p>
            ))}
        </div>
      ) : (
        <p>Utilisateur non connecté.</p>
      )}
    </div>
  );
});
