

import React, { useEffect, useState, useContext, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { customAxios } from "../Utilities/utilities";
import "./../css/Global.css";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import io from 'socket.io-client'; 
import Cookies from "js-cookie";

export function CountCatégories() {
  const { urlServeurNode } = useContext(ServerContext);
  const { userId } = useContext(AuthContext);
  const [nbCategories, setNbCategories] = useState(0);

  const chargerCategories = useCallback(async () => {
    if (userId && urlServeurNode) {
      try {
        const response = await customAxios.get(`${urlServeurNode}/api/categories/nbcategories`);
        const { count } = response.data;
        setNbCategories(count);
      } catch (error) {
        console.error("Erreur lors du chargement des catégories:", error);
        setNbCategories(0); // Réinitialiser en cas d'erreur
      }
    }
  }, [userId, urlServeurNode]);

  useEffect(() => {
    if (!urlServeurNode) return;

    chargerCategories();

    const socket = io(urlServeurNode, {
      path: '/ws',
      auth: {
        token: Cookies.get("userTokenAcces")
      }
    });
    socket.on('nouveauModèle', chargerCategories);
    socket.on('updateModèle', chargerCategories);


  }, [chargerCategories, urlServeurNode]);

  return userId ? <span className="badge badge-info">{nbCategories}</span> : null;
}
