import React, { useEffect, useState, useContext,useCallback, useMemo } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/ListeProjets.css";
import "./../css/Global.css";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { customAxios } from "../Utilities/utilities";
import { Tooltip } from "react-tooltip";
import useGetModeles from "../Utilities/GetModeles";
import { useGetTiers } from '../Utilities/GetTiers';
import {
  BsArchive,
} from "react-icons/bs";


import io from 'socket.io-client'; 
import Cookies from "js-cookie";


import ContentEditableField from "../Utilities/ContentEditableField";

//On affiche les projets d'un Tiers EDB par exemple
export function ListeProjetsByTiersId() {
  const { fetchModeles }  = useGetModeles(); 
  const { getTypeTiersByTiersId } = useGetTiers();
  const { urlServeurNode } = useContext(ServerContext);
  const [projets, setListeProjets] = useState([]);
  const [listeModelesDétaillés, setListeModelesDétaillés] = useState([]);
  const [selectedForArchive, setSelectedForArchive] = useState({});
  const [typeTiers, setTypeTiers] = useState(null);

  const { handleProjetId, handleSelectedMenu, userId, agenceId, tiersId, searchTerm } =
    useContext(AuthContext);

  // pour accéder directement au projet
  const handleLabelClick = (projetId) => {
    console.log("ListeProjetsByTiersId: handleLabelClick", projetId);
    handleProjetId(projetId);

 // Vérifier la valeur de typeTiers et appeler handleSelectedMenu en conséquence
if (typeTiers === "CLIENT") {
  handleSelectedMenu("PROJETS CLIENTS"); // Pour simuler un reroutage
} else if (typeTiers === "PARTENAIRE") {
  handleSelectedMenu("PROJETS PARTENAIRES"); // Pour simuler un reroutage
}

  };

  const fetchListeProjets = useCallback(async () => {
    try {
      const actif = true; // Tous les projets actifs
      const response = await customAxios.get(
        `${urlServeurNode}/api/projets/All/${tiersId}/${actif}`
      );

      const touslesProjets = response.data.projects;
      console.log("ListeProjetsByTiersId: fetchListeProjets", tiersId, touslesProjets);

      setListeProjets(touslesProjets);
    } catch (error) {
      console.error(error);
    }
  }, [userId, urlServeurNode, agenceId, tiersId]);


  const chargerTousLesModeles = useCallback(async () => {
    console.log("ListeProjetsByTiersId: chargerTousLesModeles tiersId", tiersId);
    if (userId && agenceId ) {
        try {
          const tousLesModeles = await fetchModeles(agenceId);

          console.log("ListeProjetsByTiersId: chargerTousLesModeles", tousLesModeles);
          // On filtre les modeles adapté au typeTiers
          // On récupère le typeTiers de tiersId
          const typeTiers = await getTypeTiersByTiersId(tiersId);

          setTypeTiers(typeTiers);
          console.log("ListeProjetsByTiersId: chargerTousLesModeles typeTiers", typeTiers);
          const modelesFiltres = tousLesModeles.filter((modele) => modele.cible === typeTiers);
          setListeModelesDétaillés(modelesFiltres);
       
        } catch (erreur) {
          console.error("Erreur lors de la récupération des modèles :", erreur);
        }
    }
  }, [userId, urlServeurNode, agenceId, tiersId]);

  useEffect(() => {
    if (!urlServeurNode || !tiersId) return;

    fetchListeProjets();
    chargerTousLesModeles();

    console.log("ListeProjetsByTiersId: const socket = io(urlServeurNode)", urlServeurNode);

    const socket = io(urlServeurNode, {
      path: '/ws',
      auth: {
        token: Cookies.get("userTokenAcces")
      }
    });
    socket.on('nouveauProjet', (data) => {
      console.log( "ListeProjetsByTiersId: webSockets nouveauProjet", data);
      fetchListeProjets();
    });
      // Nettoyage de la connexion à la fermeture du composant


  }, [userId, urlServeurNode, agenceId, tiersId]);


  const handleSave = async (index, newValue, key) => {
    try {
      console.log(
        "ListeProjetsByTiersId handleSave après modification du champ ",
        key,
        index,
        newValue
      );


      const updatedProjet = { ...projets[index], [key]: newValue };
      await customAxios.patch(
        `${urlServeurNode}/api/projets/alone/${updatedProjet._id}`,
        updatedProjet
      );
      const updatedProjets = [...projets];
      updatedProjets[index] = updatedProjet;
      setListeProjets(updatedProjets);
    } catch (error) {
      console.error(error);
      alert("ListeProjetsByTiersIdUne erreur est survenue lors de la mise à jour de l’élément.");
    }
  };

  // Fonction pour gérer la sélection des projets à supprimer
  const handleCheckboxChange = (projetId) => {
    setSelectedForArchive((prevState) => ({
      ...prevState,
      [projetId]: !prevState[projetId],
    }));
  };

  const handleDelete = async (projetId) => {
    try {
      const response = await customAxios.delete(
        `${urlServeurNode}/api/projets/${projetId}`
      );
      if (response.status === 200) {
        // Suppression réussie, mettez à jour l'état pour supprimer le projet supprimé
        // console.log("ListeProjetsByTiersIdhandleDelete: Suppression de", projetId, "OK");
        // setListeProjets((prevProjets) =>
        //   prevProjets.filter((projet) => projet._id !== projetId)
        // );
      } else if (response.status === 404) {
        console.log("ListeProjetsByTiersIdhandleDelete: Projet introuvable");
      }
    } catch (error) {
      console.error("ListeProjetsByTiersIdErreur lors de la suppression du projet", error);
      // Gérer l'erreur, afficher une alerte ou prendre des mesures appropriées.
    }
  };

 
  // On récupère la liste des projets dont etat est enCours
  const filteredProjets = useMemo(() => {
    // Filtrer les projets avec l'état "enArchive"
    const projetsEnArchive = projets.filter((projet) => projet.etat === "enCours");
    
    // Filtrer par nom en utilisant le searchTerm
    const projetsFiltrésParNom = projetsEnArchive.filter((projet) =>
      projet.nom.toLowerCase().includes(searchTerm.toLowerCase())
    );
    
    // Trier par nom
    projetsFiltrésParNom.sort((a, b) => a.nom.localeCompare(b.nom));
    
    return projetsFiltrésParNom;
  }, [projets, searchTerm]); // Dépend de l'état projets et du terme de recherche


  const handleCheckClick = async (projetId, etatActuel) => {
    try {
      const nouvelEtat = etatActuel === "enCours" ? "enArchive" : "enCours";
      console.log("ListeProjetsByTiersId handleCheckClick etatActuel", etatActuel);
      
      // Mettre à jour l'état du projet sur le serveur
      const response = await customAxios.put(
        `${urlServeurNode}/api/projets/${projetId}`,
        { etat: nouvelEtat }
      );
  
      if (response.status === 200) {
        // Mettre à jour la liste des projets localement après la mise à jour sur le serveur
        setListeProjets((prevProjets) =>
          prevProjets.map((projet) =>
            projet._id === projetId ? { ...projet, etat: nouvelEtat } : projet
          )
        );
      } else {
        console.error("La mise à jour du projet a échoué.");
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du projet:", error);
    }
  };


  return (

      <div className="d-flex flex-column cadre-zone4 Tooltip">
      {/* Liste des projets existants */}
      { filteredProjets.length > 0 ? (
          <>
            <table className=" table-zone4">
              <tbody>
              {filteredProjets.map((projet, index) => (
                  <React.Fragment key={index}> 
                    <tr>
                      <td
                        className="td-nom-projet"
                        onClick={() => handleLabelClick(projet._id)}
                        data-tooltip-id={`infoProjet-${projet._id}-${index}`} 
                        data-tooltip-content={"Accès au projet"}
                      >
                        {projet.nom}
                      </td>
                      <td
                             data-tooltip-id={`infoContrat-${projet._id}-${index}`} 
                             data-tooltip-content={"Type de Contrat"}
                      >       
                        {projet.modeleName}
                       </td>
                      <td
                        style={{ fontSize: "var(--fs-plus-petite)"}}
                        data-tooltip-id={`infoDescription-${projet._id}-${index}`} 
                        data-tooltip-content={"Description modifiable"}
                      >
                        <ContentEditableField
                          initialValue={projet.description}
                          onSave={(value) => handleSave(index, value, "description")}
                        />
                      </td>

                      {/* Si type tiers = "CLIENT" alors afficher le td */}
                      {typeTiers === "CLIENT" && (
                        <td
                          data-tooltip-id={`infoArchive-${projet._id}-${index}`}
                          data-tooltip-content={"Archive le projet"}
                          onClick={() => handleCheckClick(projet._id, projet.etat)}  // Ajouter l'événement onClick
                          style={{ cursor: "pointer" }}  // Changer le curseur pour indiquer que c'est cliquable
                        >
                          <BsArchive color="var(--texte-zone)" />
                        </td>
                      )}

        
                      <Tooltip
                        id= {`infoDescription-${projet._id}-${index}`} 
                        place="top"
                        effect="solid"
                        className="custom-tooltip"
                      ></Tooltip>
                      <Tooltip
                        id={`infoProjet-${projet._id}-${index}`}// Corrigez également ici
                        place="top"
                        effect="solid"
                        className="custom-tooltip"
                      ></Tooltip>

                      <Tooltip
                        id={`infoContrat-${projet._id}-${index}`}// Corrigez également ici
                        place="top"
                        effect="solid"
                        className="custom-tooltip"
                      ></Tooltip>

                      <Tooltip
                        id={`infoArchive-${projet._id}-${index}`} // Corrigez également ici
                        place="top"
                        effect="solid"
                        className="custom-tooltip"
                      ></Tooltip>
                      <Tooltip
                        id="TooltipNewProjet"
                        place="top"
                        effect="solid"
                        className="custom-tooltip"
                      ></Tooltip>
    
                    </tr>
              
                  </React.Fragment>
                ))}

        
              </tbody>
              
            </table>
         
            </>
        ) 
        : null
      }
    </div>
  );
}
