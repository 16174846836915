import React, { useEffect, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { customAxios } from "../Utilities/utilities";
import "./../css/Global.css";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";

import { Tooltip } from "react-tooltip";
import useGetModeles from "../Utilities/GetModeles";

// Si pas de parametre alors trouver tous les modeles
// Sinon avec le modeleID retourne le nombre de projets associés
export function ListeProjetsSansAttentes({ AllAgences }) {
  const { urlServeurNode } = useContext(ServerContext);
  const { fetchModeles }  = useGetModeles(); 
  const { userId, agenceId } = useContext(AuthContext);
  const [projets, setProjets] = useState({});
  const [groupedProjets, setGroupedProjets] = useState({});
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    const chargerTousLesProjets = async () => {
      if (userId && urlServeurNode && agenceId) {
        try {
          const tousLesModelesDetailles = await fetchModeles(
            agenceId
          );
          console.log(
            "ListeProjetsSansAttentes fetchModeles tous les modèles",
            tousLesModelesDetailles
          );


          // Filtrer pour obtenir uniquement les modèles où isCheck est true
          const modelesFiltres = tousLesModelesDetailles.filter(modele => modele.isCheck);

          // Afficher dans la console les modèles filtrés
          // console.log("Modèles avec isCheck à true:", modelesFiltres);


          // Array (9)
          // 0 {_id: "65ea21f5f907db117f1afd18", nom: "AMO", isDefault: true, description: "Description des étapes pour le Contrat AMO", cible: "CLIENT", …}
          // 1 {_id: "65ea21f5f907db117f1afd7f", nom: "MOE", isDefault: false, description: "Description des étapes pour le Contrat MOE", cible: "CLIENT", …}
          // 2 {_id: "65ea21f5f907db117f1afde6", nom: "CG", isDefault: false, description: "Description des étapes pour le Contrat CG", cible: "CLIENT", …}
          // 3 {_id: "65ea21f5f907db117f1afe4d", nom: "Courtage", isDefault: false, description: "Description des étapes pour le Contrat Courtage", cible: "CLIENT", …}
          // 4 {_id: "65ea21f5f907db117f1aff1b", nom: "Audit Thermique", isDefault: false, description: "Description des étapes pour le Contrat Audit Thermique", cible: "CLIENT", …}
          // 5 {_id: "65ea21f5f907db117f1afeb4", nom: "Estimo", isDefault: false, description: "Description des étapes pour le Contrat Estimo", cible: "CLIENT", …}
          // 6 {_id: "65ea21f5f907db117f1aff65", nom: "Interne", isDefault: false, description: "Description des étapes pour le Contrat Interne", cible: "AGENCE", …}
          // 7 {_id: "65ea21f5f907db117f1aff73", nom: "Administratif", isDefault: false, description: "Description des étapes pour le Contrat Administratif", cible: "PARTENAIRE", …}
          // 8 {_id: "65ea21f5f907db117f1aff8a", nom: "Recrutement", isDefault: false, description: "Description des étapes pour le Contrat Recrutement", cible: "PRESTATAIRE", …}

          // setListeModelesDétaillés(tousLesModelesDetailles);
          const tousLesModelesIds = modelesFiltres.map(
            (modele) => modele._id
          );

          const payload = { modeleIds: tousLesModelesIds };

          console.log(
            "ListeProjetsSansAttentes: fetchAttentes modeleIds 31",
            payload
          );
          // Là on récupére tous les projets directement sur le serveur
          if (tousLesModelesIds && tousLesModelesIds.length > 0) {
            const url = `${urlServeurNode}/api/projets/projetsSansAttentes`;
            try {
              const response = await customAxios.post(url, payload);
              const projetsTries = response.data.sort((a, b) => {
                const modeleCompare = a.nomModele.localeCompare(b.nomModele);
                if (modeleCompare !== 0) {
                  return modeleCompare; // Trie d'abord par nomModele
                }
                return a.nomProjet.localeCompare(b.nomProjet); // Puis par nomProjet si nomModele est identique
              });

              if (AllAgences) {
                const grouped = projetsTries.reduce((acc, projet) => {
                  acc[projet.nomModele] = [
                    ...(acc[projet.nomModele] || []),
                    projet,
                  ];
                  return acc;
                }, {});
                console.log(
                  "ListeProjetsSansAttentes: projetsTries",
                  projetsTries
                );
                setProjets(projetsTries);
                setGroupedProjets(grouped);
              } else {
                // Filtrer les projets en vérifiant si userId est l'un des gestionnaires du projet
                const projetsFiltres = projetsTries.filter((projet) =>
                  projet.gestionnaire.some(
                    (gestionnaire) => gestionnaire._id === userId
                  )
                );

                console.log(
                  "ListeProjetsSansAttentes: projetsTries",
                  projetsFiltres
                );
                setProjets(projetsFiltres);
                // Regrouper les projets filtrés par nom de modèle
                const grouped = projetsFiltres.reduce((acc, projet) => {
                  acc[projet.nomModele] = [
                    ...(acc[projet.nomModele] || []),
                    projet,
                  ];
                  return acc;
                }, {});
                setGroupedProjets(grouped);
              }

              //                 Array (24)
              // 0 {projetId: "65ea2258f907db117f1b0cc9", nomProjet: "CANTEGRIT-GUERARD", nomModele: "AMO", gestionnaire: "undefined undefined"}
              // 1 {projetId: "65eac4b34b7dfaa0365244b5", nomProjet: "RENAULT", nomModele: "AMO", gestionnaire: "undefined undefined"}
              // 2 {projetId: "65eac7b34b7dfaa03652a04f", nomProjet: "MARCHAND", nomModele: "AMO", gestionnaire: "undefined undefined"}
              // 3 {projetId: "65eaca544b7dfaa03652a4f1", nomProjet: "SCHMITT", nomModele: "MOE", gestionnaire: "undefined undefined"}
              // 4 {projetId: "65ead4c74b7dfaa03652b76c", nomProjet: "ROBIN", nomModele: "AMO", gestionnaire: "undefined undefined"}
            } catch (error) {
              console.error("Erreur lors de la requête POST :", error);
              // Gérer l'erreur, afficher un message d'erreur à l'utilisateur, etc.
            }
          } else {
            console.error("modeleIds est vide ou non défini.");
            // Gérer le cas où modeleIds est vide ou non défini
          }
        } catch (erreur) {
          console.error("Erreur lors de la récupération des modèles :", erreur);
          // Gérer l'erreur comme il se doit
        }
      }
    };

    chargerTousLesProjets();
  }, [userId, urlServeurNode, agenceId, AllAgences]);


const totalProjets = Object.values(groupedProjets).flat().length;
const projetPlural = totalProjets === 1 ? "projet" : "PROJETS CLIENTS";

  return (
    <>
      <button className="bouton-commun" 
        // style={{
        //   fontSize: "var(--fs-plus-petite)",
        //   borderRadius: "var(--border-radius-cadre)",
        //   padding: "10px",
        //   margin: "8px",
        //   fontWeight: "300",
        //   display: "flex",
        //   flexDirection: "column",
        //   justifyContent: "left",
        //   textAlign: "left",
        // }}
        data-tooltip-id={`ToolTipSansAttentes`}
        data-tooltip-content={"liste des projets sans Attentes, en fonction des options (A vérifier) modèles "}
        data-tooltip-place="top"
        onClick={() => setToggle(!toggle)}
      >
            {Object.keys(groupedProjets).length > 0 ? (
              <>
                <h6>A Renseigner</h6>
                  <span style={{ color: "red" }}>
                  {totalProjets} {projetPlural}
                  </span>
                {toggle && (
                  <>
                    {Object.entries(groupedProjets).map(([nomModele, projets]) => (
                      <div key={nomModele}>
                        <strong>{nomModele}</strong> (Projets : {projets.length})
                        <ul>
                          {projets.map((projet, index) => (
                            <li key={index}>
                              {AllAgences
                                ? `${projet.nomProjet} - ${projet.initiales}`
                                : projet.nomProjet}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </>
                )}
              </>
            ) : (
              <h6> Tous les Projets OK </h6>
            )}
 
      
      </button>
      <Tooltip
              id="ToolTipSansAttentes"
              place="top"
              effect="solid"
              className="custom-tooltip"
            ></Tooltip>
    </>
  );
}
