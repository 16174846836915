import { useContext } from 'react';
import { customAxios } from './utilities';
import ServerContext from '../../contexts/ServeurProvider';

export const useGetTiers = () => {
  const { urlServeurNode } = useContext(ServerContext);

  const getNameTiersByTiersId = async (tiersId) => {
    try {
      const response = await customAxios.get(`${urlServeurNode}/api/tiers/${tiersId}`);
      const tiersData = response.data;
      return tiersData && tiersData.nom ? tiersData.nom : "NomInconnu";
    } catch (error) {
      console.error("Erreur lors de la récupération du nom à partir de tiersId", error);
      return "NomInconnu";
    }
  };

  const getTypeTiersByTiersId = async (tiersId) => {
    try {
      const response = await customAxios.get(`${urlServeurNode}/api/tiers/${tiersId}`);
      const tiersData = response.data;
      return tiersData && tiersData.typeTiers ? tiersData.typeTiers : "NomInconnu";
    } catch (error) {
      console.error("Erreur lors de la récupération du type à partir de tiersId", error);
      return "NomInconnu";
    }
  };

  return {
    getNameTiersByTiersId,
    getTypeTiersByTiersId,
  };
};
