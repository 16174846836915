import React, { useEffect, useState, useContext, useCallback } from "react";
import "../css/Compteurs.css";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { customAxios } from "../Utilities/utilities";
import io from 'socket.io-client'; 
import Cookies from "js-cookie";

export function CountProjetsByUserId({ etat }) {
  const { urlServeurNode } = useContext(ServerContext);
  const { userId, handleLogout } = useContext(AuthContext);
  const [nbProjets, setNbProjets] = useState(0); // Correct initial state to 0 since it's a number

  const fetchProjetsUser = useCallback(async () => {
    if (!userId) return;
    try {
      const response = await customAxios.get(`${urlServeurNode}/api/projets/countByUserId/${userId}/${etat}`);
      setNbProjets(response.data.nbProjets || 0);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleLogout();
      } else {
        console.error("Erreur lors de la récupération des projets :", error);
        setNbProjets(0); // Set to 0 in case of error
      }
    }
  }, [userId, etat]);

  useEffect(() => {
    if (!urlServeurNode) return;
    fetchProjetsUser();

    console.log("CountProjetsByUserId const socket = io(urlServeurNode)", etat, urlServeurNode);
    const socket = io(urlServeurNode, {
      path: '/ws',
      auth: {
        token: Cookies.get("userTokenAcces")
      }
    });
    const handleNewUpdate = data => {
      console.log("CountProjetsByUserId WebSockets - Nouveau Projet/Update", data);
      fetchProjetsUser();
    };

    socket.on('nouveauProjet', handleNewUpdate);
    socket.on('updateProjet', handleNewUpdate);


    
  }, [fetchProjetsUser]);

  return (
    <>{userId ? <span className="badge badge-info">{nbProjets}</span> : null}</>
  );
}
