// Footer.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Version from './../Version/Version'; // Importer le composant Version

export function FooterAccueil() {
  const smallFontSize = '0.8rem'; // Vous pouvez ajuster la taille selon vos besoins

  return (
    <footer className="footer" style={{ 
      backgroundColor: "white", 
      borderRadius: "6px",
      position: "fixed", // Fixe le footer au bas de la fenêtre du navigateur
      bottom: 0,          // Positionne le footer tout en bas
      left: 0,            // Assure que le footer s'étend d'un côté à l'autre
      right: 0,
      width: "100%",      // Assure que le footer s'étend sur toute la largeur
      borderTop: "1px solid #ccc", // Ajoute une ligne séparatrice (optionnel)
      paddingTop: "5px",
      paddingBottom: "5px",
      zIndex: 1000        // Assure que le footer reste au-dessus des autres contenus
    }}>
      <Container fluid className="px-0">
        <Row className="justify-content-center align-items-center mx-0">
          <Col xs={12} md="auto" className="py-0 text-center text-md-left">
            <span style={{ fontSize: smallFontSize, marginLeft: '45px', color:  "var(--bg-gris-fonce)" }}>
              © {new Date().getFullYear()} İAkka - All Rights Reserved.   <Version /> 
            </span>
          </Col>
          {/* <Col xs={12} md="auto" className="py-0 text-center text-md-left">
            <Version /> 
          </Col> */}
        </Row>
      </Container>
    </footer>
  );
};
