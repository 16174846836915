// ContentEditableField.js

import React, { useState,useRef,useEffect } from "react";


function ContentEditableField({ initialValue, onSave }) {
  const [content, setContent] = useState(initialValue);
  const contentEditableRef = useRef(null); // Ref pour accéder à l'élément DOM

  const handleContentChange = (e) => {
    setContent(e.currentTarget.textContent); // Utilisez textContent pour obtenir le contenu textuel
  };

  const handleBlur = () => {
    onSave(contentEditableRef.current.textContent);
  };

  useEffect(() => {
    // Définissez le contenu uniquement si le contenu a changé
    if (contentEditableRef.current && contentEditableRef.current.textContent !== content) {
      contentEditableRef.current.textContent = content;
    }
  }, [content]);

  // Utilisez un autre useEffect pour gérer le rendu initial
  useEffect(() => {
    contentEditableRef.current.textContent = initialValue;
  }, [initialValue]);

  return (
    <div
      ref={contentEditableRef}
      contentEditable={true}
      onInput={handleContentChange}
      onBlur={handleBlur}
      // Pas besoin de dangerouslySetInnerHTML ici
    />
  );
}



export default ContentEditableField;
