import React, { useEffect, useState, useContext, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import "./../css/Global.css";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { customAxios } from "../Utilities/utilities";
import { Tooltip } from "react-tooltip";
import io from "socket.io-client";
import Cookies from "js-cookie";
import useGetModeles from "../Utilities/GetModeles";
import { useFetchAgences } from "../Utilities/fetchAgences"; // Corrected here



export function ListeCatégories({ AllAgences }) {
  const { fetchModeles }  = useGetModeles(); 
  const { fetchAgences } = useFetchAgences();

  const { urlServeurNode } = useContext(ServerContext);
  const [ListeCatégoriesDétaillés, setListeCatégoriesDétaillés] = useState([]);
  const [checkedModeles, setCheckedModeles] = useState({});
  const [selectedModeleId, setSelectedModeleId] = useState(null);
  const [toutesLesCibles, setCibles] = useState([]); // Pour les différentes Vues
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [selectedAgence, setSelectedAgence] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'cible', direction: 'ascending' });
  const [editModele, setEditModele] = useState(null);
  const [editValues, setEditValues] = useState({ nom: "", cible: "" });
  const [editedCategory, setEditedCategory] = useState(null);
  const [editedCategoryName, setEditedCategoryName] = useState("");

  const { handleModeleId, tiersId, userId, triggerAddModele, handleTriggerAddAction } = useContext(AuthContext);

  const addNewModele = async () => {
    try {
      // Sauvegarde de la nouvelle action dans la base de données
    } catch (error) {
      console.error("Erreur lors de l'ajout d'un nouveau modèle:", error);
    }
  };

  // Récupère la liste des types de Tiers
  const fetchCibles = useCallback(async () => {
    try {
      const response = await customAxios.get(`${urlServeurNode}/api/actions/types-actions-et-tiers`);
      const result = response.data;
      setCibles(result.tiers);
    } catch (error) {
      console.error("GestionActions: Failed to fetch data:", error);
    }
  }, [urlServeurNode]);

  const chargerTousLesModeles = useCallback(async (localParametreId) => {
    try {
      const tousLesModelesDetailles = await fetchModeles(localParametreId);
      setListeCatégoriesDétaillés(tousLesModelesDetailles);

      // Récupérer toutes les catégories disponibles
      const allCategories = new Set();
      tousLesModelesDetailles.forEach((modele) => {
        modele.categorie.forEach((cat) => allCategories.add(cat));
      });
      setCategories(Array.from(allCategories));

      // Trouver et sélectionner le modèle par défaut (s'il existe)
      const modeleParDefaut = tousLesModelesDetailles.find((modele) => modele.isDefault);
      if (modeleParDefaut) {
        setSelectedModeleId(modeleParDefaut._id); // Sélectionner le modèle par défaut
        handleModeleId(modeleParDefaut._id); // Mettre à jour le contexte si nécessaire
      }
    } catch (error) {
      console.error("Erreur lors du chargement des modèles:", error);
    }
  }, [urlServeurNode, handleModeleId]);

  useEffect(() => {
    if (triggerAddModele) {
      addNewModele();
      handleTriggerAddAction(false);
    }
  }, [triggerAddModele, tiersId, handleTriggerAddAction]);

  const fetchAgencesCallback = useCallback(async () => {
    try {
      const uniqueTiers = await fetchAgences();
      const adminAgence = uniqueTiers.find((agence) => agence.typeTiers === "ADMINISTRATEUR");
      if (adminAgence) {
        setSelectedAgence(adminAgence);
        chargerTousLesModeles(adminAgence._id);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des agences et administrateurs :", error);
    }
  }, [ chargerTousLesModeles]);

  const handleLabelClick = (ModeleId) => {
    handleModeleId(ModeleId);
    setSelectedModeleId(ModeleId);
    setCheckedModeles((prevState) => ({
      ...prevState,
      [ModeleId]: !prevState[ModeleId],
    }));
  };

  const handleSocketEvent = useCallback(() => {
    fetchAgencesCallback();
  }, [fetchAgencesCallback]);

  useEffect(() => {
    handleSocketEvent();
    const socket = io(urlServeurNode, {
      path: "/ws",
      auth: { token: Cookies.get("userTokenAcces") },
    });
    socket.on("updateModèle", handleSocketEvent);
    return () => {
      socket.off("updateModèle", handleSocketEvent);
      // socket.disconnect();
    };
  }, [userId, AllAgences, tiersId, fetchAgencesCallback, chargerTousLesModeles, handleSocketEvent]);

  useEffect(() => {
    fetchAgencesCallback();
    fetchCibles();
  }, [fetchAgencesCallback, fetchCibles]);

  const handleModelChange = async (selectedModelId, type) => {
    try {
      const updatedModels = ListeCatégoriesDétaillés.map((modele) => {
        if (modele._id === selectedModelId) {
          return {
            ...modele,
            isDefault: type === "radio" ? true : modele.isDefault,
            isCheck: type === "checkbox" ? !modele.isCheck : modele.isCheck,
            afficheDestinataires: type === "AfficheDestinataires" ? !modele.afficheDestinataires : modele.afficheDestinataires,
          };
        } else if (type === "radio" && modele.isDefault) {
          return { ...modele, isDefault: false };
        } else {
          return modele;
        }
      });

      setListeCatégoriesDétaillés(updatedModels);

      const updateData = updatedModels.find((modele) => modele._id === selectedModelId);
      await customAxios.patch(`${urlServeurNode}/api/modeles/ModifieOptionsDuModele/${selectedModelId}`, updateData);
    } catch (error) {
      console.error(error);
      alert("Une erreur est survenue lors de la mise à jour sur le serveur.");
    }
  };

  const handleCategoryChange = async (selectedModelId, category) => {
    try {
      const updatedModels = ListeCatégoriesDétaillés.map((modele) => {
        if (modele._id === selectedModelId) {
          const newCategories = modele.categorie.includes(category)
            ? modele.categorie.filter((cat) => cat !== category)
            : [...modele.categorie, category];
          return { ...modele, categorie: newCategories };
        }
        return modele;
      });

      setListeCatégoriesDétaillés(updatedModels);

      const updatedModel = updatedModels.find((modele) => modele._id === selectedModelId);
      const updateData = { categorie: updatedModel.categorie };

      await customAxios.patch(`${urlServeurNode}/api/modeles/ModifieOptionsDuModele/${selectedModelId}`, updateData);
    } catch (error) {
      console.error(error);
      alert("Une erreur est survenue lors de la mise à jour des catégories sur le serveur.");
    }
  };

  const handleAddCategory = () => {
    if (newCategory && !categories.includes(newCategory)) {
      setCategories([...categories, newCategory]);
      setNewCategory("");
    }
  };

  const handleRemoveCategory = async (categoryToRemove) => {
    const updatedCategories = categories.filter((category) => category !== categoryToRemove);
    setCategories(updatedCategories);

    const updatedModels = ListeCatégoriesDétaillés.map((modele) => {
      const newCategories = modele.categorie.filter((cat) => cat !== categoryToRemove);
      return { ...modele, categorie: newCategories };
    });

    setListeCatégoriesDétaillés(updatedModels);

    for (let model of updatedModels) {
      const updateData = { categorie: model.categorie };
      await customAxios.patch(`${urlServeurNode}/api/modeles/ModifieOptionsDuModele/${model._id}`, updateData);
    }
  };

  const handleEditCategory = (category) => {
    setEditedCategory(category);
    setEditedCategoryName(category);
  };

  const handleSaveEditCategory = async () => {
    try {
      const updatedCategories = categories.map((category) => {
        return category === editedCategory ? editedCategoryName : category;
      });

      setCategories(updatedCategories);

      const updatedModels = ListeCatégoriesDétaillés.map((modele) => {
        const newCategories = modele.categorie.map((cat) => (cat === editedCategory ? editedCategoryName : cat));
        return { ...modele, categorie: newCategories };
      });

      setListeCatégoriesDétaillés(updatedModels);

      for (let model of updatedModels) {
        const updateData = { categorie: model.categorie };
        await customAxios.patch(`${urlServeurNode}/api/modeles/ModifieOptionsDuModele/${model._id}`, updateData);
      }

      setEditedCategory(null);
      setEditedCategoryName("");
    } catch (error) {
      console.error("Erreur lors de la mise à jour des catégories :", error);
    }
  };

  const handleCancelEditCategory = () => {
    setEditedCategory(null);
    setEditedCategoryName("");
  };

  const handleEditModele = (modele) => {
    setEditModele(modele);
    setEditValues({ nom: modele.nom, cible: modele.cible });
  };

  const handleSaveEdit = async () => {
    try {
      const updatedModels = ListeCatégoriesDétaillés.map((modele) => {
        if (modele._id === editModele._id) {
          return { ...modele, nom: editValues.nom, cible: editValues.cible };
        }
        return modele;
      });

      setListeCatégoriesDétaillés(updatedModels);
      await customAxios.patch(`${urlServeurNode}/api/modeles/ModifieOptionsDuModele/${editModele._id}`, { nom: editValues.nom, cible: editValues.cible });

      setEditModele(null);
    } catch (error) {
      console.error(error);
      alert("Une erreur est survenue lors de la mise à jour sur le serveur.");
    }
  };

  const handleCancelEdit = () => {
    setEditModele(null);
    setEditValues({ nom: "", cible: "" });
  };

  const sortData = (data, config) => {
    const { key, direction } = config;
    const sortedData = [...data].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    return sortedData;
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const renderTableHeader = () => {
    return (
      <>
        <th style={{ textAlign: "left", fontWeight: 600, cursor: 'pointer' }} data-tooltip-id={`Nom du contrat`} data-tooltip-content={"Type de contrat."} data-tooltip-place="top" onClick={() => handleSort('nom')}>
          MODELES
        </th>
        <th style={{ textAlign: "left", fontWeight: 600, cursor: 'pointer' }} data-tooltip-id={`Cible du Projet`} data-tooltip-content={"A qui s'adresse ce projet?"} data-tooltip-place="top" onClick={() => handleSort('cible')}>
          CIBLE
        </th>
        {categories.map((category, index) => (
          <th key={index} style={{ textAlign: "center", fontWeight: 600, padding: "0 10px" }} data-tooltip-id={`Catégorie ${category}`} data-tooltip-content={"Catégories proposées à la création d'une nouvelle agence"} data-tooltip-place="top">
            <div style={{ display: 'inline-block', border: '1px solid white', padding: '5px', borderRadius: '5px', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
              {editedCategory === category ? (
                <>
                  <input type="text" value={editedCategoryName} onChange={(e) => setEditedCategoryName(e.target.value)} />
                  <button onClick={handleSaveEditCategory} style={{ marginLeft: "5px" }}>
                    ✓
                  </button>
                  <button onClick={handleCancelEditCategory} style={{ marginLeft: "5px" }}>
                    x
                  </button>
                </>
              ) : (
                <>
                  {category}
                  <button onClick={() => handleEditCategory(category)} style={{ marginLeft: "5px" }}>
                    ✎
                  </button>
                  <button onClick={() => handleRemoveCategory(category)} style={{ marginLeft: "5px", color: "red", border: "none", background: "none", cursor: "pointer" }}>
                    x
                  </button>
                </>
              )}
            </div>
          </th>
        ))}
      </>
    );
  };

  const renderTableBody = () => {
    const sortedModeles = sortData(ListeCatégoriesDétaillés, sortConfig);
    return sortedModeles.map((modele, index) => (
      <tr key={modele._id} className={index % 2 === 0 ? "bg-custom" : ""}>
        <td>
          {editModele && editModele._id === modele._id ? (
            <input type="text" value={editValues.nom} onChange={(e) => setEditValues({ ...editValues, nom: e.target.value })} />
          ) : (
            <label className={`${modele._id === selectedModeleId ? "label-semi-gras" : ""}`} htmlFor={`flexCheckDefault${modele._id}`} style={{ cursor: "pointer" }} onClick={() => handleLabelClick(modele._id)}>
              <span style={{ marginLeft: "5px" }}>{modele.nom}</span>
              {modele.categorie.length === 0 && (
                <span style={{ color: "yellow", marginLeft: "10px", fontSize: '20px' }} data-tooltip-id={`noCategory-${modele._id}`} title="Aucune catégorie sélectionnée">
                  ⚠️
                </span>
              )}
              {(!modele.etapes || modele.etapes.length === 0) && (
                <span style={{ color: "green", marginLeft: "10px", fontSize: '20px' }} data-tooltip-id={`noStep-${modele._id}`} title="Aucune étape rattachée">
                  ▲
                </span>
              )}
            </label>
          )}
        </td>

        <td>
          {editModele && editModele._id === modele._id ? (
            <select value={editValues.cible} onChange={(e) => setEditValues({ ...editValues, cible: e.target.value })}>
              {toutesLesCibles.map((cible) => (
                <option key={cible} value={cible}>
                  {cible}
                </option>
              ))}
            </select>
          ) : (
            <label>{modele.cible}</label>
          )}
        </td>
        {categories.map((category, index) => (
          <td key={index} style={{ textAlign: "center" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <input type="checkbox" name={`category${index}`} id={`checkboxCategory${modele._id}${index}`} checked={modele.categorie.includes(category)} onChange={() => handleCategoryChange(modele._id, category)} />
            </div>
          </td>
        ))}
        {editModele && editModele._id === modele._id && (
          <td>
            <button className="btn btn-success" onClick={handleSaveEdit}>
              Sauvegarder
            </button>
            <button className="btn btn-secondary" onClick={handleCancelEdit} style={{ marginLeft: "5px" }}>
              Annuler
            </button>
          </td>
        )}
        {!editModele && (
          <td>
            <button className="btn btn-primary" onClick={() => handleEditModele(modele)}>
              Modifier
            </button>
          </td>
        )}
      </tr>
    ));
  };

  const renderContent = () => {
    if (!userId) return <p>Non connecté</p>;
    if (ListeCatégoriesDétaillés.length === 0) return <p>Aucun modèle trouvé.</p>;
    return (
      <>
        <table>
          <thead>
            <tr>{renderTableHeader()}</tr>
          </thead>
          <tbody>{renderTableBody()}</tbody>
        </table>

        <Tooltip id="Nom du contrat" place="top" effect="solid" className="custom-tooltip" />
        <Tooltip id="Cible du Projet" place="top" effect="solid" className="custom-tooltip" />
        {categories.map((category, index) => (
          <Tooltip key={index} id={`Catégorie ${category}`} place="top" effect="solid" className="custom-tooltip" />
        ))}
           {ListeCatégoriesDétaillés.map((modele) => (
          <React.Fragment key={modele._id}>
            <Tooltip id={`noCategory-${modele._id}`} place="top" effect="solid" className="custom-tooltip">
              Aucune catégorie sélectionnée
            </Tooltip>
            <Tooltip id={`noStep-${modele._id}`} place="top" effect="solid" className="custom-tooltip">
              Aucune étape rattachée
            </Tooltip>
          </React.Fragment>
        ))}
      </>
    );
  };

  return (
    <>
      <div className="form-group">
        <h5>
          Tableau d'association des modèles à des catégories {selectedAgence?.nom}, utilisé lors de la création d'une nouvelle agence.
        </h5>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <input type="text" className="form-control" value={newCategory} onChange={(e) => setNewCategory(e.target.value)} placeholder="Nouvelle catégorie" />
          <button className="btn btn-primary" onClick={handleAddCategory} style={{ marginLeft: '10px' }}>
            Ajouter catégorie
          </button>
        </div>
      </div>
      <div className="cadre-zone2">{renderContent()}</div>
    </>
  );
}
