import React, { useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/Global.css";

import { useProjet } from "../../contexts/ProjetContext";

// Appelé depuis Zone 3
export function ProjetNomByProjetId() {
  const { projetData } = useProjet();

  // On vérifie que projetData est défini et qu'il contient les propriétés nécessaires
  if (!projetData || !projetData._id || !projetData.nom) {
    // Vous pourriez vouloir retourner un élément de secours ou rien
    return <h5 className="titre-zone3">Nom du projet indisponible</h5>;
  }

  // Si projetData est bien défini et contient toutes les informations, on affiche le nom du projet
  return (
    <h5 className="titre-zone3" style={{  textAlign: 'center' }}>
      {projetData.nom}
    </h5>
  );
  
  
}
